import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./quatation.css";
import { useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../Utils/sweetAlert";
import { callApi } from "../../Utils/Api";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faDownload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "validator";
import { useParams } from "react-router-dom";

interface Row {
  id: any;
  sno: number;
  description: string;
  length: string;
  h_w: string;
  area: string;
  totalPrice: string;
}
interface Less {
  id: any;
  sno: number;
  description: string;
  amount: any;
}

interface Table {
  name: string;
  rows: Row[];
}
interface LessTable {
  name: string;
  rows: Less[];
}

function PoLabourPage() {
  const navigate = useNavigate();
  const { siteId } = useParams<{ siteId: string }>();

  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerDate, setCustomerData] = useState("");
  const [CustomerVendor, setCustomerVendor] = useState("");
  const [deletedIds, setDeletedIds] = useState<number[]>([]);
  const [receivedAmount, setReceivedAmount] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [rows, setRows] = useState<{ [category: string]: Row[] }>({});
  const [submitting, setSubmitting] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [comments, setComments] = useState("");
  const [showEditors, setShowEditors] = useState<{
    [category: string]: boolean;
  }>({});
  const [tables, setTables] = useState<Table[]>([]);
  const [lessWork, setLessWork] = useState<LessTable[]>([]);
  const [ckeditorData, setCkeditorData] = useState<{
    [category: string]: string;
  }>({});
  const [state, setState] = useState({
    QuoteListSet: { category: [] as any[] },
    vendor: [
      {
        id: "",
        name: "",
      },
    ],
    item: [
      {
        id: "",
        product: "",
        product_price: "",
      },
    ],
    poData: [],
  });
  const [deletedCategoryItemIds, setDeletedCategoryItemIds] = useState<
    number[]
  >([]);
  const [deletedCategoryIds, setDeletedCategoryIds] = useState<number[]>([]);
  const [deletedLessWorkIds, setDeletedLessWorkIds] = useState<number[]>([]);
  const [deletedTableIds, setDeletedTableIds] = useState<number[]>([]);

  useEffect(() => {
    if (!customerDate) {
      setCustomerData(getCurrentDate());
    }
  }, [customerDate]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    if (!customerAddress) {
      const shipadd = localStorage.getItem("shipadd");
      setCustomerAddress(shipadd || "");
    }
  }, [customerAddress]);
  useEffect(() => {
    console.log(
      deletedCategoryItemIds,
      deletedCategoryIds,
      deletedLessWorkIds,
      deletedTableIds
    );
  }, [
    deletedCategoryItemIds,
    deletedCategoryIds,
    deletedLessWorkIds,
    deletedTableIds,
  ]);

  useEffect(() => {
    getQuotatilDetails();
    GetVendorDetails();
  }, []);

  const getQuotatilDetails = () => {
    const req = {
      quo_id: "1",
    };
    callApi("post", "quo_detail.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["responseCode"] === 200) {
          const data = response.responseJson;
          setState((prevState) => ({
            ...prevState,
            QuoteListSet: response.responseJson,
          }));

          const categories = data.category.map((cat: any) => cat.categoryName);

          const rows = data.category.reduce((acc: any, cat: any) => {
            acc[cat.categoryName] = cat.categoryItems.map(
              (item: any, index: any) => ({
                id: item.item_id,
                sno: index,
                description: item.description,
                length: item.length,
                h_w: item.height,
                area: item.area,
                totalPrice: item.totalPrice,
              })
            );
            return acc;
          }, {});

          const material = data.category.reduce((acc: any, cat: any) => {
            acc[cat.categoryName] = cat.material;
            return acc;
          }, {});

          const extraWork = data.extraWork.map((extra: any) => ({
            name: extra.categoryName,
            rows: extra.categoryItems.map((item: any, index: any) => ({
              id: item.item_id,
              sno: index,
              description: item.description,
              length: item.length,
              h_w: item.height,
              area: item.area,
              totalPrice: item.totalPrice,
            })),
          }));

          const lessWork = Array.isArray(data.lesswork)
            ? data.lesswork.map((less: any) => ({
                name: less.categoryName,
                rows: less.categoryItems.map((item: any, index: any) => ({
                  id: item.item_id,
                  sno: index,
                  description: item.description,
                  amount: item.amount,
                })),
              }))
            : [];
          setCategories(categories);
          setRows(rows);
          setTables(extraWork);
          setLessWork(lessWork);
          setCkeditorData(material);
          console.log("categories", categories);
          console.log("rows", rows);
          console.log("material", material);
          console.log("extraWork", extraWork);
          console.log("lessWork", lessWork);
        } else {
          setState((prevState: any) => ({
            ...prevState,
            QuoteListSet: [],
          }));
        }
      });
  };

  const GetVendorDetails = () => {
    callApi("POST", "vendor_list.php", "")
      .then((res) => res.data)
      .then((response) => {
        console.log("response--->111", response.responseJson);

        setState((prevState: any) => ({
          ...prevState,
          vendor: response.responseJson,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    callApi("POST", "product_list.php", "")
      .then((res) => res.data)
      .then((response) => {
        console.log("response--->222", response.responseJson);

        setState((prevState: any) => ({
          ...prevState,
          item: response.responseJson,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    let req = {
      po_id: siteId,
    };
    if (siteId != "0") {
      callApi("POST", "po_id_select.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response["response_Code"] == 200) {
            setState((prevState: any) => ({
              ...prevState,
              poData: response?.responseJson[0],
            }));
            setCustomerData(response?.responseJson[0]?.po_date);
            setCustomerVendor(response?.responseJson[0]?.vendor_id);
            setComments(response?.responseJson[0]?.comments);
            setCustomerName(response?.responseJson[0]?.po_number);
            setItemData(response?.responseJson[0]?.items);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [ItemData, setItemData] = useState([
    {
      product_id: "",
      product: "",
      po_qty: "",
      po_unit_price: "",
      po_total: "",
      item_id: "",
    },
  ]);

  const addItemRow = (): void => {
    const newItem = {
      product_id: "",
      product: "",
      po_qty: "",
      po_unit_price: "",
      po_total: "",
      item_id: "",
    };
    setItemData((prevItemData) => [...prevItemData, newItem]);
    console.log(ItemData); // This may not reflect the updated state immediately
  };

  const deleteItemRow = (index: number): void => {
    const updatedExpenceData = [...ItemData];
    const deletedItemId: any = updatedExpenceData[index]?.item_id; // Assuming `id` is the identifier
    updatedExpenceData.splice(index, 1);
    setItemData(updatedExpenceData);
    setDeletedIds([...deletedIds, deletedItemId]);
  };
  useEffect(() => {
    console.log("expenceData has changed:", ItemData);
  }, [ItemData]);

  const onchange = (e: any, index: number) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    console.log("event___", fieldName, fieldValue);
    let newItem;
    const updatedExpenceData: any = [...ItemData];
    if (fieldName == "po_qty") {
      const po_unit_price: any = updatedExpenceData[index].po_unit_price;
      const po_total = fieldValue * po_unit_price;
      newItem = {
        ...updatedExpenceData[index],
        [fieldName]: fieldValue,
        po_total,
      };
    } else if (fieldName == "po_unit_price") {
      const po_qty: any = updatedExpenceData[index].po_qty;
      const po_total = fieldValue * po_qty;
      newItem = {
        ...updatedExpenceData[index],
        [fieldName]: fieldValue,
        po_total,
      };
    } else {
      newItem = { ...updatedExpenceData[index], [fieldName]: fieldValue };
    }
    // newItem.valid = fieldValue !== "";
    updatedExpenceData[index] = newItem;
    setItemData(updatedExpenceData);
  };

  const [isValid, setIsValid] = useState(false);

  const checkValid = () => {
    let valid = true;
    const updatedItemData = ItemData.map((element) => {
      if (
        element.product !== "" &&
        element.po_qty !== "" &&
        element.po_unit_price !== "" &&
        element.po_total !== ""
      ) {
        return { ...element, valid: true };
      } else {
        valid = false;
        return { ...element, valid: false };
      }
    });
    setItemData(updatedItemData);
    return valid;
  };

  const submitHandelClick = async () => {
    const isValid = await checkValid();
    console.log("checkis valid------", isValid);
    const Grand_total = ItemData.reduce(
      (acc: any, curr: any) => acc + parseFloat(curr.po_total || 0),
      0
    );
    const site_Id = localStorage.getItem("siteid");

    const req = {
      po_date: customerDate,
      vendor_id: CustomerVendor,
      site_id: site_Id,
      po_id: siteId,
      comments: comments,
      items: ItemData,
      deletedIds: deletedIds,
      grand_total: Grand_total,
      labour_status: "2",
    };
    console.log("Grand_total---->", Grand_total);
    console.log("req---->", req);
    if (isValid) {
      setIsValid(false);
      callApi("post", "po_create.php", req)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response["responseCode"] === 200) {
            showSuccess("Success!", response.message);
            // handleSiteClick();
            navigate(`/layout/purchaseorder/${response.po_id}`);
          } else {
            showError("Failed", response.message);
          }
        });
    } else {
      setIsValid(true);
    }
    console.log("submitHandelClick", req);
  };

  const handleSiteClick = () => {
    const site_Id = localStorage.getItem("siteid");
    // navigate(`/layout/sitedetails/${site_Id}`);
    navigate(`/layout/purchaseorder/${site_Id}`);
  };

  const purchaseentryroute = () => {
    navigate("/layout/purchaseentryform");
  };

  const addnewform = () => {
    setItemData([
      {
        product_id: "",
        product: "",
        po_qty: "",
        po_unit_price: "",
        po_total: "",
        item_id: "",
      },
    ]);
  };
  const handlegoBack = () => {
    navigate(-1);
  };

  const PoformClick = () => {
    let siteID = "0";
    navigate(`/layout/poformcreate/${siteID}`);
  };

  const vendorOption = [{ id: "0", name: "Select Site" }, ...state.vendor];

  return (
    <div className="container-qt">
      <div style={{ height: "40px" }}>
        <button className="btn excelbtn mr-2" onClick={handlegoBack}>
          Go Back
        </button>
      </div>
      {/* <header className="head">
        <Row>
          <Col lg="2" md="2" sm="2" xs="2" className="flex">
            <h5 onClick={() => purchaseentryroute()}>
              Purchase Entry{" "}
              <FontAwesomeIcon icon={faChevronRight} className="righticon" />{" "}
            </h5>
          </Col>
          <Col lg="2" md="2" sm="2" xs="2" className="flex">
            <h5>
              Return Slip{" "}
              <FontAwesomeIcon icon={faChevronRight} className="righticon" />
            </h5>
          </Col>
          <Col lg="4" md="4" sm="4" xs="4" className="flex">
            <h5 onClick={() => addnewform()}>
              Add Labour
              <FontAwesomeIcon icon={faPlus} className="righticon" />
            </h5>
          </Col>
          <Col lg="2" md="2" sm="2" xs="2" className="flex">
            <h5>
              Mark as send
              <input
                name="#value"
                type="checkbox"
                className="form-check-input "
                id="exampleCheck1"
                // checked={state.FaciaInnodoor}
                // onChange={FaciaInternalnodoor}
                style={{ marginTop: "3px" }}
              />
            </h5>
          </Col>
          <Col lg="2" md="2" sm="2" xs="2" className="flex">
            <h5>
              Download{" "}
              <span>
                {" "}
                <FontAwesomeIcon icon={faDownload} />
              </span>
            </h5>
          </Col>
        </Row>
      </header> */}
      <div className="mt-2  ">
        <Row>
          {/* <Col lg="2" md="2" sm="2" xs="2"></Col> */}
          {!isEmpty(customerName) && (
            <>
              <Col lg="3" md="3" sm="3" xs="3">
                <label>Purchase Order number</label>
                <input
                  type="text"
                  value={customerName}
                  disabled
                  className="form-control "
                  onChange={(e) => setCustomerName(e.target.value)}
                  placeholder="Enter Purchase Order number..."
                />
              </Col>
            </>
          )}
          <Col lg="3" md="3" sm="3" xs="3">
            <label>Date</label>
            <input
              type="date"
              className="form-control "
              value={customerDate}
              onChange={(e) => setCustomerData(e.target.value)}
              placeholder="Enter Date..."
            />
          </Col>
          <Col lg="3" md="3" sm="3" xs="3">
            <label>Vendor</label>
            <Form.Select
              aria-label="Default select example"
              style={{ height: "58%" }}
              value={CustomerVendor || ""}
              onChange={(e) => setCustomerVendor(e.target.value)}
            >
              {vendorOption.map((vendor) => (
                <option key={vendor.id} value={vendor.id}>
                  {vendor.name}
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col lg="3" md="3" sm="3" xs="3">
            <label>Shipping Address</label>
            <CKEditor
              editor={ClassicEditor}
              data={customerAddress}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCustomerAddress(data);
              }}
              config={{
                placeholder: "Enter Shipping Address...",
              }}
            />
          </Col>
          {/* <Col lg="2" md="2" sm="2" xs="2"></Col> */}
        </Row>
        {/* <Row>
          <Col lg="2" md="2" sm="2" xs="2"></Col>
          <Col lg="4" md="4" sm="4" xs="4">
            <label>Vendor</label>
            <Form.Select
              aria-label="Default select example"
              style={{ height: "58%" }}
              value={CustomerVendor || ""}
              onChange={(e) => setCustomerVendor(e.target.value)}
            >
              {state.vendor.map((vendor) => (
                <option key={vendor.id} value={vendor.id}>
                  {vendor.name}
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col lg="4" md="4" sm="4" xs="4">
            <label>Shipping Address</label>
            <input
              type="text"
              className="form-control "
              disabled
              value={customerAddress}
              onChange={(e) => setCustomerAddress(e.target.value)}
              placeholder="Enter Shipping Address..."
            />
          </Col>
          <Col lg="2" md="2" sm="2" xs="2"></Col>
        </Row> */}
      </div>
      {!isEmpty(CustomerVendor) && CustomerVendor != "0" && (
        <>
          <div className="category-section">
            <Row style={{ height: "50px" }}>
              <Col lg="9" md="9" sm="9" xs="9">
                <h4>Labour Details</h4>
              </Col>
              <Col lg="3" md="3" sm="3" xs="3" className="flexend">
                <button
                  className="btn excelbtn mr-2"
                  onClick={PoformClick}
                  style={{ width: "120px" }}
                >
                  PO Form{" "}
                </button>
              </Col>
            </Row>

            <div className="table-container mt-2">
              <table className="custom-table-ban">
                <thead>
                  <tr>
                    <th>SNo</th>
                    <th>Labour</th>
                    <th>Count</th>
                    <th> Price</th>
                    <th>Total</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ItemData?.length > 0
                    ? ItemData.map((itm: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {/* <Form.Select
                                                    aria-label="Default select example"
                                                    name="product"
                                                    style={{ height: "58%" }}
                                                    value={itm.product_id || ""}
                                                    onChange={(e) => {
                                                        const selectedItem = state.item.find(item => item.id == e.target.value);
                                                        selectOnchange(e, index, selectedItem);
                                                    }}
                                                >
                                                <option value="">Select Item</option>
                                                    {state.item.map((item: any) => (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {item.product_name}
                                                        </option>
                                                    ))}
                                                </Form.Select> */}
                              <input
                                className="table-input-style textcenter"
                                type="text"
                                name="product"
                                value={itm.product}
                                onChange={(e) => onchange(e, index)}
                              />
                              {isValid && itm.product == "" && (
                                <p
                                  style={{
                                    margin: "0px 5px",
                                    textAlign: "left",
                                    color: "red",
                                    fontSize: "16px",
                                  }}
                                >
                                  Required Field.
                                </p>
                              )}
                            </td>
                            <td>
                              <input
                                className="table-input-style textcenter"
                                type="number"
                                name="po_qty"
                                value={itm.po_qty}
                                onChange={(e) => onchange(e, index)}
                              />
                              {isValid && itm.po_qty == "" && (
                                <p
                                  style={{
                                    margin: "0px 5px",
                                    textAlign: "left",
                                    color: "red",
                                    fontSize: "16px",
                                  }}
                                >
                                  Required Field.
                                </p>
                              )}
                            </td>
                            <td>
                              <input
                                className="table-input-style textcenter"
                                type="number"
                                name="po_unit_price"
                                value={itm.po_unit_price}
                                onChange={(e) => onchange(e, index)}
                              />
                              {isValid && itm.po_unit_price == "" && (
                                <p
                                  style={{
                                    margin: "0px 5px",
                                    textAlign: "left",
                                    color: "red",
                                    fontSize: "16px",
                                  }}
                                >
                                  Required Field.
                                </p>
                              )}
                            </td>
                            <td>
                              <input
                                type="number"
                                className="table-input-style textcenter"
                                name="po_total"
                                value={itm.po_total}
                                onChange={(e) => onchange(e, index)}
                              />
                              {isValid && itm.po_unit_price == "" && (
                                <p
                                  style={{
                                    margin: "0px 5px",
                                    textAlign: "left",
                                    color: "red",
                                    fontSize: "16px",
                                  }}
                                >
                                  Required Field.
                                </p>
                              )}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                className="remove-row-btn"
                                style={{ width: "30px" }}
                                onClick={() => deleteItemRow(index)}
                              >
                                <span style={{ fontWeight: "900" }}>-</span>
                              </button>
                              <button
                                className="add-row-btn"
                                onClick={addItemRow}
                              >
                                <span style={{ fontWeight: "900" }}>+</span>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                  {ItemData?.length > 0 && (
                    <tr>
                      <td
                        colSpan={4}
                        style={{ textAlign: "right", fontWeight: "bold" }}
                      ></td>
                      <td className="grandtotal">
                        Grand Total ={" "}
                        {ItemData.reduce(
                          (acc: any, curr: any) =>
                            acc + parseFloat(curr.po_total || 0),
                          0
                        ).toFixed(2)}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="category-section">
            <Row>
              <Col lg="3" md="3" sm="3" xs="3">
                <textarea
                  className="form-control"
                  placeholder="Comments or Instruction"
                  name="prjname"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  required
                />
              </Col>
            </Row>
            <Row style={{ height: "50px" }}>
              <Col lg="3" md="3" sm="3" xs="3" className="flex">
                {/* <Button
                variant="outline-primary subbutton"
                type="submit"
                className="subbtn"
                onClick={submitHandelClick}
              >
                Submit{" "}
              </Button> */}
                <button
                  className="btn excelbtn mr-2"
                  onClick={submitHandelClick}
                  style={{ width: "100px" }}
                >
                  Save{" "}
                </button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
}

export default PoLabourPage;
