// Sidebar.tsx
import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import Dashboard from "../../assets/sidebar/d&ddashboard.svg";
import DashboardSelected from "../../assets/sidebar/d&dDashboardw.svg";
import Deals from "../../assets/sidebar/d&dSite.svg";
import DealsSelected from "../../assets/sidebar/d&dSitew.svg";
import Dropdown from "../../assets/sidebar/dropdown.svg";
import Goto from "../../assets/sidebar/goto.svg";
import Leads from "../../assets/sidebar/d&dVendor.svg";
import LeadsSelected from "../../assets/sidebar/d&dVendorw.svg";
import Notifications from "../../assets/sidebar/notifications.svg";
import Payments from "../../assets/sidebar/d&dProduct.svg";
import Renewals from "../../assets/sidebar/d&dexpenses.svg";
import PaymentsSelected from "../../assets/sidebar/d&dProductw.svg";
import RenewalsSelected from "../../assets/sidebar/d&dexpensesw.svg";
import Setting from "../../assets/sidebar/settings.svg";
import user from "../../assets/sidebar/sample-user.svg";
import Logo from "../../assets/sidebar/D&Dlogoofinal.png";
import Open from "../../assets/sidebar/open.svg";
import close from "../../assets/sidebar/rightarrow.jpg";
import closeIcon from "../../assets/dashboard/close.svg";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { showAlert } from "../../Utils/sweetAlert";
import Usermanagement from "./usermanagement/Usermanagement";
import useractive from "../../assets/sidebar/d&duser-managementw.svg"
import  usernoactive from "../../assets/sidebar/d&duser-management 1.svg"

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  handleMenu: (menu:any) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar, handleMenu }) => {
  const [activeMenu, setActiveMenu] = useState<string>(() => {
    const storedMenu = localStorage.getItem("activeMenu");
    return storedMenu ? storedMenu : "Dashboard";
  });

  const handleMenuClick = (menu: string) => {
    setActiveMenu(menu);
    localStorage.setItem("activeMenu", menu);
    console.log("activeMenu", menu);
    handleMenu(menu);
  };
const navigate=useNavigate()
  const isMobile = useMediaQuery("(max-width:767px)");
  const handlelogout = async () => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to log out?",
      "Yes",
      "No"
  );
  if (isComfirm) {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/")
  }
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-header">
        <div className="header-content">
          <div className="sidebar-heading" style={{ textAlign: "center" }}>
            {/* <Link to="/"> */}
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "85%", textAlign: "center" }}
                onClick={() => handleMenuClick("Dashboard")}
              />
            {/* </Link> */}
          </div>
          {/* {isOpen && <div className="logo-title">D&D</div>} */}
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {isOpen ? (
              <img src={Open} alt="open" className="arrow-icon1" />
            ) : (
              <img
                src={close}
                alt="Close"
                className="arrow-icon2"
                style={{ zIndex: "1" }}
              />
            )}
          </div>
        </div>
      </div>

      <ul className="sidebar-menu">
        <div>
          {/* Dashboard */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Dashboard"
                    ? "linear-gradient(180deg, #00c8ff, #045971)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Dashboard" ? "white" : "black",
              }}
              to="/layout/dashboard"
              onClick={() => handleMenuClick("Dashboard")}
            >
              <img
                src={activeMenu === "Dashboard" ? DashboardSelected : Dashboard}
                alt="dashboard"
              />{" "}
              <div className="sidebar-title">
                {isOpen && <span style={{ fontWeight: "00" }}>Dashboard</span>}
                {isOpen && activeMenu !== "Dashboard" && (
                  <img src={Goto} alt="go-to" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
          {/* Leads */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Vendor" ? "linear-gradient(180deg, #00c8ff, #045971)" : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Vendor" ? "white" : "black",
              }}
              to="/layout/listofvendor"
              onClick={() => handleMenuClick("Vendor")}
            >
              <img
                src={activeMenu === "Vendor" ? LeadsSelected : Leads}
                alt="Vendor"
              />{" "}
              <div className="sidebar-title">
                {isOpen && <span style={{ fontWeight: "00" }}>Vendor</span>}
                {isOpen && activeMenu !== "Vendor" && (
                  <img src={Goto} alt="Vendor" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
          {/* Deals */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Site" ? "linear-gradient(180deg, #00c8ff, #045971)" : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Site" ? "white" : "black",
              }}
              to="/layout/sitelist"
              onClick={() => handleMenuClick("Site")}
            >
              <img
                src={activeMenu === "Site" ? DealsSelected : Deals}
                alt="Site"
              />
              <div className="sidebar-title">
                {isOpen && <span style={{ fontWeight: "00" }}>Site</span>}
                {isOpen && activeMenu !== "Site" && (
                  <img src={Goto} alt="go-to" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
          {/* Renewals */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Expenses"
                    ? "linear-gradient(180deg, #00c8ff, #045971)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Expenses" ? "white" : "black",
              }}
              to="/layout/expence"
              onClick={() => handleMenuClick("Expenses")}
            >
              <img
                src={activeMenu === "Expenses" ? RenewalsSelected : Renewals}
                alt="Expenses"
              />{" "}
              <div className="sidebar-title">
                {isOpen && <span style={{ fontWeight: "00" }}>Expenses</span>}
                {isOpen && activeMenu !== "Expenses" && (
                  <img src={Goto} alt="Goto" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
          {/* Payments */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Product"
                    ? "linear-gradient(180deg, #00c8ff, #045971)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Product" ? "white" : "black",
              }}
              to="/layout/product"
              onClick={() => handleMenuClick("Product")}
            >
              <img
                src={activeMenu === "Product" ? PaymentsSelected : Payments}
                alt="Product"
              />{" "}
              <div className="sidebar-title">
                {isOpen && <span style={{ fontWeight: "00" }}>Product</span>}
                {isOpen && activeMenu !== "Product" && (
                  <img src={Goto} alt="Goto" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "User Management"
                    ? "linear-gradient(180deg, #00c8ff, #045971)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "User Management" ? "white" : "black",
              }}
              to="/layout/usermanagement"
              onClick={() => handleMenuClick("User Management")}
            >
              <img
                src={
                  activeMenu === "User Management" ? useractive : usernoactive
                }
                alt="User Management"
              />{" "}
              <div className="sidebar-title">
                {isOpen && (
                  <span style={{ fontWeight: "00" }}>User Management</span>
                )}
                {isOpen && activeMenu !== "User Management" && (
                  <img src={Goto} alt="Goto" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
        </div>

        <div className="sidebar-menu">
          {/* Notifications */}
          <li
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingRight: "0px",
            }}
          ></li>
          {/* user */}
          <li>
            <div
              
              className="menu-button"
              style={{
                backgroundColor:"lightgray",
                color:"gray",
                padding: "0px",
                height:"50px"
              }}
              onClick={() => handlelogout()}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div style={{ marginLeft: "5%" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      marginLeft: "0%",
                      color: "black",
                    }}
                   
                  >
                    LOG OUT
                  </span>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
