import React, { useEffect, useState } from "react";
import "./Quotationpdf.css";
import { Col, Row, Table } from "react-bootstrap";
import { callApi } from "../../Utils/Api";
import html2canvas from "html2canvas";
import { useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import { showError, showSuccess } from "../../Utils/sweetAlert";
import rs from "../../assets/rs.svg";
import rs1 from "../../assets/rs1.svg";
const Quotationpdf = () => {
  const navigate = useNavigate();
  const { quoId } = useParams<{ quoId: string }>();
  const [invstat, setinvstatus] = useState("");
  const [quodate, setquodate] = useState("");
  const [quoiddata, setquoiddata] = useState("");
  const [quoadd, setquoadd] = useState("");
  const [sitename, setsitename] = useState("");

  const [state, setState] = useState({
    QuoteListSet: {
      contact: "99433 44464",
      website: "http://www.ddarchitects.co",
      project: {
        name: "Residential",
        client: "Mr. Devanandh (Ar. Ravirajan)",
        location: "Pollachi",
      },
      vendor: {
        name: "D&D Architects",
        address:
          "D&D Architects,F7, Shanmugam complex, 1st floor, Mtp Road, R S Puram, Coimbatore - 641002",
      },
      quoteNumber: "542",
      date: "27-06-2024",
      sections: [
        {
          categoryName: "Dining room",
          categoryItems: [
            {
              description: "Wall Design unit",
              length: 3,
              height: 9,
              area: 27.0,
              amount: " 45,000",
            },
            {
              description: "Pooja room Lumpsum",
              length: 5,
              height: 5,
              area: 25.0,
              amount: " 60,000",
            },
          ],
          material: "",
          total: " 105,000",
        },
      ],
      lessWorkTotalAmount: "0",
      extraWorkTotalAmount: "0",
      extraWork: [],
      lessWork: [],
      materials: [
        "Century Waterproof plywood 19mm - 710",
        "Inside 0.8 mm century",
        "Acrylic 1.2mm imported",
        "Hettich / Hafele",
        "Hafele / Olive brand lifetime warranty",
        "G profile Handle - SS or copper",
        "Adhesive  Fevicol SH/SR (Speedex)",
      ],
      address: "",
    },
    quotationData: {},
    allGrandTotal: "",
  });

  useEffect(() => {
    getQuotatilDetails();
  }, []);

  useEffect(() => {
    console.log("QuoteListSet", state.QuoteListSet, state.allGrandTotal);
  }, [state.QuoteListSet, state.allGrandTotal]);

  const convertInvoice = () => {
    const data: any = state.quotationData;

    const lessWorkTotalAmount = data.lesswork.reduce(
      (total: number, category: any) => {
        return (
          total +
          category.categoryItems.reduce((categoryTotal: number, item: any) => {
            return categoryTotal + parseFloat(item.amount);
          }, 0)
        );
      },
      0
    );

    const extraWorkTotalAmount = data.extraWork.reduce(
      (total: number, category: any) => {
        return (
          total +
          category.categoryItems.reduce((categoryTotal: number, item: any) => {
            return categoryTotal + parseFloat(item.amount);
          }, 0)
        );
      },
      0
    );

    const overallTotal = state.QuoteListSet.sections.reduce(
      (total, section) => {
        return total + totalForCategory(section.categoryItems);
      },
      0
    );

    const lessWorkAmount = parseFloat(lessWorkTotalAmount ?? "0");
    const grandTotal = overallTotal - lessWorkAmount;
    const allgrandTotal: any = grandTotal + extraWorkTotalAmount ?? 0;

    callApi("post", "invoice_create.php", {
      ...data,
      inv_id: "",
      quo_id: quoId,
      quo_amt: allgrandTotal,
    })
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["responseCode"] === 200) {
          showSuccess("Success!", response.message);
          setTimeout(() => {
            navigate(`/layout/sitedetails/${data.site_id}`);
          }, 2000);
        } else {
          showError("Failed", response.message);
        }
      });
  };

  const getQuotatilDetails = () => {
    const req = {
      quo_id: quoId,
    };
    callApi("post", "quo_detail.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["responseCode"] === 200) {
          const data = response.responseJson;
          setinvstatus(data.inv_status);
          setquodate(data.date);
          setquoiddata(data.quo_no);
          setsitename(data.site_name);
          setquoadd(data.address);
          const lessWorkTotalAmount = data.lesswork.reduce(
            (total: number, category: any) => {
              return (
                total +
                category.categoryItems.reduce(
                  (categoryTotal: number, item: any) => {
                    return categoryTotal + parseFloat(item.amount);
                  },
                  0
                )
              );
            },
            0
          );

          const extraWorkTotalAmount = data.extraWork.reduce(
            (total: number, category: any) => {
              return (
                total +
                category.categoryItems.reduce(
                  (categoryTotal: number, item: any) => {
                    return categoryTotal + parseFloat(item.amount);
                  },
                  0
                )
              );
            },
            0
          );

          setState((prevState: any) => ({
            ...prevState,
            QuoteListSet: {
              ...prevState.QuoteListSet,
              sections: data.category ?? [],
              extraWork: data.extraWork ?? [],
              lessWork: data.lesswork ?? [],
              lessWorkTotalAmount: lessWorkTotalAmount,
              extraWorkTotalAmount: extraWorkTotalAmount,
              address: data.address,
            },
            quotationData: data,
          }));
        }
      });
  };

  const logo1 = require("../../assets/sidebar/image1.png");

  const totalForCategory = (categoryItems: any) => {
    return categoryItems.reduce(
      (total: any, item: any) => total + parseFloat(item.amount),
      0
    );
  };

  const overallTotal = state.QuoteListSet.sections.reduce((total, section) => {
    return total + totalForCategory(section.categoryItems);
  }, 0);

  const lessWorkTotalAmount = parseFloat(
    state.QuoteListSet.lessWorkTotalAmount ?? "0"
  );
  const grandTotal = overallTotal - lessWorkTotalAmount;
  const allgrandTotal =
    grandTotal + state.QuoteListSet.extraWorkTotalAmount ?? 0;

  const downloadPdf = async () => {
    console.log("Download started");
    setState((prevState) => ({
      ...prevState,
      loader: true,
    }));

    const doc = new jsPDF("p", "mm", "a4");
    const elementsToInclude = document.querySelectorAll(".quotation-content");
    const promises: Promise<void>[] = [];

    elementsToInclude.forEach((element, index) => {
      const htmlElement = element as HTMLElement;
      promises.push(
        html2canvas(htmlElement).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          const aspectRatio = imgWidth / imgHeight;
          const adjustedWidth = pageWidth;
          const adjustedHeight = pageWidth / aspectRatio;

          if (index !== 0) {
            doc.addPage();
          }

          if (adjustedHeight > pageHeight) {
            let heightLeft = adjustedHeight;
            let position = 0;
            while (heightLeft >= 0) {
              doc.addImage(
                imgData,
                "PNG",
                0,
                position,
                adjustedWidth,
                adjustedHeight
              );
              heightLeft -= pageHeight;
              position -= pageHeight;
              if (heightLeft > 0) {
                doc.addPage();
              }
            }
          } else {
            doc.addImage(imgData, "PNG", 0, 0, adjustedWidth, adjustedHeight);
          }
        })
      );
    });

    await Promise.all(promises);

    doc.save("quotation.pdf");

    setState((prevState) => ({
      ...prevState,
      loader: false,
    }));
  };

  const handlegoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <div style={{ padding: "20px" }}>
        <button
          style={{ float: "left" }}
          className="btn excelbtn mr-2 pdf-btn"
          onClick={handlegoBack}
        >
          Go Back
        </button>
        {invstat === "0" && (
          <button
            style={{ float: "left" }}
            onClick={convertInvoice}
            className="btn excelbtn convert-btn"
          >
            Convert to Invoice
          </button>
        )}

        <button
          style={{ float: "right" }}
          onClick={downloadPdf}
          className="btn excelbtn pdf-btn"
        >
          Download PDF
        </button>
      </div>
      <div className="quotation-content" style={{ border: "none" }}>
        <div className="card quotationcard">
          <div className="header1">
            <div className="">
              <img src={logo1} alt="D&D Architects Logo" width="100" />
            </div>
            <div className="title-qt">
              <h3>D&D ARCHITECTS</h3>
            </div>
            <div className="quotation-text">
              <h4>QUOTATION</h4>
            </div>
          </div>
          <div className="section-title contact-info">
            <p>Contact: {state.QuoteListSet.contact}</p>
            <p>Website: {state.QuoteListSet.website}</p>
          </div>
          <div className="section">
            <div className="quote-info">
              <div className="projectdiv">
                <p>
                  <strong>Project: {sitename}</strong>
                </p>
              </div>
              <div className="projectdiv">
                <p>
                  <strong>Vendor</strong>
                </p>
              </div>
              <div></div>
            </div>
          </div>
          <div className="section">
            <div className="quote-info">
              <div
                style={{
                  backgroundColor: "#00a7f6",
                  color: "white",
                  padding: "5px",
                }}
              >
                <p>
                  <strong dangerouslySetInnerHTML={{ __html: quoadd }} />
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "#00a7f6",
                  color: "white",
                  padding: "5px",
                }}
              >
                <p>
                  {" "}
                  <strong>{state.QuoteListSet?.vendor?.address}</strong>{" "}
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "rgb(89 89 89)",
                  color: "white",
                  padding: "5px",
                }}
              >
                <p>
                  <strong>Quote </strong>{" "}
                  <span className="quotespace">:{quoiddata} </span>
                  <br />
                </p>
                <p>
                  <strong>Date </strong>{" "}
                  <span className="datespace">:{quodate}</span>
                </p>
              </div>
            </div>
          </div>
          {state.QuoteListSet.sections.map((section, index) => (
            <div className="section roomsection" key={index}>
              <div
                className="section-title"
                style={{
                  backgroundColor: "rgb(89 89 89)",
                  color: "white",
                  width: "35%",
                }}
              >
                <strong> {section.categoryName} </strong>
              </div>
              <table className="table1">
                <thead>
                  <tr>
                    <th>S No</th>
                    <th>Description</th>
                    <th>Length</th>
                    <th>H/W</th>
                    <th>Area</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {section.categoryItems.map((item, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{item.description}</td>
                      <td>{item.length}</td>
                      <td>{item.height}</td>
                      <td>{item.area}</td>
                      <td>
                        <img src={rs1} style={{ backgroundColor: "#ffff" }} />{" "}
                        {item.amount}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      className="total"
                      style={{
                        backgroundColor: "rgb(89 89 89)",
                        color: "white",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{
                        backgroundColor: "rgb(89 89 89)",
                        color: "white",
                      }}
                    >
                      <img src={rs} style={{ backgroundColor: "#595959" }} />{" "}
                      {section.categoryItems.reduce(
                        (total, item) => total + parseFloat(item.amount),
                        0
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {section.material !== "" ? (
                <>
                  <div className="materials-section">
                    <h5 className="underline">Materials:</h5>
                    <p
                      dangerouslySetInnerHTML={{ __html: section.material }}
                    ></p>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          ))}

          <div className="totalsection">
            <Table style={{ backgroundColor: "rgb(89 89 89)", color: "white" }}>
              <tr className="tabletrline">
                <td className="totaltd">Total {"       "}</td>
                <td className="totaltd">
                  <img src={rs} style={{ backgroundColor: "#595959" }} />{" "}
                  {overallTotal}
                </td>
              </tr>
            </Table>
          </div>

          {state.QuoteListSet.lessWork.map((section: any, index: any) => (
            <div className="section roomsection" key={index}>
              <div
                className="section-title"
                style={{
                  backgroundColor: "rgb(89 89 89)",
                  color: "white",
                  width: "35%",
                }}
              >
                <strong> {section.categoryName} </strong>
              </div>
              <table className="table1">
                <thead>
                  <tr>
                    <th>Sl No</th>
                    <th>Description</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {section.categoryItems.map((item: any, idx: any) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{item.description}</td>
                      <td>
                        <img src={rs1} style={{ backgroundColor: "#ffff" }} />{" "}
                        {item.amount}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td
                      className="total"
                      style={{
                        backgroundColor: "rgb(89 89 89)",
                        color: "white",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{
                        backgroundColor: "rgb(89 89 89)",
                        color: "white",
                      }}
                    >
                      <img src={rs} style={{ backgroundColor: "#595959" }} />{" "}
                      {section.categoryItems.reduce(
                        (total: any, item: any) =>
                          total + parseFloat(item.amount),
                        0
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
          <div className="totalsection">
            <Table style={{ backgroundColor: "rgb(89 89 89)", color: "white" }}>
              {state.QuoteListSet.lessWork &&
                state.QuoteListSet.lessWork.length > 0 && (
                  <tr className="tabletrline">
                    <td className="totaltd">Less work {"       "}</td>
                    <td className="totaltd">
                      <img src={rs} style={{ backgroundColor: "#595959" }} />{" "}
                      {state.QuoteListSet.lessWorkTotalAmount ?? "0"}
                    </td>
                  </tr>
                )}
            </Table>
          </div>

          {state.QuoteListSet.extraWork.map((section: any, index: any) => (
            <>
              <div className="section roomsection" key={index}>
                <div
                  className="section-title"
                  style={{
                    backgroundColor: "rgb(89 89 89)",
                    color: "white",
                    width: "35%",
                  }}
                >
                  <strong> {section.categoryName} </strong>
                </div>
                <table className="table1">
                  <thead>
                    <tr>
                      <th>Sl No</th>
                      <th>Description</th>
                      <th>Length</th>
                      <th>H/W</th>
                      <th>Area</th>
                      <th>Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {section.categoryItems.map((item: any, idx: any) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.description}</td>
                        <td>{item.length}</td>
                        <td>{item.height}</td>
                        <td>{item.area}</td>
                        <td>
                          <img src={rs1} style={{ backgroundColor: "#ffff" }} />{" "}
                          {item.amount}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        className="total"
                        style={{
                          backgroundColor: "rgb(89 89 89)",
                          color: "white",
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          backgroundColor: "rgb(89 89 89)",
                          color: "white",
                        }}
                      >
                        <img src={rs} style={{ backgroundColor: "#595959" }} />{" "}
                        {section.categoryItems.reduce(
                          (total: any, item: any) =>
                            total + parseFloat(item.amount),
                          0
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="totalsection">
                <Table
                  style={{ backgroundColor: "rgb(89 89 89)", color: "white" }}
                >
                  <tr className="tabletrline">
                    <td className="totaltd">Total {"       "}</td>
                    <td className="totaltd">
                      <img src={rs} style={{ backgroundColor: "#595959" }} />{" "}
                      {section.categoryItems.reduce(
                        (total: any, item: any) =>
                          total + parseFloat(item.amount),
                        0
                      )}
                    </td>
                  </tr>
                </Table>
              </div>
            </>
          ))}

          <div className="totalsection">
            <Table style={{ backgroundColor: "rgb(89 89 89)", color: "white" }}>
              <tr className="tabletrline">
                <td className="totaltd">Overall Total</td>
                <td className="totaltd">
                  <img src={rs} style={{ backgroundColor: "#595959" }} />{" "}
                  {allgrandTotal ?? 0}
                </td>
              </tr>
              {/* <tr className="tabletrline">
              <td className="totaltd">Received Amount</td>
              <td className="totaltd"><img src={rs} style={{ backgroundColor: "#595959" }} />{" "} 45000</td>
            </tr>
            <tr className="tabletrline">
              <td className="totaltd">Balance</td>
              <td className="totaltd"><img src={rs} style={{ backgroundColor: "#595959" }} />{" "} 55000</td>
            </tr> */}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotationpdf;
