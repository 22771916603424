import React, { FormEvent, useState, ChangeEvent, useEffect } from "react";
import Papa from "papaparse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../usermanagement/usermanagement.css";
import { showAlert, showError, showSuccess } from "../../../Utils/sweetAlert";
import { callApi } from "../../../Utils/Api";
import { isEmpty } from "validator";
import { Table, Pagination, Modal, Button } from "rsuite";
import {
  faAngleLeft,
  faAngleRight,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import "../../page/site/Sitelist.css"
import ExcelJS from 'exceljs';

const Nameofproduct = () => {
  const [formData, setFormData] = useState<any>({
    id: "",
    name: "",
    vendor: "",
    price: "",
  });
  const [state, setState] = useState({
    vendor: [
      {
        id: "",
        name: "",
      },
    ],
  });

  const [error, setError] = useState(false);
  const [vendorerror, setvendornoerror] = useState(false);
  const [priceerror, setpriceerror] = useState(false);
  const [productnameerror, setproductnameerror] = useState(false);
  const [passworderror, setpassworderror] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [confirm_passworderror, setconfirm_passworderror] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { Column, HeaderCell, Cell } = Table;
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [productlist, setProductlist] = useState<any>([]);
  const location = useLocation();
  const [CustomerVendor, setCustomerVendor] = useState("");

  const totalPages = Math.ceil(productlist.length / rowsPerPage);
  const paginatedData = productlist.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const getData = () => {
    let filteredData = productlist;

    // Apply search filter if searchQuery exists
    if (searchQuery) {
      filteredData = productlist.filter((row: any) =>
        Object.values(row).some((value) =>
          typeof value === "string"
            ? value.toLowerCase().includes(searchQuery.toLowerCase())
            : false
        )
      );
    }

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a: any, b: any) => {
        let x = a[sortColumn];
        let y = b[sortColumn];

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleProductChangeLimit = (dataKey: React.SetStateAction<number>) => {
    setPage(1);
    setLimit(dataKey);
  };

  const data = productlist.filter((v: any, i: any) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      name: "",
      price: "",
      vendor: "",
    });
    setError(false);
    setCustomerVendor("")
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleCheckbox = async (e: any, id: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to change the status?",
      "Yes",
      "No"
    );
    const { checked } = e.target;
    console.log("handleCheckbox_____", checked);
    if (isComfirm) {
      const req = {
        id: id,
        status: checked == false ? "1" : "0",
      };
      callApi("POSt", "Product.delete.php", req)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response.responseCode === 200) {
            showSuccess("CHANGE!", response.message);
            productDetails();
          } else {
            showError("FAILED!", response.message);
          }
        });
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "price") {
      const isValidInput = /^[0-9/]*$/.test(value);
      if (!isValidInput) {
        setpriceerror(true);
      } else {
        setpriceerror(false);
      }
    } else if (name === "name") {
      const isValidInput = /^[a-zA-Z ]+$/.test(value);
      if (!isValidInput) {
        setproductnameerror(true);
        console.error("Invalid input. Please enter only characters.");
      } else {
        setproductnameerror(false);
      }
    } else if (name === "vendor") {
      const isValidInput = /^[0-9/-]*$/.test(value);
      if (!isValidInput) {
        setvendornoerror(true);
        console.error("Invalid input. Please enter only characters.");
      } else {
        setvendornoerror(false);
      }
    }
  };
  useEffect(() => {
    productDetails();
  }, []);

  useEffect(() => {
    console.log("----44444444444444-------....>>", CustomerVendor);
  }, [CustomerVendor]);

  const handleproductcreate = (e: FormEvent) => {
    e.preventDefault();

    console.log("formFields_________", formData, CustomerVendor);

    if (isEmpty(CustomerVendor) || isEmpty(formData.price)) {
      setError(true);
    } else {
      console.log("jgjhjh", modalTitle);

      if (modalTitle === "Add Product+Vendor") {
        let request = {
          product_id: rowData.id,
          product_name: formData.name,
          vendor_id: CustomerVendor,
          product_price: formData.price,
        };
        console.log("-----------....>>", CustomerVendor);

        callApi("POST", "product_create.php", request)
          .then((res: any) => res.data)
          .then((response: any) => {
            if (response.responseCode === 200) {
              showSuccess("Success!", response.message);
              setFormData({
                name: "",
                price: "",
                vendor: "",
              });
              setCustomerVendor("")
              setShowModal(false);
              productDetails();
              setError(false)
            } else {
              showError("Failed", response.message);
            }
          });
      } else {
        editingdetails();
      }
    }
  };
  const { rowData } = location.state || {};

  console.log("roedata---->234567", rowData);

  const productDetails = () => {
    const request = {
      product_id: rowData.id,
    };
    callApi("post", "product_detail.php", request)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["response_Code"] === 200) {
          console.log("product--->", response.responseJson);
          setProductlist(response.responseJson);
        }
        console.log("product--->", productlist);
      });

    let req = {
      vendor_id: CustomerVendor,
    };

    callApi("POST", "vendor_list.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("58645845158456-------------->", response);

        if (response['response_Code'] == 200) {
          let vendordroplistdata: any = [];
          response.responseJson.forEach((element: any) => { //vendor active 1
            if (element.vendor_status == "1") {
              vendordroplistdata.push(element)
            }
          })
          console.log("fgchjkl;j-------------------", vendordroplistdata);

          setState((prevState: any) => ({
            ...prevState,
            vendor: vendordroplistdata,
          }));

          setCustomerVendor(response?.responseJson[0]?.vendor_id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("poData_____________", state);
  };

  const vendoroptions = [{ id: "0", name: "Select Vendor" }, ...state.vendor];
  const addProduct = () => {
    // rowData
    setFormData({
      name: rowData.product_name,
      price: "",
      vendor: "",
    });
    setCustomerVendor("")
    setModalTitle("Add Product+Vendor");
    setShowModal(true);
  };

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const productEdit = (data: any) => {
    console.log("this is the data", data);
    setFormData({
      id: data.id,
      name: data.product_name,
      price: data.price,
      vendor: data.vendor_id,
      product_id: data.product_id,
    });
    console.log("-------------6543", formData);
    setCustomerVendor(data.vendor_id);
    setModalTitle("Edit Product+Vendor");
    setShowModal(true);
  };

  const editingdetails = () => {
    let request = {
      row_id: formData.id,
      product_name: formData.name,
      vendor_id: CustomerVendor,
      product_price: formData.price,
      product_id: formData.product_id,
    };
    console.log("req", request);
    callApi("post", "product_update.php", request)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response.responseCode === 200) {
          showSuccess("EDITED!", response.message);
          productDetails();
          setShowModal(false);
        } else {
          showError("FAILED!", response.message);
        }
      });
  };

  const productdelet = async (row: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to delete ?",
      "Yes",
      "No"
    );
    console.log("{rfgtr}", row);

    if (isComfirm) {
      let requ = {
        row_id: row.id,
      };
      callApi("POSt", "product_delete.php", requ)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response.responseCode === 200) {
            showSuccess("DELETED!", response.message);
            productDetails();
          } else {
            showError("FAILED!", response.message);
          }
        });
    }
  };

  const downloadCSV = () => {
    const data = [
      ["Product_pricevariation_details"],
      ["S.No", "Updated Date", "Vendor", "Price", "Variation From Last Price"],
      ...productlist.map((row: any) => [
        row.s_no,
        row.updated_date,
        row.vendor,
        row.price,
        row.variation,
      ]),
    ];

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add rows to the worksheet
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    // Merge the title row across all columns and center the title
    worksheet.mergeCells('A1:E1');
    const titleCell = worksheet.getCell('A1');
    titleCell.value = "Product Price Variation Details"; // Set the title value
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
    titleCell.font = { bold: true, size: 14 };

    // Style for the header row (second row)
    const headerRow = worksheet.getRow(2);
    headerRow.eachCell((cell: any) => {
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { bold: true };
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } }; // Yellow background
    });

    // Write the workbook to an Excel file
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Product_pricevariation_details_${formattedDate}.xlsx`;
      link.click();
    });
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission
      // You can add any additional actions here if needed
    }
  };


  return (
    <>
      <div className="container-qt">
        {/* <div className="rs-panel-body"> */}
        <div className="rs-table rs-table-hover">
          <div className="tabletitle" style={{ fontSize: "24px" }}>Product Price Variation</div>
          <div className="table-header">
            <div className='vendor-add-btn'>
              <button
                type="button"
                className="btn excelbtn exbtn"
                style={{ marginRight: "10px" }}
                onClick={addProduct}
              >
                Add Vendor
              </button>
            </div>
            <div className="table-search searchfieldscontain vendor-excel">
              <button
                type="button"
                className="btn excelbtn btnmobiledash"
                style={{ marginRight: "10px" }}
                onClick={downloadCSV}
              >
                Excel
              </button>
              <div className="search-field1">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-input vendorsearch"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", overflowX: "hidden" }}>
            <Table
              height={420}
              data={getData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
              style={{ width: "100%" }} // Adjust min-width as needed
            >
              <Column width={50} align="center" fixed>
                <HeaderCell>S.No</HeaderCell>
                <Cell dataKey="s_no" />
              </Column>

              <Column width={100}>
                <HeaderCell>Updated Data</HeaderCell>
                <Cell dataKey="updated_date" />
              </Column>
              <Column width={100}>
                <HeaderCell>Vendor</HeaderCell>
                <Cell dataKey="vendor"></Cell>
              </Column>
              <Column width={100}>
                <HeaderCell>Price</HeaderCell>
                <Cell dataKey="price"></Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>Variation From Last Price</HeaderCell>
                <Cell dataKey="variation" />
              </Column>

              <Column width={100} align="center">
                <HeaderCell>Action</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div>
                      <button
                        onClick={() => productEdit(rowData)}
                        className="iconbg"
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                      <button
                        onClick={() => productdelet(rowData)}
                        className="iconbg"
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                    </div>
                  )}
                </Cell>
              </Column>
              {/* <Column width={200}>
                            <HeaderCell>Status</HeaderCell>
                            <Cell>
                                {(rowData) => (
                                    <label className="switch">
                                    <input type="checkbox" checked={rowData.status == "1"} onChange={(e) => handleCheckbox(e, rowData.id)} />
                                    <span className="slider round"></span>
                                </label>
                                )}
                            </Cell>
                        </Column> */}
            </Table>
          </div>
          <div>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              className="pagination"
              size="xs"
              layout={["total", "pager", "skip"]}
              total={productlist.length}
              limitOptions={[5, 10, 15]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleProductChangeLimit}
            />
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* Modal for adding new user */}
      <Modal open={showModal} onClose={handleCloseModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="modaltittle">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form style={{ padding: "2%", overflow: "hidden" }}>
            <div className="row">
              {/* <div className="col-md-6 form-group">
                <label htmlFor="name" className="labeltittle">
                  Product Name
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    error && isEmpty(formData.name) ? "is-invalid" : ""
                  } mt-1 mb-2`}
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {error && isEmpty(formData.name) && (
                  <div className="invalid-feedback">{"Product Name Error"}</div>
                )}
                {productnameerror && (
                  <div className="error-message" style={{ color: "red" }}>
                    Enter valid Name
                  </div>
                )}
              </div> */}
              <div className="col-md-6 form-group">
                <label className="labeltittle">Vendor<span className='mandatoryfield'>*</span></label>
                <Form.Select
                  className={`form-control ${error && isEmpty(CustomerVendor) ? "is-invalid" : ""
                    } mt-1 mb-2`} aria-label=""
                  style={{ height: "58%" }}
                  value={CustomerVendor}
                  onChange={(e) => setCustomerVendor(e.target.value)}
                  onKeyDown={handleKeyDown}
                >
                  {vendoroptions.map((vendor: any) => (
                    <option key={vendor.id} value={vendor.id}>
                      {vendor.name}
                    </option>
                  ))}
                </Form.Select>
                {error && isEmpty(CustomerVendor) && (
                  <div className="invalid-feedback" style={{ display: "block" }}>{"vendor Error"}</div>
                )}
                {vendorerror && (
                  <div className="error-message" style={{ color: "red" }}>
                    Enter valid vendor
                  </div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="price" className="labeltittle">
                  Amount<span className='mandatoryfield'>*</span>
                </label>
                <input
                  type="price"
                  className={`form-control ${error && isEmpty(formData.price) ? "is-invalid" : ""
                    } mt-1 mb-2`}
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  minLength={2}
                  maxLength={10}
                />
                {error && isEmpty(formData.price) && (
                  <div className="invalid-feedback">{"Price Error"}</div>
                )}
                {priceerror && (
                  <div className="error-message" style={{ color: "red" }}>
                    Enter valid Price Amount
                  </div>
                )}
              </div>
            </div>
            <Button
              className="newuser mt-3"
              onClick={(e) => handleproductcreate(e)}
            >
              {modalTitle}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Nameofproduct;
