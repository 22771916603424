import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Button, Col, DatePicker, Form, Grid, Modal, Pagination, Row, SelectPicker, Table } from 'rsuite'
import "./vendorsitelist.css"
import { showAlert, showError, showSuccess } from '../../../Utils/sweetAlert'
import { callApi } from '../../../Utils/Api'
import Papa from 'papaparse'
import { isEmpty } from 'validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
// import { Id } from '@reduxjs/toolkit/dist/tsHelpers'
import { useLocation, useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';

const Overallvendorstatement = () => {

    const [formData, setFormData] = useState({
        id: '',
        vendorname: '',
        site: "",
        credit: ""
    });

    const [error, setError] = useState(false);
    const [contactnumbererror, setcontactnumbererror] = useState(false);
    const [contactnameerror, setcontactnameerror] = useState(false);
    const [crediterror, setCrediterror] = useState(false)
    const [vendornameerror, setvendornameerror] = useState(false);
    const [gstnumbererror, setgstnumbererror] = useState(false);
    const [confirm_passworderror, setconfirm_passworderror] = useState(false)
    const [modalTitle, setModalTitle] = useState("");
    const [state, setState] = useState({
        UserListSet: [],
    });
    const [nameerror, setNameerror] = useState(false);
    const [passworderror, setpassworderror] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [isToggled, setIsToggled] = useState(false);
    const [billingaddresserror, setbillingaddresserror] = useState(false)
    // const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    // State for editing

    const [searchQuery, setSearchQuery] = useState("");

    const { Column, HeaderCell, Cell } = Table;
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [datas, setDatas] = useState([]);

    useEffect(() => {

    }, [fromDate,
        toDate]);
    console.log("---dff", fromDate, toDate);

    const handleSearch = () => {
        if (!fromDate || !toDate) {
            showError("FAILED!", "Please Select both Dates");
            return;
        }

        const from_date = formattedFromDate(fromDate);
        const to_date = formattedToDate(toDate);

        // const formattedFromDate = fromDate.toISOString().split('T')[0];
        // const formattedToDate = toDate.toISOString().split('T')[0];
        const request = {
            from_date: from_date,
            to_date: to_date,
            id: rowData.id
        }
        callApi("post", "vendor_statement.php", request)
            .then((res: any) => res.data)
            .then((response: any) => {
                console.log("response--->", response);
                if (response["response_Code"] === 200) {
                    console.log("product--->", response.responseJson)
                    if (response && response.responseJson) {
                        const data = response.responseJson;
                        console.log("response.responseJson_________", data);

                        // setState(data);
                        setState((prevState: any) => ({
                            ...prevState,
                            UserListSet: data,
                        }));
                    }
                }
                console.log("product---> 11", state)
            })
    };
    const handleDateChange = (value: any, event: any) => {
        setFromDate(value); // value is of type Date | null
        console.log(fromDate);
    };
    const formattedFromDate = (date: any) => {
        if (!fromDate) return ''; // handle undefined or null date
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based in JavaScript
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const handleDateChangeto = (value: any, event: any) => {
        setToDate(value)
        console.log(toDate);

    }
    const formattedToDate = (date: any) => {
        if (!toDate) return ''; // handle undefined or null date
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based in JavaScript
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const today = new Date()
    
    const disableFutureDates = (date:any) => {
        return date > today;
    };

    const totalPages = Math.ceil(state.UserListSet.length / rowsPerPage);
    const paginatedData = state.UserListSet.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );
    const location = useLocation()

    const { rowData } = location.state || {}

    const getData = () => {
        let filteredData = state.UserListSet;

        // Apply search filter if searchQuery exists
        if (searchQuery) {
            filteredData = state.UserListSet.filter((row: any) =>
                Object.values(row).some((value) =>
                    typeof value === "string"
                        ? value.toLowerCase().includes(searchQuery.toLowerCase())
                        : false
                )
            );
        }

        // Apply sorting if sortColumn and sortType are provided
        if (sortColumn && sortType) {
            filteredData = filteredData.slice().sort((a: any, b: any) => {
                let x = a[sortColumn];
                let y = b[sortColumn];

                if (typeof x === "string" && typeof y === "string") {
                    x = x.toLowerCase();
                    y = y.toLowerCase();
                }

                if (sortType === "asc") {
                    return x > y ? 1 : -1;
                } else {
                    return x < y ? 1 : -1;
                }
            });
        }

        console.log("Filtered Data: ", filteredData);

        // Apply pagination
        const start = (page - 1) * limit;
        const end = start + limit;

        return filteredData.slice(start, end);
    };


    const handleSortColumn = (sortColumn: any, sortType: any) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    // const handleCloseModal = () => {
    //     setShowModalAdd(false);
    //     setShowModalEdit(false);
    //     setEditingSiteDetails(null);
    // };

    const handleCloseModal = () => setShowModal(false);
    const handleChange = (e: any) => {
        const { name, value } = e.target;

        // Update state for form fields
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        // Perform validation based on field name
        if (name === "contactnumber") {
            const isValidInput = /^[0-9/-]*$/.test(value);
            if (!isValidInput || value.length < 10) {
                setcontactnumbererror(true);
                console.error("Invalid input. Please enter only characters.");
            } else {
                setcontactnumbererror(false);
            }
        } else if (name === "vendorname") {
            const isValidInput = /^[a-zA-Z ]+$/.test(value);
            if (!isValidInput) {
                setvendornameerror(true);
                console.error("Invalid input. Please enter only characters.");
            } else {
                setvendornameerror(false);
            }
        }
        else if (name === "credit") {
            const isValidInput = /^[a-zA-Z ]+$/.test(value);
            if (!isValidInput) {
                setCrediterror(true);
                console.error("Invalid input. Please enter only Numbers.");
            } else {
                setCrediterror(false);
            }
        }
    };
    useEffect(() => {
        getUserDetails();
    }, []);

    const handlesaveuser = (e: FormEvent) => {
        e.preventDefault();

        console.log(
            "formFields_________",
            formData
        );

        if (
            isEmpty(formData.vendorname) ||
            isEmpty(formData.site) ||
            isEmpty(formData.credit)
            // isEmpty(formData.mobile.length < 10)
        ) {
            console.log("this", formData)
        } else if (
            vendornameerror ||
            contactnameerror ||
            contactnumbererror ||
            gstnumbererror ||
            billingaddresserror
        ) {
            // if (vendornameerror === true) {
            //     showError("Vendor Name Error", "Form not Valid!");
            // } else if (contactnameerror === true) {
            //     showError("Contact Name Error", "Form not Valid!");
            // } else if (contactnumbererror === true) {
            //     showError("Mobile number Error", "Form not Valid!");
            // } else if (gstnumbererror === true) {
            //     showError("Gst Number Error", "Form not Valid!");
            // } else if (billingaddresserror === true) {
            //     showError("Billingaddress Error", "Form not Valid!");
            // }
        } else {
            console.log("jgjhjh", modalTitle);

            if (modalTitle === "Add User") {
                let request = {
                    "vendor_name": formData.vendorname,
                    "contact_no": formData.site,
                    "gst_number": formData.credit,


                };
                callApi("POST", "vendor_create.php", request)
                    .then((res: any) => res.data)
                    .then((response: any) => {
                        if (response.responseCode === 200) {
                            showSuccess("Success!", response.message);

                            setFormData({
                                id: "",
                                vendorname: "",
                                site: "",
                                credit: ""
                            });
                            setShowModal(false);
                            getUserDetails();
                        }
                        else {
                            showError("Failed", "Vendor Creation Failed!");
                        }
                    });
            }
            else {

                editingdetails();
            }
        }
    };
    const getUserDetails = () => {
        const request = {
            "id": rowData.id
        }
        callApi("post", "vendor_statement.php", request)
            .then((res: any) => res.data)
            .then((response: any) => {
                if (response["response_Code"] === 200) {
                    console.log("coming");
                    console.log("response.responseJson", response.responseJson);

                    setState((prevState) => ({
                        ...prevState,
                        UserListSet: response.responseJson,
                    }))

                }
                console.log("UserListSet", state.UserListSet);

            })
    }

    const addUser = () => {
        setModalTitle("Add User");
        setShowModal(true)
    };

    const userEdit = (data: any) => {
        console.log("this is the data", data)
        setFormData({
            id: data.id,
            vendorname: data.name,
            site: data.site,
            credit: data.credit
        });
        console.log("muthu---->111", formData);


        setModalTitle("Edit User");
        setShowModal(true);
    };

    const editingdetails = () => {
        let request = {
            vendor_id: formData.id,
            vendor_name: formData.vendorname,
            site: formData.site,
            credit: formData.credit
        };

        console.log("request", request);
        callApi("post", "vendor_update.php", request)

            .then((res: any) => res.data)
            .then((response: any) => {
                if (response.responseCode === 200) {
                    showSuccess("EDITED!", response.message);
                    getUserDetails();
                    setFormData({
                        id: "",
                        vendorname: "",
                        site: "",
                        credit: ""
                    });
                    setShowModal(false);
                } else {
                    showError("FAILED!", "Editing Failed");
                }
            });
    };

    // const [currentPage, setCurrentPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showModal, setShowModal] = useState(false); // State for showing/hiding modal

    // const pageCount = Math.ceil(state.UserListSet.length / rowsPerPage);
    // const paginatedData = state.UserListSet.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
    // console.log("paginatedData", paginatedData);

    const downloadCSV = () => {
        const data = [
            ["Overall Vendor Statement"],
            ["S.No", "Date", "Site Name", "Credits Added", "PE Value", "PE Number", "Balance Credits"],
            ...state.UserListSet.map((row: any) => [row.s_no, row.name, row.site_name, row.credit_added, row.pe_value, row.pe_number, row.bal_amt])
        ];
    
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
    
        // Add rows to the worksheet
        data.forEach((row) => {
            worksheet.addRow(row);
        });
    
        // Merge the title row across all columns and center the title
        worksheet.mergeCells('A1:G1');
        const titleCell = worksheet.getCell('A1');
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
        titleCell.font = { bold: true, size: 14 };
    
        // Style for the header row (second row)
        const headerRow = worksheet.getRow(2);
        headerRow.eachCell((cell: any) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
            cell.font = { bold: true };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } }; // Yellow background
        });
    
        // Write the workbook to an Excel file
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Vendor_Overallstatement_${formattedDate}.xlsx`;
            link.click();
        });
    };
    

    const handlePageClickprevious = () => {
        setCurrentPage(currentPage - 1);
    };
    const handlePageClicknext = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlepageListChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value === "All" ? state.UserListSet.length : Number(event.target.value);
        setRowsPerPage(value);
        setCurrentPage(0); // Reset to first page when rows per page changes
    };

    const movecredit = async (data: any) => {
        const isComfirm = await showAlert(
            "Please Confirm",
            "Are you sure you want to change the status?",
            "Yes",
            "No"
        );
        if (isComfirm) {
            const req = {
                id: data.id,
            }
            callApi("POSt", "vendor_delete.php", req)
                .then((res: any) => res.data)
                .then((response: any) => {
                    if (response.responseCode === 200) {
                        showSuccess("CHANGE!", response.message);
                        getUserDetails();
                    } else {
                        showError("FAILED!", "CHANGE failed");
                    }
                });
        }
    }
    const closeModal = () => {
        setShowModal(false);
        setFormData({
            id: "",
            vendorname: "",
            site: "",
            credit: ""
        });
        setError(false)
    }

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = paginatedData.filter((row: any) =>
        row.site_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.credit_added.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
        setPage(1);
        setLimit(dataKey);
    };
    const navigate = useNavigate()
    const handlegoBack = () => {
        navigate(-1);
    };
    return (
        <>
            <div style={{ padding: "20px", display: "flex", justifyContent: "space-between" }}>
                {" "}
                <button className="btn excelbtn mr-2" onClick={handlegoBack} style={{ height: "38px" }}>
                    Go Back
                </button>
                {/* <button className='btn excelbtn mr-2' onClick={(e) => handlestatement(rowData)}>statement</ button> */}
            </div>
            <div className="new-vendorlist-card vendorlistcard">
                <Grid fluid>
                    <Row className="new-vendorlist-row">
                        <Col xs={12} md={4} className="new-vendorlist-col">
                            <div className="vendor-fields1">
                                <p className="field-label">Vendor Name :</p>
                                <span className="field-value">{rowData.name}</span>
                            </div>
                            <div className="vendor-fields1">
                                <p className="field-label">Contact Name :</p>
                                <span className="field-value">{rowData.contact_name}</span>
                            </div>
                            <div className="vendor-fields1">
                                <p className="field-label">Contact Details :</p>
                                <span className="field-value">{rowData.contact_no}</span>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="new-vendorlist-col">
                            <div className="vendor-fields">
                                <p className="field-label">GST Number:</p>
                                <span className="field-value">{rowData.gst_number}</span>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="new-vendorlist-col">
                            <div className="vendor-fields">
                                <p className="field-label">Billing Address:</p>
                                <span className="field-value">
                                    {rowData.billing_address}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
            <div className="container-qt">
                {/* <div className="rs-panel-body"> */}
                <div className="rs-table rs-table-hover">
                    <div className="tabletitle" style={{ fontSize: "25px" }}>Vendor Statement</div>
                    <div className="table-header expensetabelheader " style={{ gap: "10px" }}>
                        <div
                            className='grpdatepicker'
                        >
                            {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                            <DatePicker size="lg" placeholder="From Date" disabledDate={disableFutureDates} className='datepicker'
                                // value={fromDate}
                                onChange={handleDateChange}
                            />
                            <DatePicker size="lg" placeholder="To Date"  disabledDate={disableFutureDates} className='datepicker'
                                // value={toDate}
                                onChange={handleDateChangeto}
                            // renderInput={(params) => <TextField {...params} size="large" className='datepicker' />}
                            />
                            {/* </LocalizationProvider> */}
                            <button
                                type="button"
                                className="btn excelbtn"
                                style={{ width: "100px" }}
                                onClick={handleSearch}

                            >
                                <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontSize: "18px" }} />
                            </button>
                        </div>
                        <div className="table-search searchfieldscontain vendor-excel expensesearch">
                            <button
                                type="button"
                                className="btn excelbtn btnmobiledash expencebtn-excel"
                                style={{ marginRight: "10px" }}
                                onClick={downloadCSV}
                            >
                                Excel
                            </button>

                            <div className="search-field1">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="search-input vendorsearch expencebtn-search"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", overflowX: "hidden", overflowY: "hidden" }}>
                    <Table
                        height={420}
                        data={getData()}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        loading={loading}
                        style={{ width: "100%" }} // Adjust min-width as needed
                    >
                        <Column width={50} align='center'>
                            <HeaderCell>S.No</HeaderCell>
                            <Cell dataKey="s_no" />
                        </Column>

                        <Column width={100}>
                            <HeaderCell>Date</HeaderCell>
                            <Cell dataKey="date" >

                            </Cell>
                        </Column>
                        <Column width={200}>
                            <HeaderCell>Site Name</HeaderCell>
                            <Cell dataKey="site_name" >

                            </Cell>
                        </Column>
                        <Column width={200}>
                            <HeaderCell>Credits Added</HeaderCell>
                            <Cell dataKey="credit_added" >

                            </Cell>
                        </Column>
                        <Column width={200}>
                            <HeaderCell>PE Value</HeaderCell>
                            <Cell dataKey="pe_value" >

                            </Cell>
                        </Column>
                        <Column width={200}>
                            <HeaderCell>PE Number</HeaderCell>
                            <Cell dataKey="pe_number" >

                            </Cell>
                        </Column>
                        <Column width={200}>
                            <HeaderCell >Balance Credit</HeaderCell>
                            <Cell dataKey="bal_amt" >

                            </Cell>
                        </Column>
                    </Table>
                </div>
                <div>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        className="pagination"
                        size="xs"
                        layout={["total", "pager", "skip"]}
                        total={state.UserListSet.length}
                        limitOptions={[5, 10, 15]}
                        limit={limit}
                        activePage={page}
                        onChangePage={setPage}
                        onChangeLimit={handleChangeLimit}
                    />
                </div>

            </div>


            {/* 
            </div> */}

            {/* Modal for adding new user */}
            <Modal open={showModal} onClose={handleCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Credit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form style={{ padding: "2%" }} >
                        <div className="form-group">
                            <label htmlFor="name">Vendor Name</label>
                            <input
                                type="text"
                                className={`form-control ${error && isEmpty(formData.vendorname) ? 'is-invalid' : ''} mt-1 mb-2`}
                                id="name"
                                name="name"
                                value={formData.vendorname}
                                onChange={handleChange}
                            />
                            {error && isEmpty(formData.vendorname) && <div className="invalid-feedback">{"Name Error"}</div>}
                            {nameerror && <div className="error-message" style={{ color: "red" }}>Enter valid Name</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile">Select Site</label>
                            <SelectPicker block className={`form-control1 ${error && isEmpty(formData.site) ? 'is-invalid' : ''} mt-1 mb-2`} data={[]} style={{ border: "none", outline: "none", padding: "none" }} />

                            {error && isEmpty(formData.site) && <div className="invalid-feedback">{"choose valid Mobile Number"}</div>}

                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                className={`form-control ${error && isEmpty(formData.credit) ? 'is-invalid' : ''} mt-1 mb-2`}
                                id="email"
                                name="email"
                                value={formData.credit}
                                onChange={handleChange}
                            />
                            {error && isEmpty(formData.site) && <div className="invalid-feedback">{"Credit Error"}</div>}
                            {crediterror && <div className="error-message" style={{ color: "red" }}>Enter valid Credit</div>}
                        </div>
                        <Button className='newuser mt-3' onClick={(e) => handlesaveuser(e)}>Credit</Button>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Overallvendorstatement