import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./quatation.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { showError, showSuccess } from "../../Utils/sweetAlert";
import { callApi } from "../../Utils/Api";

interface Row {
  id: any;
  sno: number;
  description: string;
  length: string;
  h_w: string;
  area: string;
  amount: string;
  rate: string;
}
interface Less {
  id: any;
  sno: number;
  description: string;
  amount: any;
}

interface Table {
  name: string;
  rows: Row[];
  category_id: any;
}
interface LessTable {
  name: string;
  rows: Less[];
  category_id: any;
}

type MaterialIdMap = {
  [key: string]: string;
};
interface Errors {
  [key: string]: string;
}

function Quotationform() {
  const navigate = useNavigate();
  const location = useLocation();

  const { quotid } = useParams<{ quotid: string }>();
  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerDate, setcustomerDate] = useState("");
  const [receivedAmount, setReceivedAmount] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [categoriesid, setCategoriesid] = useState<string[]>([]);
  const [rows, setRows] = useState<{ [category: string]: Row[] }>({});
  const [submitting, setSubmitting] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");

  const [showEditors, setShowEditors] = useState<{
    [category: string]: boolean;
  }>({});
  const [tables, setTables] = useState<Table[]>([]);
  const [lessWork, setLessWork] = useState<LessTable[]>([]);
  const [ckeditorData, setCkeditorData] = useState<{
    [category: string]: string;
  }>({});
  const [uncheckedMaterialIds, setUncheckedMaterialIds] = useState<string[]>(
    []
  );
  const [material_id, setMaterialId] = useState<MaterialIdMap>({});
  const [state, setState] = useState({
    QuoteListSet: { category: [] as any[] },
  });
  const [deletedCategoryItemIds, setDeletedCategoryItemIds] = useState<
    number[]
  >([]);
  const [isValid, setIsValid] = useState(false);
  const [deletedCategoryIds, setDeletedCategoryIds] = useState<number[]>([]);
  const [deletedLessWorkIds, setDeletedLessWorkIds] = useState<number[]>([]);
  const [deletedTableIds, setDeletedTableIds] = useState<number[]>([]);
  const [categoryerror, setcategoryError] = useState("");

  useEffect(() => {
    console.log(
      deletedCategoryItemIds,
      deletedCategoryIds,
      deletedLessWorkIds,
      deletedTableIds
    );
  }, [
    deletedCategoryItemIds,
    deletedCategoryIds,
    deletedLessWorkIds,
    deletedTableIds,
  ]);

  useEffect(() => {
    console.log("quot id", quotid);
    if (!customerName) {
      const cussname = localStorage.getItem("cusname") || "";
      const cusadd = localStorage.getItem("cusadd") || "";
      setCustomerName(cussname);
      setCustomerAddress(cusadd);
    }
    if (quotid != "") {
      getQuotatilDetails();
    }
  }, [customerName]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    if (!customerDate) {
      setcustomerDate(getCurrentDate());
    }
  }, [customerDate]);

  const getQuotatilDetails = () => {
    const req = {
      quo_id: quotid ?? "",
    };
    callApi("post", "quo_detail.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["responseCode"] === 200) {
          const data = response.responseJson;
          setState((prevState) => ({
            ...prevState,
            QuoteListSet: response.responseJson,
          }));

          const categories = data.category.map((cat: any) => cat.categoryName);
          const categoriesid = data.category.map((cat: any) => cat.category_id);
          console.log("dhndsnhfdf", categoriesid);
          const rows = data.category.reduce((acc: any, cat: any) => {
            acc[cat.categoryName] = cat.categoryItems.map(
              (item: any, index: any) => ({
                id: item.item_id,
                sno: index,
                description: item.description,
                length: item.length,
                h_w: item.height,
                area: item.area,
                rate: item.rate,
                amount: item.amount,
              })
            );
            return acc;
          }, {});

          const material = data.category.reduce((acc: any, cat: any) => {
            acc[cat.categoryName] = cat.material;
            return acc;
          }, {});

          const material_id = data.category.reduce((acc: any, cat: any) => {
            acc[cat.categoryName] = cat.material_id;
            return acc;
          }, {});

          const showEditors = data.category.reduce((acc: any, cat: any) => {
            acc[cat.categoryName] = !!cat.material; // Checkbox should be checked if there's material
            return acc;
          }, {});

          const extraWork = data.extraWork.map((extra: any) => ({
            name: extra.categoryName,
            category_id: extra.category_id,
            rows: extra.categoryItems.map((item: any, index: any) => ({
              id: item.item_id,
              sno: index,
              description: item.description,
              length: item.length,
              h_w: item.height,
              area: item.area,
              rate: item.rate,
              amount: item.amount,
            })),
          }));

          const lessWork = Array.isArray(data.lesswork)
            ? data.lesswork.map((less: any) => ({
                name: less.categoryName,
                category_id: less.category_id,
                rows: less.categoryItems.map((item: any, index: any) => ({
                  id: item.item_id,
                  sno: index,
                  description: item.description,
                  amount: item.amount,
                })),
              }))
            : [];
          setCategories(categories);
          setCategoriesid(categoriesid);
          setRows(rows);
          setCustomerName(data.customer_name);
          setCustomerAddress(data.address);
          setTables(extraWork);
          setLessWork(lessWork);
          setCkeditorData(material);
          setShowEditors(showEditors);
          setMaterialId(material_id);
        } else {
          setState((prevState: any) => ({
            ...prevState,
            QuoteListSet: [],
          }));
        }
      });
  };

  const addCategory = () => {
    if (isDuplicateCategory(newCategoryName)) {
      alert("Category name already exists!");
      return;
    }
    if (newCategoryName.trim() === "") {
      alert("Please enter a category name");
      return;
    }

    setCategories((prev) => [...prev, newCategoryName]);
    setRows((prev) => ({
      ...prev,
      [newCategoryName]: [
        {
          sno: 0,
          description: "",
          length: "",
          h_w: "",
          id: "",
          area: "",
          amount: "",
          rate: "",
        },
      ],
    }));
    setShowEditors((prev) => ({ ...prev, [newCategoryName]: false }));
    setNewCategoryName(""); // Clear the input field after adding category
  };

  const isDuplicateCategory = (name: any) => {
    return categories.includes(name);
  };

  const removeCategory = (name: string) => {
    setCategories((prev) => prev.filter((category) => category !== name));
    setRows((prev) => {
      const newRows = { ...prev };
      delete newRows[name];
      return newRows;
    });
    setShowEditors((prev) => {
      const newEditors = { ...prev };
      delete newEditors[name];
      return newEditors;
    });

    // Find the category to delete
    let categoryToDelete: any;
    if (state?.QuoteListSet?.category?.length > 0) {
      const categoryToDelete = (state.QuoteListSet.category as any[]).find(
        (cat: any) => cat.categoryName === name
      );
    }

    // Check if the category exists and has an ID
    if (categoryToDelete && categoryToDelete.category_id) {
      // Push the ID to the deletedCategoryIds state
      setDeletedCategoryIds((prev) => [...prev, categoryToDelete.category_id]);
    }
  };

  const addRow = (category: string) => {
    const currentRows = rows[category];
    const newSno =
      currentRows.length > 0 ? currentRows[currentRows.length - 1].sno + 1 : 1;

    setRows((prev: any) => ({
      ...prev,
      [category]: [
        ...prev[category],
        {
          sno: newSno,
          description: "",
          length: "",
          h_w: "",
          id: "",
          area: "",
          amount: "",
        },
      ],
    }));
  };

  const removeRow = (category: string, index: number, item: any) => {
    setRows((prev) => ({
      ...prev,
      [category]: prev[category].filter((row, rowIndex) => rowIndex !== index),
    }));
    setDeletedCategoryItemIds((prev: any) => [...prev, item.id]);
  };

  const handleInputChange = (
    category: string,
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if (name === "description" && !/^[a-zA-Z0-9\s]*$/.test(value)) {
      return;
    }
    if ((name === "length" && !/^[0-9\s]*$/.test(value)) || value === "0") {
      return;
    }

    if ((name === "h_w" && !/^[0-9\s]*$/.test(value)) || value === "0") {
      return;
    }

    if ((name === "rate" && !/^[0-9\s]*$/.test(value)) || value === "0") {
      return;
    }
    // Calculate area if length and h_w are updated
    let updatedRow = { ...rows[category][index], [name]: value };
    if (name === "length" || name === "h_w") {
      const length = parseFloat(updatedRow.length);
      const h_w = parseFloat(updatedRow.h_w);
      updatedRow = {
        ...updatedRow,
        area: (length * h_w).toString(), // Calculate area and convert to string
      };
    }
    if (name === "area" || name === "rate") {
      updatedRow = {
        ...updatedRow,
        amount: (
          parseFloat(updatedRow.area) * parseFloat(updatedRow.rate)
        ).toString(),
      };
    }

    setRows((prev) => ({
      ...prev,
      [category]: prev[category].map((row, rowIndex) =>
        rowIndex === index ? updatedRow : row
      ),
    }));

    setValidationErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value) {
        delete newErrors[`${category}-${index}-${name}`];
      }
      return newErrors;
    });
  };

  const handleCkEditorChange = (event: any, editor: any, category: string) => {
    const data = editor.getData();
    setCkeditorData((prev) => ({
      ...prev,
      [category]: data,
    }));
  };

  const handleCategoryChange = (index: number, newCategory: string) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const oldCategory = updatedCategories[index];
      updatedCategories[index] = newCategory;

      // Update rows and ckeditorData keys
      const updatedRows = { ...rows };
      if (updatedRows[oldCategory]) {
        updatedRows[newCategory] = updatedRows[oldCategory];
        delete updatedRows[oldCategory];
      }

      const updatedCkeditorData = { ...ckeditorData };
      if (updatedCkeditorData[oldCategory]) {
        updatedCkeditorData[newCategory] = updatedCkeditorData[oldCategory];
        delete updatedCkeditorData[oldCategory];
      }

      setRows(updatedRows);
      setCkeditorData(updatedCkeditorData);

      return updatedCategories;
    });
  };

  const totalForCategory = (categoryItems: any) => {
    return categoryItems.reduce(
      (total: any, item: any) => total + parseFloat(item.amount),
      0
    );
  };

  const [validationErrors, setValidationErrors] = useState<Errors>({});

  const validateForm = (): Errors => {
    let errors: Errors = {};
    categories.forEach((category) => {
      rows[category].forEach((row, rowIndex) => {
        if (!row.description) {
          errors[`${category}-${rowIndex}-description`] =
            "Description is required";
        }
        if (!row.length) {
          errors[`${category}-${rowIndex}-length`] = "Length is required";
        }
        if (!row.length) {
          errors[`${category}-${rowIndex}-h_w`] = "h_w is required";
        }
        if (!row.length) {
          errors[`${category}-${rowIndex}-rate`] = "Rate is required";
        }
        // Add more validation checks as needed
      });
    });

    tables.forEach((table, tableIndex) => {
      table.rows.forEach((row, rowIndex) => {
        if (!row.description) {
          errors[`table-${tableIndex}-${rowIndex}-description`] =
            "Description is required";
        }
        if (!row.length) {
          errors[`table-${tableIndex}-${rowIndex}-length`] =
            "Length is required";
        }
        if (!row.h_w) {
          errors[`table-${tableIndex}-${rowIndex}-h_w`] = "H/W is required";
        }
        if (!row.rate) {
          errors[`table-${tableIndex}-${rowIndex}-rate`] = "Rate is required";
        }
      });
    });

    lessWork.forEach((table, tableIndex) => {
      table.rows.forEach((row, rowIndex) => {
        if (!row.description) {
          errors[`lessWork-${tableIndex}-${rowIndex}-description`] =
            "Description is required";
        }
        if (!row.amount || parseFloat(row.amount) <= 0) {
          errors[`lessWork-${tableIndex}-${rowIndex}-amount`] =
            "Amount is required";
        }
      });
    });

    return errors;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setSubmitting(false);
      return;
    }

    // Reset validation errors
    setValidationErrors({});

    const formattedCategories = categories.map((category, index) => ({
      categoryName: category,
      category_id: categoriesid[index],
      categoryItems: rows[category].map((row: any, index) => ({
        id: row?.id,
        description: row.description,
        length: parseFloat(row.length),
        height: parseFloat(row.h_w),
        area: parseFloat(row.area),
        amount: parseFloat(row.amount),
        rate: row.rate,
      })),
      material: ckeditorData[category] || "",
    }));

    const formattedExtraWork = tables.map((table: any, tableIndex) => ({
      categoryName: table.name,
      category_id: table.category_id,
      categoryItems: table.rows.map((row: any, index: any) => ({
        id: row?.id, // Assuming a sequential id. You can replace this with a dynamic value if needed.
        description: row.description,
        length: parseFloat(row.length),
        height: parseFloat(row.h_w),
        area: parseFloat(row.area),
        amount: parseFloat(row.amount),
        rate: parseFloat(row.rate),
      })),
    }));

    const formattedlessWork = lessWork.map((table: any, tableIndex) => ({
      categoryName: table.name,
      category_id: table.category_id,
      categoryItems: table.rows.map((row: any, index: any) => ({
        id: row?.id, // Assuming a sequential id. You can replace this with a dynamic value if needed.
        description: row.description,
        amount: row.amount,
      })),
    }));

    // const data: any = state.quotationData;
    const lessWorkTotalAmount: any = formattedlessWork.reduce(
      (total: number, category: any) => {
        return (
          total +
          category.categoryItems.reduce((categoryTotal: number, item: any) => {
            return categoryTotal + parseFloat(item.amount);
          }, 0)
        );
      },
      0
    );

    const extraWorkTotalAmount = formattedExtraWork.reduce(
      (total: number, category: any) => {
        return (
          total +
          category.categoryItems.reduce((categoryTotal: number, item: any) => {
            return categoryTotal + parseFloat(item.amount);
          }, 0)
        );
      },
      0
    );

    const overallTotal = formattedCategories.reduce((total, section) => {
      return total + totalForCategory(section.categoryItems);
    }, 0);

    const lessWorkAmount = parseFloat(lessWorkTotalAmount ?? "0");
    const grandTotal = overallTotal - lessWorkAmount;
    const allgrandTotal: any = grandTotal + extraWorkTotalAmount ?? 0;

    const site_id = localStorage.getItem("siteid");

    const data = {
      site_id,
      customer_name: customerName,
      address: customerAddress,
      date: customerDate,
      // quo_amt: receivedAmount, // Assuming quo_amt is the same as receivedAmount. You can change this if needed.
      received_amt: parseFloat(receivedAmount),
      category: formattedCategories,
      extraWork: formattedExtraWork,
      lesswork: formattedlessWork,
      quo_id: quotid ?? "",
      deleted_category_id: deletedCategoryIds,
      deleted_item_id: [
        ...deletedCategoryItemIds,
        ...deletedTableIds,
        ...deletedLessWorkIds,
      ],
      deletedLessWorkIds: deletedLessWorkIds,
      deletedTableIds: deletedTableIds,
      deleted_material_id: uncheckedMaterialIds,
      quo_amt: allgrandTotal,
    };
    console.log("allgrandTotal---->", allgrandTotal);

    console.log("data---->", data);

    if (formattedCategories.length > 0 && customerName && customerAddress) {
      try {
        const response = await fetch(
          "https://dnd.zerame.com/api/quo_create.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        const result = await response.json();
        console.log(result);
        if (result.success === true) {
          setSubmitting(false);
          showSuccess("Success!", result.message);
          localStorage.setItem("qu_id", result.quo_id);
          const quotationid = result.quo_id;
          setTimeout(() => {
            navigate(`/layout/quotationpdf/${quotationid}`);
          }, 2000);
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    } else {
      showError("Failed", "Customer Name or Address or category are empty!");
      setSubmitting(false);
    }
  };
  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (/^[a-zA-Z0-9\s]*$/.test(value)) {
      setNewCategoryName(value);
    } else {
      e.currentTarget.value = newCategoryName;
    }
  };

  const handleCheckboxChange = (category: string, materialId: string) => {
    setShowEditors((prevShowEditors) => {
      const updatedShowEditors = { ...prevShowEditors };
      updatedShowEditors[category] = !prevShowEditors[category];

      // If checkbox is unchecked, push material ID into the array
      if (!updatedShowEditors[category]) {
        setUncheckedMaterialIds((prevIds) => [...prevIds, materialId]);
        setCkeditorData((prevData) => ({
          ...prevData,
          [category]: "", // Clear CKEditor data for this category
        }));
      } else {
        setUncheckedMaterialIds((prevIds) =>
          prevIds.filter((id) => id !== materialId)
        );
      }

      return updatedShowEditors;
    });
  };

  const addTable = () => {
    const newTable: Table = {
      // name: `Table ${tables.length + 1}`,
      name: `Extra Work`,
      rows: [
        {
          sno: 0,
          id: "",
          description: "",
          length: "",
          h_w: "",
          area: "",
          amount: "",
          rate: "",
        },
      ],
      category_id: "",
    };
    setTables((prev) => [...prev, newTable]);
  };

  const addlessWork = () => {
    const newTable: LessTable = {
      // name: `Table ${tables.length + 1}`,
      name: `Less Work`,
      rows: [
        {
          sno: 0,
          description: "",
          amount: "",
          id: "",
        },
      ],
      category_id: "",
    };
    setLessWork((prev) => [...prev, newTable]);
  };

  const addLessWork = (tableIndex: number) => {
    const newRows = [
      ...lessWork[tableIndex].rows,
      {
        sno: lessWork[tableIndex].rows.length,
        description: "",
        amount: "",
        id: "",
      },
    ];
    const updatedlessWork = lessWork.map((table, index) =>
      index === tableIndex ? { ...table, rows: newRows } : table
    );
    setLessWork(updatedlessWork);
  };
  const addTableRow = (tableIndex: number) => {
    const newRows = [
      ...tables[tableIndex].rows,
      {
        sno: tables[tableIndex].rows.length,
        description: "",
        length: "",
        h_w: "",
        id: "",
        area: "",
        amount: "",
        rate: "",
      },
    ];
    const updatedTables = tables.map((table, index) =>
      index === tableIndex ? { ...table, rows: newRows } : table
    );
    setTables(updatedTables);
  };

  const removeExtraTableRow = (tableIndex: number) => {
    const categoryId = tables[tableIndex]?.category_id;
    setTables((prevTables) =>
      prevTables.filter((_, idx) => idx !== tableIndex)
    );
    setDeletedCategoryIds((prev) => [...prev, categoryId]);
  };

  const removelessWorkTable = (tableIndex: number) => {
    const categoryId = lessWork[tableIndex]?.category_id;
    setLessWork((prevTables) =>
      prevTables.filter((_, idx) => idx !== tableIndex)
    );
    setDeletedCategoryIds((prev) => [...prev, categoryId]);
  };

  const handleTableInputChange = (
    tableIndex: number,
    rowIndex: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const updatedRows = tables[tableIndex].rows.map((row, index) =>
      index === rowIndex ? { ...row, [name]: value } : row
    );
    if (name === "description" && !/^[a-zA-Z0-9\s]*$/.test(value)) {
      return;
    }
    if ((name === "length" && !/^[0-9\s]*$/.test(value)) || value === "0") {
      return;
    }

    if ((name === "h_w" && !/^[0-9\s]*$/.test(value)) || value === "0") {
      return;
    }

    if ((name === "rate" && !/^[0-9\s]*$/.test(value)) || value === "0") {
      return;
    }
    // Calculate area if both length and h_w values are present
    if (name === "length" || name === "h_w") {
      const length = parseFloat(updatedRows[rowIndex].length);
      const h_w = parseFloat(updatedRows[rowIndex].h_w);
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        area: (length * h_w).toString(), // Calculate area and convert to string
      };
    }
    if (name === "area" || name === "rate") {
      const area = parseFloat(updatedRows[rowIndex].area);
      const rate = parseFloat(updatedRows[rowIndex].rate);
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        amount: (area * rate).toString(), // Calculate total and convert to string
      };
    }

    const updatedTables = tables.map((table, index) =>
      index === tableIndex ? { ...table, rows: updatedRows } : table
    );

    setTables(updatedTables);

    setValidationErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value) {
        delete newErrors[`table-${tableIndex}-${rowIndex}-${name}`];
      } else {
        newErrors[
          `table-${tableIndex}-${rowIndex}-${name}`
        ] = `${name} is required`;
      }
      return newErrors;
    });
  };

  const handlelessWorkInputChange = (
    tableIndex: number,
    rowIndex: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const updatedRows = lessWork[tableIndex].rows.map((row, index) =>
      index === rowIndex ? { ...row, [name]: value } : row
    );

    let errors = { ...validationErrors };

    // Validation
    if (name === "description" && !value.trim()) {
      errors[`lessWork-${tableIndex}-${rowIndex}-description`] =
        "Description is required";
    } else {
      delete errors[`lessWork-${tableIndex}-${rowIndex}-description`];
    }

    if (
      name === "amount" &&
      (!/^[0-9]*\.?[0-9]+$/.test(value) || parseFloat(value) <= 0)
    ) {
      errors[`lessWork-${tableIndex}-${rowIndex}-amount`] =
        "Amount must be a positive number";
    } else {
      delete errors[`lessWork-${tableIndex}-${rowIndex}-amount`];
    }

    const updatedlessWork = lessWork.map((table, index) =>
      index === tableIndex ? { ...table, rows: updatedRows } : table
    );

    setLessWork(updatedlessWork);
    setValidationErrors(errors);
  };

  const removeTableRow = (tableIndex: number, rowIndex: number) => {
    const deletedId = tables[tableIndex].rows[rowIndex].id;
    setDeletedTableIds((prev: any) => [...prev, deletedId]);

    const updatedRows = tables[tableIndex].rows.filter(
      (_, index) => index !== rowIndex
    );
    const updatedTables = tables.map((table, index) =>
      index === tableIndex ? { ...table, rows: updatedRows } : table
    );
    console.log("kkaakk", updatedTables);
    setTables(updatedTables);
  };

  const removelessWorkRow = (tableIndex: number, rowIndex: number) => {
    const deletedId = lessWork[tableIndex].rows[rowIndex].id;
    setDeletedLessWorkIds((prev: any) => [...prev, deletedId]);
    const updatedRows = lessWork[tableIndex].rows.filter(
      (_, index) => index !== rowIndex
    );
    const updatedlessWork = lessWork.map((table, index) =>
      index === tableIndex ? { ...table, rows: updatedRows } : table
    );
    setLessWork(updatedlessWork);
  };
  const handlegoBack = () => {
    navigate(-1);
  };
  return (
    <div className="container-qt">
      <button className="btn excelbtn mr-2" onClick={handlegoBack}>
        Go Back
      </button>
      <h1 className="title" style={{ fontSize: "25px" }}>
        Quotation Form
      </h1>

      <div className="customer-details">
        <h4 style={{ textAlign: "center", marginBottom: "10px" }}>
          Customer Details
        </h4>
        <div className="row">
          <div className="col-md-4">
            <label>
              Customer Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              placeholder="Enter Customer Name..."
              required
            />
          </div>

          <div className="col-md-4">
            <label>
              Date<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="Date"
              value={customerDate}
              onChange={(e) => setcustomerDate(e.target.value)}
              placeholder="Enter Customer Address..."
              required
            />
          </div>
          <div className="col-md-4">
            <label>
              Customer Address<span style={{ color: "red" }}>*</span>
            </label>
            <div className="custom-ckeditor">
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: [],
                }}
                data={customerAddress}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setCustomerAddress(data);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {categories.length > 0 &&
        categories.map((category, index) => (
          <div key={index} className="category-section">
            <h3 className="category-title">
              <input
                className="table-input-style"
                style={{
                  color: "black",
                  width: "25%",
                  fontSize: "18px",
                  height: "25px",
                }}
                type="text"
                name="category"
                value={category}
                onChange={(e) => handleCategoryChange(index, e.target.value)}
              />
              {/* <button
                className=""
                onClick={() => removeCategory(category)}
              >
                -
              </button> */}
              <button
                onClick={() => removeCategory(category)}
                className="btn cat_remove"
              >
                -
              </button>
            </h3>

            <div className="table-container">
              <form onSubmit={handleSubmit}>
                {/* //cat table// */}
                <table className="custom-table-ban">
                  <thead>
                    <tr>
                      <th>SNo</th>
                      <th>Description</th>
                      <th>Length</th>
                      <th>H/W</th>
                      <th>Area</th>
                      <th>Rate</th>
                      <th>Total Price</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows[category].map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td>
                          <p>{rowIndex + 1}</p>
                        </td>
                        <td>
                          <input
                            className="table-input-style textcenter"
                            type="text"
                            name="description"
                            maxLength={50}
                            minLength={1}
                            value={row.description}
                            onChange={(event) =>
                              handleInputChange(category, rowIndex, event)
                            }
                            required
                          />
                          {validationErrors[
                            `${category}-${rowIndex}-description`
                          ] && (
                            <span className="error">
                              {
                                validationErrors[
                                  `${category}-${rowIndex}-description`
                                ]
                              }
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            className="table-input-style textcenter"
                            type="text"
                            name="length"
                            value={row.length}
                            onChange={(event) =>
                              handleInputChange(category, rowIndex, event)
                            }
                            maxLength={10}
                            minLength={1}
                            required
                          />
                          {validationErrors[
                            `${category}-${rowIndex}-length`
                          ] && (
                            <span className="error">
                              {
                                validationErrors[
                                  `${category}-${rowIndex}-length`
                                ]
                              }
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            className="table-input-style textcenter"
                            type="text"
                            name="h_w"
                            value={row.h_w}
                            onChange={(event) =>
                              handleInputChange(category, rowIndex, event)
                            }
                            maxLength={10}
                            minLength={1}
                            required
                          />
                          {validationErrors[`${category}-${rowIndex}-h_w`] && (
                            <span className="error">
                              {validationErrors[`${category}-${rowIndex}-h_w`]}
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="table-input-style textcenter"
                            name="area"
                            disabled
                            value={row.area}
                            onChange={(event) =>
                              handleInputChange(category, rowIndex, event)
                            }
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="table-input-style textcenter"
                            name="rate"
                            value={row.rate}
                            onChange={(event) =>
                              handleInputChange(category, rowIndex, event)
                            }
                            maxLength={10}
                            minLength={1}
                            required
                          />
                          {validationErrors[`${category}-${rowIndex}-rate`] && (
                            <span className="error">
                              {validationErrors[`${category}-${rowIndex}-rate`]}
                            </span>
                          )}
                        </td>
                        <td>
                          <input
                            className="table-input-style textcenter"
                            type="number"
                            disabled
                            name="amount"
                            value={row.amount}
                            onChange={(event) =>
                              handleInputChange(category, rowIndex, event)
                            }
                          />
                        </td>
                        <td style={{ display: "flex", flexDirection: "row" }}>
                          <button
                            className="remove-row-btn"
                            style={{ width: "30px" }}
                            disabled={rows[category].length <= 1}
                            onClick={() => removeRow(category, rowIndex, row)}
                          >
                            <span style={{ fontWeight: "900" }}>-</span>
                          </button>
                          {rowIndex === rows[category].length - 1 && (
                            <button
                              className="add-row-btn"
                              onClick={() => addRow(category)}
                            >
                              <span style={{ fontWeight: "900" }}>+</span>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>
            </div>
            <div className="checkbox-editor">
              <label>
                <input
                  type="checkbox"
                  checked={showEditors[category] || false}
                  onChange={() =>
                    handleCheckboxChange(category, material_id[category])
                  }
                />
                <span style={{ marginLeft: "5px" }}>Materials Used</span>
              </label>
              {showEditors[category] && (
                <CKEditor
                  editor={ClassicEditor}
                  data={ckeditorData[category] || ""}
                  onChange={(event, editor) =>
                    handleCkEditorChange(event, editor, category)
                  }
                />
              )}
            </div>
          </div>
        ))}

      {tables.map((table: any, tableIndex) => (
        <div key={tableIndex} className="table-section">
          <div className="quo-d-flex">
            <h3>{table.name}</h3>
            <button
              className="remove-row-btn"
              onClick={() => removeExtraTableRow(tableIndex)}
            >
              <span>Remove Extra Work</span>
            </button>
          </div>
          <div className="table-container">
            {/* //extra work table ./ */}
            <table className="custom-table-ban">
              <thead>
                <tr>
                  <th>SNo</th>
                  <th>Description</th>
                  <th>Length</th>
                  <th>H/W</th>
                  <th>Area</th>
                  <th>Rate</th>
                  <th>Total Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {table.rows.map((row: any, rowIndex: any) => (
                  <tr key={rowIndex}>
                    <td>
                      {/* <input
                        type="number"
                        name="sno"
                        value={row.sno}
                        onChange={(event) =>
                          handleTableInputChange(tableIndex, rowIndex, event)
                        }
                      /> */}
                      <p>{rowIndex + 1}</p>
                    </td>
                    <td>
                      <input
                        className="table-input-style textcenter"
                        type="text"
                        name="description"
                        value={row.description}
                        minLength={1}
                        maxLength={100}
                        onChange={(event) =>
                          handleTableInputChange(tableIndex, rowIndex, event)
                        }
                        required
                      />
                      {validationErrors[
                        `table-${tableIndex}-${rowIndex}-description`
                      ] && (
                        <p className="error-message">
                          {
                            validationErrors[
                              `table-${tableIndex}-${rowIndex}-description`
                            ]
                          }
                        </p>
                      )}
                    </td>
                    <td>
                      <input
                        className="table-input-style textcenter"
                        type="number"
                        name="length"
                        value={row.length}
                        minLength={1}
                        maxLength={10}
                        onChange={(event) =>
                          handleTableInputChange(tableIndex, rowIndex, event)
                        }
                        required
                      />
                      {validationErrors[
                        `table-${tableIndex}-${rowIndex}-length`
                      ] && (
                        <p className="error-message">
                          {
                            validationErrors[
                              `table-${tableIndex}-${rowIndex}-length`
                            ]
                          }
                        </p>
                      )}
                    </td>
                    <td>
                      <input
                        className="table-input-style textcenter"
                        type="number"
                        name="h_w"
                        minLength={1}
                        maxLength={10}
                        value={row.h_w}
                        onChange={(event) =>
                          handleTableInputChange(tableIndex, rowIndex, event)
                        }
                        required
                      />
                      {validationErrors[
                        `table-${tableIndex}-${rowIndex}-h_w`
                      ] && (
                        <p className="error-message">
                          {
                            validationErrors[
                              `table-${tableIndex}-${rowIndex}-h_w`
                            ]
                          }
                        </p>
                      )}
                    </td>
                    <td>
                      <input
                        className="table-input-style textcenter"
                        type="number"
                        name="area"
                        disabled
                        value={row.area}
                        onChange={(event) =>
                          handleTableInputChange(tableIndex, rowIndex, event)
                        }
                        required
                      />
                    </td>
                    <td>
                      <input
                        className="table-input-style textcenter"
                        type="number"
                        name="rate"
                        minLength={1}
                        maxLength={10}
                        value={row.rate}
                        onChange={(event) =>
                          handleTableInputChange(tableIndex, rowIndex, event)
                        }
                        required
                      />
                      {validationErrors[
                        `table-${tableIndex}-${rowIndex}-rate`
                      ] && (
                        <p className="error-message">
                          {
                            validationErrors[
                              `table-${tableIndex}-${rowIndex}-rate`
                            ]
                          }
                        </p>
                      )}
                    </td>
                    <td>
                      <input
                        className="table-input-style textcenter"
                        type="number"
                        name="amount"
                        value={row.amount}
                        onChange={(event) =>
                          handleTableInputChange(tableIndex, rowIndex, event)
                        }
                        required
                      />
                    </td>
                    <td>
                      <button
                        className="remove-row-btn"
                        style={{ width: "30px" }}
                        onClick={() => removeTableRow(tableIndex, rowIndex)}
                        disabled={table.rows.length <= 1}
                      >
                        <span style={{ fontWeight: "900" }}>-</span>
                      </button>
                      {rowIndex === table.rows.length - 1 && (
                        <button
                          className="add-row-btn"
                          onClick={() => addTableRow(tableIndex)}
                        >
                          <span style={{ fontWeight: "900" }}>+</span>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}

      {lessWork.map((table: any, tableIndex: any) => (
        <div
          key={tableIndex}
          className="table-section"
          style={{ marginTop: "20px" }}
        >
          <div className="quo-d-flex">
            <h3>{table.name}</h3>
            <button
              className="remove-row-btn"
              onClick={() => removelessWorkTable(tableIndex)}
            >
              <span>Remove Less Work</span>
            </button>
          </div>
          <div className="table-container">
            <table className="custom-table-ban">
              <thead>
                <tr>
                  <th>SNo</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {table.rows.map((row: any, rowIndex: any) => (
                  <tr key={rowIndex}>
                    <td>
                      <p>{rowIndex + 1}</p>
                    </td>
                    <td>
                      <input
                        className="table-input-style textcenter"
                        type="text"
                        name="description"
                        minLength={1}
                        maxLength={100}
                        value={row.description}
                        onChange={(event) =>
                          handlelessWorkInputChange(tableIndex, rowIndex, event)
                        }
                        required
                      />
                      {validationErrors[
                        `lessWork-${tableIndex}-${rowIndex}-description`
                      ] && (
                        <p className="error-message">
                          {
                            validationErrors[
                              `lessWork-${tableIndex}-${rowIndex}-description`
                            ]
                          }
                        </p>
                      )}
                    </td>
                    <td>
                      <input
                        className="table-input-style textcenter"
                        type="text"
                        name="amount"
                        minLength={1}
                        maxLength={10}
                        value={row.amount}
                        onChange={(event) =>
                          handlelessWorkInputChange(tableIndex, rowIndex, event)
                        }
                        required
                      />
                      {validationErrors[
                        `lessWork-${tableIndex}-${rowIndex}-amount`
                      ] && (
                        <p className="error-message">
                          {
                            validationErrors[
                              `lessWork-${tableIndex}-${rowIndex}-amount`
                            ]
                          }
                        </p>
                      )}
                    </td>
                    <td>
                      <button
                        className="remove-row-btn"
                        style={{ width: "30px" }}
                        disabled={table.rows.length <= 1}
                        onClick={() => removelessWorkRow(tableIndex, rowIndex)}
                      >
                        <span style={{ fontWeight: "900" }}>-</span>
                      </button>
                      {rowIndex === table.rows.length - 1 && (
                        <button
                          className="add-row-btn"
                          onClick={() => addLessWork(tableIndex)}
                        >
                          <span style={{ fontWeight: "900" }}>+</span>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}

      <div className="add-category-section">
        <div className="left-section">
          <input
            className="input-style"
            type="text"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            placeholder="Enter category name"
            onInput={handleInput}
            required
            minLength={1}
            maxLength={40}
          />
          <button className="add-category-btn" onClick={addCategory}>
            Add Category
          </button>
        </div>
        <div className="right-section">
          {lessWork.length == 0 ? (
            <>
              <button
                className={`add-category-btn`}
                onClick={addlessWork}
                disabled={lessWork.length > 0}
              >
                Less Work
              </button>
            </>
          ) : (
            ""
          )}
          {tables.length == 0 ? (
            <>
              <button
                className={`add-category-btn`}
                onClick={addTable}
                disabled={tables.length > 0}
              >
                Extra Work
              </button>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <button type="submit" className="submit-btn" disabled={submitting}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default Quotationform;
