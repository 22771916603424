import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./component/layout/Sidebar";
import Header from "./component/layout/Header";
import "./App.css";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [headerTitle, setHeaderTitle] = useState("Dashboard");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleMenu = (data: any) => {
    console.log("---------", data);
    setHeaderTitle(data);
    if (window.innerWidth < 768) {
      console.log("--------> Window width changed to", window.innerWidth);
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }
  };

  useEffect(() => {
    // Log the initial window width
    console.log("Initial window width:", window.innerWidth);

    // Function to handle window resize
    const handleResize = () => {
      console.log("Window width changed to", window.innerWidth);
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Log changes to sidebar open state
    console.log("Sidebar open state changed:", isSidebarOpen, "Mobile view:", isMobile);
  }, [isSidebarOpen]);

  return (
    <div className="layout">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} handleMenu={handleMenu} />
      <div className="main-content">
        <Header toggleSidebar={toggleSidebar} headerTitle={headerTitle} />
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
