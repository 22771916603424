import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";
import Layout from "./Layout";
// import Login from "./component/login/login1d";
import Login from "./component/loginpage/Login";
import Dashboard from "./component/page/Dashboard";
import Admindashboard from "./component/page/Admindashboard";
import CreateForm from "./component/page/CreateForm";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "rsuite/dist/rsuite.min.css";
import Leads from "./component/page/Leads";
import AddLead from "./component/page/AddLead";
import Quatationform from "./component/Quatation/quatationform";
import Usermanagement from "./component/layout/usermanagement/Usermanagement";
import Quotationpdf from "./component/Quatation/quotationpdf";
import Listofvendor from "./component/layout/vendor/Listofvendor";
import Sitelist from "./component/page/site/Sitelist";
import Vendorsitelist from "./component/layout/vendor/Vendorsitelist";
import InvoicePage from "./component/Quatation/invoice";
import PurchaseorderPage from "./component/Quatation/purchaseorder";
import PurchaseEntryPage from "./component/Quatation/purchaseentry";
import Overallvendorstatement from "./component/layout/vendor/Overallvendorstatement";
import Product from "./component/layout/product/Product";
import { Rsuite } from "./component/page/Rsuite";
import Nameofproduct from "./component/layout/product/Nameofproduct";
import Expence from "./component/layout/expence/Expence";
import SiteDetails from "./component/page/site/SiteDetails";
import PoFormPage from "./component/Quatation/poform";
import PurchaseEntryform from "./component/Quatation/purchaseentryform";
import Returnslip from "./component/Quatation/returnShip";
import InvoiceTable from "./component/Quatation/invoiceTable";
import Invoiceform from "./component/Quatation/invoiceForm";
import PoLabourPage from "./component/Quatation/polabour";
import Statement from "./component/page/Statement";
import Completedsite from "./component/page/site/Completedsite";
import { Paymentsrecords } from "./component/page/site/Paymentsrecords";
import PurchaseEntryform1 from "./component/Quatation/purchaseentryform1";
import Returnslipupdate from "./component/Quatation/returnslipupdate";
import Invoicepayment from "./component/Quatation/invoicepayment";
import Poformcreate from "./component/Quatation/poformcreate";
import Returnslippdf from "./component/Quatation/Returnslippdf";
import IdleTimerComponent from "./Idealtimer";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
      <IdleTimerComponent />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/layout/*"
            element={<PrivateRoute element={<Layout />} />}
          >
            <Route index path="dashboard" element={<Dashboard />} />
            <Route index path="listofvendor" element={<Listofvendor />} />
            <Route index path="createform" element={<CreateForm />} />
            <Route index path="leads/:quotid" element={<Quatationform />} />
            <Route index path="add-lead" element={<AddLead />} />
            <Route index path="sitelist" element={<Sitelist />} />
            <Route index path="vendorsitelist" element={<Vendorsitelist />} />
            <Route
              index
              path="overallvendorstatement"
              element={<Overallvendorstatement />}
            />
            <Route path="product" element={<Product />} />
            <Route path="nameofproduct" element={<Nameofproduct />} />
            <Route index path="expence" element={<Expence />} />
            <Route index path="usermanagement" element={<Usermanagement />} />
            <Route
              index
              path="quotationpdf/:quoId"
              element={<Quotationpdf />}
            />
            <Route index path="invoice/:inv_id" element={<InvoicePage />} />
            <Route
              index
              path="purchaseorder/:porder_id"
              element={<PurchaseorderPage />}
            />
            <Route
              index
              path="purchaseentry/:pe_id"
              element={<PurchaseEntryPage />}
            />
            <Route index path="rsuite" element={<Rsuite />} />
            <Route index path="poform/:poid" element={<PoFormPage />} />
            <Route index path="poformcreate/:poid" element={<Poformcreate />} />

            <Route
              index
              path="purchaseentryform/:peid"
              element={<PurchaseEntryform />}
            />
            <Route index path="returnslip/:rid" element={<Returnslip />} />
            <Route index path="invoiceTable/:inid" element={<InvoiceTable />} />
            <Route index path="invoiceform/:inid" element={<Invoiceform />} />
            <Route index path="sitedetails/:siteId" element={<SiteDetails />} />
            <Route index path="polabour/:siteId" element={<PoLabourPage />} />
            <Route index path="statement" element={<Statement />} />
            <Route index path="completedsite" element={<Completedsite />} />
            <Route index path="paymentsrecords" element={<Paymentsrecords />} />
            <Route
              index
              path="invoicepayment/:inv_id"
              element={<Invoicepayment />}
            />
            <Route
              index
              path="purchaseentryform1/:peid"
              element={<PurchaseEntryform1 />}
            />
            <Route
              index
              path="returnslipupdate/:rid"
              element={<Returnslipupdate />}
            />
            <Route index path="returnslippdf/:rtId" element={<Returnslippdf />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
