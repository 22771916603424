import React, { FormEvent, useState, ChangeEvent, useEffect } from 'react';
import Papa from "papaparse";
// import { Table, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft, faArrowRight, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./usermanagement.css";
import { showAlert, showError, showSuccess } from '../../../Utils/sweetAlert';
import { callApi } from '../../../Utils/Api';
import { isEmpty } from 'validator';
import { title } from 'process';
import { log } from 'console';
// import { Pagination } from 'rsuite';
import Column from 'rsuite/esm/Table/TableColumn';

import { Table, Pagination, Modal, Button } from "rsuite";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
    faEdit,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "../../page/site/Sitelist.css";
import ExcelJS from 'exceljs';


// Define FormErrors interface
// interface FormErrors {
//     name: string;
//     mobile?: number;
//     email?: string;
//     password?: string;
//     confirm_password?: string;
// }

const Usermanagement = () => {
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        mobile: '',
        email: '',
        password: '',
        confirm_password: ''
    });

    const [error, setError] = useState(false);
    const [mobilenoerror, setMobilenoerror] = useState(false);
    const [emailerror, setEmailerror] = useState(false);
    const [nameerror, setNameerror] = useState(false);
    const [passworderror, setpassworderror] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [confirm_passworderror, setconfirm_passworderror] = useState(false)
    const [modalTitle, setModalTitle] = useState("");
    const [state, setState] = useState({
        UserListSet: [],
    });
    // const [searchQuery, setSearchQuery] = useState('');

    const [dataPeriod, setDataPeriod] = useState<"yearly" | "monthly">("monthly");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showModal, setShowModal] = useState(false);
    const [siteDetails, setSiteDetails] = useState({
        site_name: "",
        customer_name: "",
        contact: "",
        billing_address: "",
        shipping_address: "",
    });
    const [searchQuery, setSearchQuery] = useState("");
    const { Column, HeaderCell, Cell } = Table;
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const totalPages = Math.ceil(state.UserListSet.length / rowsPerPage);
    const paginatedData = state.UserListSet.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const getData = () => {
        let filteredData = state.UserListSet;

        // Apply search filter if searchQuery exists
        if (searchQuery) {
            filteredData = state.UserListSet.filter((row: any) =>
                Object.values(row).some((value) =>
                    typeof value === "string"
                        ? value.toLowerCase().includes(searchQuery.toLowerCase())
                        : false
                )
            );
        }

        // Apply sorting if sortColumn and sortType are provided
        if (sortColumn && sortType) {
            filteredData = filteredData.slice().sort((a: any, b: any) => {
                let x = a[sortColumn];
                let y = b[sortColumn];

                if (typeof x === "string" && typeof y === "string") {
                    x = x.toLowerCase();
                    y = y.toLowerCase();
                }

                if (sortType === "asc") {
                    return x > y ? 1 : -1;
                } else {
                    return x < y ? 1 : -1;
                }
            });
        }

        console.log("Filtered Data: ", filteredData);

        // Apply pagination
        const start = (page - 1) * limit;
        const end = start + limit;

        return filteredData.slice(start, end);
    };

    const handleSortColumn = (sortColumn: any, sortType: any) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
        setPage(1);
        setLimit(dataKey);
    };

    const data = state.UserListSet.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
    });

    const handlePageClickPrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClickNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageListChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value =
            event.target.value === "All"
                ? state.UserListSet.length
                : Number(event.target.value);
        setRowsPerPage(value);
        setCurrentPage(1); // Reset to first page when rows per page changes
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        setFormData({
            id: "",
            name: "",
            email: "",
            mobile: "",
            password: "",
            confirm_password: ""
        });
        setError(false)
        setMobilenoerror(false);
        setEmailerror(false);
        setNameerror(false);
        setpassworderror(false);
        setPasswordMatch(false);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = paginatedData.filter((row: any) =>
        row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.mobile_no.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, id: number) => {
        const updatedData = state.UserListSet.map((item: any) =>
            item.id === id
                ? { ...item, vendor_status: e.target.checked ? "1" : "0" }
                : item
        );
        // Assuming you want to update the state or perform some other logic
        console.log(updatedData);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Update state for form fields
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        // Perform validation based on field name
        if (name === "mobile") {
            const isValidInput = /^[0-9/-]*$/.test(value);
            if (!isValidInput || value.length < 10) {
                setMobilenoerror(true);
                console.error("Invalid input. Please enter only numeric characters, slash, or hyphen.");
            } else {
                setMobilenoerror(false);
            }
        } else if (name === "email") {
            const isValidInput = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value);
            const endsWithValidDomain = value.endsWith(".com") || value.toLowerCase().endsWith(".in");
            if (!isValidInput || !endsWithValidDomain) {
                setEmailerror(true);
            } else {
                setEmailerror(false);
            }
        } else if (name === "name") {
            const isValidInput = /^[a-zA-Z ]+$/.test(value);
            if (!isValidInput) {
                setNameerror(true);
                console.error("Invalid input. Please enter only characters.");
            } else {
                setNameerror(false);
            }
        } else if (name === "password") {
            const isValidInput = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(value);
            if (!isValidInput) {
                setpassworderror(true);
                console.error("Invalid input. Please enter a valid password.");
            } else {
                setpassworderror(false);
            }
            if (formData.confirm_password !== value) {
                setPasswordMatch(true);
            } else {
                setPasswordMatch(false);
            }
        } else if (name === "confirm_password") {
            const isValidInput = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(value);
            if (!isValidInput) {
                setpassworderror(true);
                console.error("Invalid input. Please enter a valid password.");
            } else {
                setpassworderror(false);
            }
            if (formData.password !== value) {
                setPasswordMatch(true);
            } else {
                setPasswordMatch(false);
            }
        }
    };
    useEffect(() => {
        getUserDetails();
    }, []);

    const handlesaveuser = (e: FormEvent) => {
        e.preventDefault();

        console.log(
            "formFields_________",
            formData
        );

        if (
            isEmpty(formData.name) ||
            isEmpty(formData.email) ||
            isEmpty(formData.mobile) ||
            isEmpty(formData.password) ||
            isEmpty(formData.confirm_password)
            // isEmpty(formData.mobile.length < 10)
        ) {
            setError(true)
        } else if (
            nameerror ||
            emailerror ||
            mobilenoerror ||
            passworderror ||
            confirm_passworderror ||
            passwordMatch
        ) {
            // if (nameerror === true) {
            //     showError("Name Error", "Form not Valid!");
            // } else if (emailerror === true) {
            //     showError("Email Error", "Form not Valid!");
            // } else if (mobilenoerror === true) {
            //     showError("Mobile number Error", "Form not Valid!");
            // } else if (passworderror === true) {
            //     showError("Password Error", "Form not Valid!");
            // } else if (confirm_passworderror === true) {
            //     showError("Conformpassword Error", "Form not Valid!");
            // } else {
            //     showError("Password does not match Error", "Form not Valid!");
            // }
        } else {
            console.log("jgjhjh", modalTitle);

            if (modalTitle === "Add User") {
                let request = {
                    "name": formData.name,
                    "email": formData.email,
                    "password": formData.password,
                    "mobile_no": formData.mobile,
                    "user_type": 2

                };
                callApi("POST", "user_create.php", request)
                    .then((res: any) => res.data)
                    .then((response: any) => {
                        if (response.responseCode === 200) {
                            showSuccess("Success!", response.message);
                            setFormData({
                                id: "",
                                name: "",
                                email: "",
                                mobile: "",
                                password: "",
                                confirm_password: ""
                            });
                            setShowModal(false);
                            getUserDetails();
                        }
                        else {
                            showError("Failed", response.message);
                        }
                    });
            }
            else {

                editingdetails();
            }
        }
    };
    const getUserDetails = () => {
        callApi("post", "user_list.php", "")
            .then((res: any) => res.data)
            .then((response: any) => {
                if (response["response_Code"] === 200) {
                    setState((prevState) => ({
                        ...prevState,
                        UserListSet: response.responseJson,
                    }))
                }
            })
    }

    const addUser = () => {
        setModalTitle("Add User");
        setShowModal(true)
    };

    const userEdit = (data: any) => {
        console.log("this is the data", data)
        setFormData({
            id: data.id,
            name: data.name,
            email: data.email,
            mobile: data.mobile_no,
            password: data.password,
            confirm_password: data.password,
        });
        setModalTitle("Edit User");
        setShowModal(true);
    };

    const editingdetails = () => {
        let request = {
            user_id: formData.id,
            name: formData.name,
            email: formData.email,
            password: formData.password,
            mobile_no: formData.mobile,


        };
        callApi("post", "user_update.php", request)
            .then((res: any) => res.data)
            .then((response: any) => {
                if (response.responseCode === 200) {
                    showSuccess("EDITED!", response.message);
                    getUserDetails();
                    setFormData({
                        id: "",
                        name: "",
                        email: "",
                        mobile: "",
                        password: "",
                        confirm_password: ""
                    });
                    setShowModal(false);
                } else {
                    showError("FAILED!", response.message);
                }
            });
    };

    const userDelete = async (row: any) => {
        const isComfirm = await showAlert(
            "Please Confirm",
            "Are you sure you want to delete the user?",
            "Yes",
            "No"
        );
        if (isComfirm) {
            let requ = {
                "user_id": row.id,
            };
            callApi("POSt", "user_delete.php", requ)
                .then((res: any) => res.data)
                .then((response: any) => {
                    if (response.responseCode === 200) {
                        showSuccess("DELETED!", response.message);
                        getUserDetails();
                    } else {
                        showError("FAILED!", response.message);
                    }
                });
        }
    };
    // const tableData = [
    //     { id: 1, userName: "MRM Enterprises" },
    //     { id: 2, userName: "Varun Electronics" },
    //     { id: 3, userName: "MRM Enterprises" },
    //     { id: 4, userName: "Varun Electronics" },
    //     { id: 5, userName: "MRM Enterprises" },
    //     { id: 6, userName: "Varun Electronics" },
    //     { id: 7, userName: "MRM Enterprises" },
    //     { id: 8, userName: "Varun Electronics" },


    //     // Add more data as needed
    // ];

    // const [currentPage, setCurrentPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(5);
    // const [showModal, setShowModal] = useState(false); // State for showing/hiding modal

    // const pageCount = Math.ceil(state.UserListSet.length / rowsPerPage);
    // const paginatedData = state.UserListSet.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

    const downloadCSV = () => {
        const data = [
            ["user_details"],
            ["S.No", "User Name", "Mobile Number","Password"],
            ...state.UserListSet.map((row: any) => [row.s_no, row.name, row.mobile_no, row.password])
        ];
    
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
    
        // Add rows to the worksheet
        data.forEach((row) => {
            worksheet.addRow(row);
        });
    
        // Merge the title row across all columns and center the title
        worksheet.mergeCells('A1:D1');
        const titleCell = worksheet.getCell('A1');
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
        titleCell.font = { bold: true, size: 14 };
    
        // Style for the header row (second row)
        const headerRow = worksheet.getRow(2);
        headerRow.eachCell((cell: any) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
            cell.font = { bold: true };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } }; // Yellow background
        });
    
        // Write the workbook to an Excel file
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `userdetails_${formattedDate}.xlsx`;
            link.click();
        });
    };

    // const handlePageClickprevious = () => {
    //     setCurrentPage(currentPage - 1);
    // };
    // const handlePageClicknext = () => {
    //     setCurrentPage(currentPage + 1);
    // };

    // const handlepageListChange = (event: ChangeEvent<HTMLSelectElement>) => {
    //     const value = event.target.value === "All" ? state.UserListSet.length : Number(event.target.value);
    //     setRowsPerPage(value);
    //     setCurrentPage(0); // Reset to first page when rows per page changes
    // };
    // const closeModal = () => {
    //     setShowModal(false);
    //     setFormData({
    //         id: "",
    //         name: "",
    //         email: "",
    //         mobile: "",
    //         password: "",
    //         confirm_password: ""
    //     });
    // }






    return (
        <>
            {/* <div className='usermanagement'>
                <div className="container-table-user">
                    <div className="table-container-user">
                        <div className="table-header-user">
                            <button type="button" className="btn excelbtn" onClick={downloadCSV}>Excel</button>
                            <div className="table-search-user">
                                <div className="search-field1">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="search-input"
                                        value={searchQuery}
                                         onChange={handleSearchInputChange}
                                    />
                                </div>
                                <div className='addinguser'>
                                    <button className='btn adduserbtn' onClick={addUser}>Add User</button>
                                </div>
                            </div>
                        </div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Mobile Number</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((row: any) => (
                                    <tr key={row.id}>
                                        <td>{row.s_no}</td>
                                        <td>{row.name}</td>
                                        <td>{row.mobile_no}</td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                                                <button onClick={()=> userEdit(row)}><FontAwesomeIcon icon={faEdit} style={{ height: "20px", width: "20px" }} /></button>
                                                <button onClick={() => userDelete(row)}><FontAwesomeIcon icon={faTrash} style={{ height: "20px", width: "20px" }} /></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="pagination-and-info-user">
                <div className="info-and-limit">
                        <select
                            value={rowsPerPage === state.UserListSet.length ? "All" : rowsPerPage}
                            onChange={handlepageListChange}
                            style={{ border: "1px solid gray", height: "30px", width: "50px", borderRadius: "5px",marginRight:"3px" }}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value="All">All</option>
                        </select>{"     "}
                        <span>Showing {(currentPage * rowsPerPage) + 1} to {(currentPage * rowsPerPage) + paginatedData.length} of { state.UserListSet.length} entries</span>
                    </div>
                    <div className="pagination">
                        <button
                            onClick={handlePageClickprevious}
                            style={{
                                height: "35px",
                                width: "40px",
                                borderRadius: "5px",
                                padding: "0px 0px 0px 0px",
                                backgroundColor: "#00a7f6",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center"
                            }}
                            disabled={currentPage === 0}
                        >
                           <FontAwesomeIcon icon={faArrowLeft} style={{ height: "20px", width: "20px",zIndex:"1",color:"white" }} />
                        </button>

                        <span
                            style={{
                                fontSize: "18px",
                                padding: "0px 8px 8px 0px",
                                backgroundColor: "transperant",
                                margin: "5px 5px 0px 5px",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center"
                            }}
                        >
                            {pageCount === 0 ? "0" : currentPage + 1}
                            <p style={{ padding: "0px 10px 0px 10px" }}>/</p>
                            {pageCount}
                        </span>
                        <button
                            onClick={handlePageClicknext}
                            style={{
                                height: "35px",
                                width: "40px",
                                borderRadius: "5px",
                                padding: "0px 0px 0px 0px",
                                backgroundColor: "#00a7f6",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center"
                            }}
                            disabled={currentPage === pageCount - 1}
                        ><FontAwesomeIcon icon={faArrowRight}  style={{ height: "18px", width: "20px",zIndex:"1",color:"white" }}/>
                        </button>
                    </div>
                    
                </div>
            </div> */}

            <div className="container-qt">
                    <div className="rs-table rs-table-hover">
                    <div className="tabletitle" style={{ fontSize: "24px" }}>User Details</div>
                        <div className="table-header ">
                        <div className='vendor-add-btn'>
                            <button
                                type="button"
                                className="btn excelbtn exbtn"
                                style={{ marginRight: "10px" }}
                                onClick={addUser}
                            >
                                Add User
                            </button>
                            </div>
                            <div className="table-search searchfieldscontain vendor-excel">
                            <button
                                type="button"
                                className="btn excelbtn btnmobiledash"
                                style={{ marginRight: "10px" }}
                                onClick={downloadCSV}
                            >
                                Excel
                            </button>                            
                                <div className="search-field1">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="search-input vendorsearch"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", overflowX: "hidden" }}>
                            <Table
                                height={420}
                                data={getData()}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSortColumn={handleSortColumn}
                                loading={loading}
                                style={{ width: "100%" }} // Adjust min-width as needed
                            >
                                <Column width={50} align="center" fixed>
                                    <HeaderCell >S.No</HeaderCell>
                                    <Cell dataKey="s_no" />
                                </Column>

                                <Column width={100}>
                                    <HeaderCell >Name</HeaderCell>
                                    <Cell dataKey="name" />
                                </Column>
                                <Column width={100} align="center">
                                    <HeaderCell >Mobile Number</HeaderCell>
                                    <Cell dataKey="mobile_no" >

                                    </Cell>
                                </Column>
                                <Column width={100} align="center">
                                    <HeaderCell >Password</HeaderCell>
                                    <Cell dataKey="password" >

                                    </Cell>
                                </Column>

                                <Column width={100} align="center">
                                    <HeaderCell>Action</HeaderCell>
                                    <Cell >
                                        {(rowData) => (
                                            <div>
                                                <button onClick={() => userEdit(rowData)} className='iconbg'><FontAwesomeIcon icon={faEdit} style={{ height: "20px", width: "20px" }} /></button>
                                                <button onClick={() => userDelete(rowData)} className='iconbg'><FontAwesomeIcon icon={faTrash} style={{ height: "20px", width: "20px" }} /></button>
                                            </div>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>
                        </div>
                        <div>
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                className="pagination"
                                size="xs"
                                layout={["total", "pager", "skip"]}
                                total={filteredData.length}
                                limitOptions={[5, 10, 15]}
                                limit={limit}
                                activePage={page}
                                onChangePage={setPage}
                                onChangeLimit={handleChangeLimit}
                            />
                        </div>
                    </div>



            </div>

            {/* Modal for adding new user */}
            <Modal open={showModal} onClose={handleCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className='modaltittle'>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form style={{ padding: "2%", overflow: "hidden" }} >
                        <div className='row'>
                            <div className="col-md-6 form-group">
                                <label htmlFor="name" className='labeltittle'>Name<span className='mandatoryfield'>*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${error && isEmpty(formData.name) ? 'is-invalid' : ''} mt-1 mb-2`}
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                {error && isEmpty(formData.name) && <div className="invalid-feedback">{"Name Error"}</div>}
                                {nameerror && <div className="error-message" style={{ color: "red" }}>Enter valid Name</div>}
                            </div>
                            <div className="col-md-6 form-group">
                                <label htmlFor="mobile" className='labeltittle'>Mobile<span className='mandatoryfield'>*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${error && isEmpty(formData.mobile) ? 'is-invalid' : ''} mt-1 mb-2`}
                                    id="mobile"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    maxLength={10}
                                />
                                {error && isEmpty(formData.mobile) && <div className="invalid-feedback">{"Mobile number Error"}</div>}
                                {mobilenoerror && <div className="error-message" style={{ color: "red" }}>Enter valid Mobile Number</div>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12 form-group">
                                <label htmlFor="email" className='labeltittle'>Email<span className='mandatoryfield'>*</span></label>
                                <input
                                    type="email"
                                    className={`form-control ${error && isEmpty(formData.email) ? 'is-invalid' : ''} mt-1 mb-2`}
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {error && isEmpty(formData.email) && <div className="invalid-feedback">{"Email Error"}</div>}
                                {emailerror && <div className="error-message" style={{ color: "red" }}>Enter valid Email</div>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6 form-group">
                                <label htmlFor="password" className='labeltittle'>Password<span className='mandatoryfield'>*</span></label>
                                <input
                                    type="password"
                                    className={`form-control ${error && isEmpty(formData.password) ? 'is-invalid' : ''} mt-1 mb-2`}
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                {error && isEmpty(formData.password) && <div className="invalid-feedback">{"Password Error"}</div>}
                                {passworderror && <div className="error-message" style={{ color: "red" }}>Password must contain at least 8 characters, one digit, and one special character</div>}
                            </div>
                            <div className="col-md-6 form-group">
                                <label htmlFor="confirm_password" className='labeltittle'>Confirm Password<span className='mandatoryfield'>*</span></label>
                                <input
                                    type="password"
                                    className={`form-control ${error && isEmpty(formData.confirm_password) ? 'is-invalid' : ''} mt-1 mb-2`}
                                    id="confirm_password"
                                    name="confirm_password"
                                    value={formData.confirm_password}
                                    onChange={handleChange}
                                />
                                {error && isEmpty(formData.confirm_password) && <div className="invalid-feedback">{""}</div>}
                                {passwordMatch && <div className="error-message" style={{ color: "red" }}>Passwords do not match</div>}
                            </div>
                        </div>
                        <Button className='newuser mt-3' onClick={(e) => handlesaveuser(e)}>{modalTitle}</Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Usermanagement;

