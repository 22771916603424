import React from "react";
import { useNavigate } from "react-router-dom";
const InvoiceTable = () => {
    const navigate = useNavigate();
    const invoiceEdit = () => {
        navigate("/layout/invoiceform");
    }
    return (
        <div className="container">
            <button style={{ float: "left" }} onClick={invoiceEdit} className="btn btn-primary">
                Invoice Edit
            </button>
            <h1>Invoice Table</h1>
        </div>
    );
};

export default InvoiceTable;
