import Swal from "sweetalert2";

export function showError(title: any, message: any) {
  Swal.fire({
    title: title,
    icon: "error",
    text: message,
    timer: 2000,
    showConfirmButton: false,
    width: "25rem",
  });
}
export function showSuccess(title: any, message: any) {
  Swal.fire({
    title: title,
    icon: "success",
    text: message,
    timer: 2000,
    showConfirmButton: false,
    width: "25rem",
  });
}
export function showAlert(
  title: any,
  text: any,
  confirmBtn: any,
  cancelBtn: any
) {
  // Swal.fire({
  //     title: title,
  //     icon: 'success',
  //     text: message,
  //     timer: 2000,
  //     showConfirmButton: false,
  //     width: '25rem',

  // })
  return new Promise(async (resolve, reject) => {
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      width: "25rem",
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      cancelButtonText: cancelBtn,
      confirmButtonText: confirmBtn,
    }).then((result: any) => {
      if (result.isConfirmed) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
}
