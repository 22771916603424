import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import Increase from "../../assets/dashboard/increase.svg";
import balance from "../../assets/dashboard/balance.png";
import transport from "../../assets/dashboard/Transport.png";
import vendor from "../../assets/dashboard/vendor.png";
import labour from "../../assets/dashboard/labour.png";
// import Table from "react-bootstrap/Table";
import Papa from "papaparse";
import { Table, Pagination } from "rsuite";

import "./Dashboard.css";
import "./site/SiteDetails.css";
import { callApi } from "../../Utils/Api";
import ExcelJS from 'exceljs';

const Dashboard: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [customerBalance, setCustomerBalance] = useState("0");
  const [paymentVendor, setPaymentVendor] = useState("0");
  const [paymentTransport, setPaymentTransport] = useState("0");
  const [paymentLabour, setPaymentLabour] = useState("0");
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [tabletype, setTableType] = React.useState("Customer Balance");
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const { Column, HeaderCell, Cell } = Table;
  const scrollToRef = useRef<HTMLDivElement>(null);

  const downloadCSV = () => {
    const type = tabletype;
    let data: any;
    if (type == "Customer Balance") {
      data = [
        ["Customer Balance"],
        [
          "S.No",
          "Site Name",
          "Invoice Value",
          "Received Amount",
          "Balance Payment",
        ],
        ...tableData.map((row: any) => [
          row.id,
          row.site_name,
          row.invoice_value,
          row.received_payment,
          row.balance_payment,
        ]),
      ];
    } else if (type == "Balance Credits") {
      data = [
        ["Balance Credits"],
        ["S.No", "Site Name", "PE Total Value", "Balance Credits"],
        ...tableData.map((row: any) => [
          row.id,
          row.site_name,
          row.pe_total_value,
          row.balance_credits,
        ]),
      ];
    } else if (type == "Transport Expense") {
      data = [
        ["Transport Expense"],
        ["S.No", "Site Name", "Total Transport"],
        ...tableData.map((row: any) => [row.id, row.site_name, row.transport]),
      ];
    } else if (type == "Labour Expense") {
      data = [
        ["Labour Expense"],
        ["S.No", "Site Name", "Total PE Value", "Balance Credits"],
        ...tableData.map((row: any) => [
          row.id,
          row.site_name,
          row.pe_total,
          row.balance_credits,
        ]),
      ];
    }

   
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
    
        // Add rows to the worksheet
        data.forEach((row:any) => {
            worksheet.addRow(row);
        });
    
        // Merge the title row across all columns and center the title
        worksheet.mergeCells('A1:D1');
        const titleCell = worksheet.getCell('A1');
        titleCell.value = type; // Set the title value
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
        titleCell.font = { bold: true, size: 14 };
    
        // Style for the header row (second row)
        const headerRow = worksheet.getRow(2);
        headerRow.eachCell((cell: any) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
            cell.font = { bold: true };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } }; // Yellow background
        });
    
        // Write the workbook to an Excel file
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download =type+`${formattedDate}.xlsx`;
            link.click();
        });
  };

  const handlePageClickPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageListChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value =
      event.target.value === "All"
        ? tableData.length
        : Number(event.target.value);
    setRowsPerPage(value);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const totalPages = Math.ceil(tableData && tableData?.length / rowsPerPage);
  const paginatedData =
    tableData &&
    tableData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = paginatedData.filter(
    (row: any) =>
      row.site_name &&
      row.site_name.toLowerCase().includes(searchQuery.toLowerCase())
    // (row.balance_payment &&
    //   row.balance_payment
    //     .toLowerCase()
    //     .includes(searchQuery.toLowerCase())) ||
    // (row.balance_payment &&
    //   row.balance_payment.toLowerCase().includes(searchQuery.toLowerCase())
    // )
  );

  const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    getDashBoardDetails("Customer Balance", 1);
  }, []);

  useEffect(() => {}, [tableData, tabletype]);

  const getDashBoardDetails = (type: any, status: any) => {
    setLoading(true);
    setTableType(type);
    const req = {
      status: status,
    };
    callApi("post", "dashboard.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["response_Code"] === 200) {
          if (scrollToRef.current !== null) {
            scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          const data = response.responseJson;
          setLoading(false);
          setCustomerBalance(data.customer_balance ?? "0");
          setPaymentVendor(data.payment_vendor ?? "0");
          setPaymentTransport(data.payment_transport ?? "0");
          setPaymentLabour(data.payment_labour ?? "0");
          if (data.sites && data.sites.length > 0) {
            setTableData(data.sites);
          }else{
            setTableData([]);
          }
        } else {
          console.error("Failed to Fetch");
        }
      })
      .catch((error) => {
        console.error("Error Fetching:", error);
      });
  };

  const data =
    tableData &&
    tableData.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });

  const getData = () => {
    let filteredData = tableData;

    // Apply search filter if searchQuery exists
    if (searchQuery) {
      filteredData =
        tableData &&
        tableData.filter((row) =>
          Object.values(row).some((value) =>
            typeof value === "string"
              ? value.toLowerCase().includes(searchQuery.toLowerCase())
              : false
          )
        );
    }

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      return data.slice().sort((a, b) => {
        let x: any = a[sortColumn];
        let y: any = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt(0); // Explicitly specify the index for charCodeAt
        }
        if (typeof y === "string") {
          y = y.charCodeAt(0); // Explicitly specify the index for charCodeAt
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  return (
    <div className="dashboard">
      <div className="card-container">
        <>
          <div
            className={`card-section ${
              tabletype == "Customer Balance" ? "card-section-select" : ""
            }`}
            onClick={() => getDashBoardDetails("Customer Balance", 1)}
          >
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Customer Balance</span>
                <img src={balance} alt="Total Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">₹{customerBalance}</h1>
              <span className="cardfontfooter">
                Click here to get Customer Balance
              </span>
            </a>
          </div>
          <div
            className={`card-section ${
              tabletype == "Balance Credits" ? "card-section-select" : ""
            }`}
            onClick={() => getDashBoardDetails("Balance Credits", 2)}
          >
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Balance Credits</span>
                <img src={vendor} alt="Hot leads" className="leads-icon" />
              </div>
              <h1 className="card-info">₹{paymentVendor}</h1>
              <span className="cardfontfooter">
                Click here to get Balance Credits
              </span>
            </a>
          </div>
          <div
            className={`card-section ${
              tabletype == "Transport Expense" ? "card-section-select" : ""
            }`}
            onClick={() => getDashBoardDetails("Transport Expense", 3)}
          >
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Transport Expense</span>
                <img src={transport} alt="Warm Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">₹{paymentTransport}</h1>
              <span className="cardfontfooter">
                Click here to get Transport Expense
              </span>
            </a>
          </div>
          <div
            className={`card-section ${
              tabletype == "Labour Expense" ? "card-section-select" : ""
            }`}
            onClick={() => getDashBoardDetails("Labour Expense", 4)}
          >
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Labour Expense</span>
                <img src={labour} alt="Cold Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">₹{paymentLabour}</h1>
              <span className="cardfontfooter">
                Click here to get Labour Expense
              </span>
            </a>
          </div>
        </>
      </div>

      <div className="container-qt">
        {/* <div className="rs-panel-body"> */}
          <div className="rs-table rs-table-hover">
            <div className="table-header headeroftabel">
            <div className="table-search searchfieldscontain vendor-excel" style={{display:"flex",justifyContent:"start",marginRight:"12px"}}>
              <div>
              <button
                type="button"
                className="btn excelbtn btnmobiledash "
                style={{ marginRight: "12px"}}
                onClick={downloadCSV}
              >
                Excel
              </button>
              </div>
                <div ref={scrollToRef}  className="search-field1">
                  <input
                    type="text"
                    placeholder="Search"
                    className="search-input vendorsearch"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
            {tabletype == "Customer Balance" ? (
              <>
                <Table
                  height={420}
                  data={getData()}
                  sortColumn={sortColumn}
                  sortType={sortType}
                  onSortColumn={handleSortColumn}
                  loading={loading}
                  style={{ width: "100%" }}
                >
                  <Column width={50} align="center" fixed>
                    <HeaderCell>S.No</HeaderCell>
                    <Cell>
                      {(rowData, rowIndex) =>
                        rowIndex !== undefined ? rowIndex + 1 : ""
                      }
                    </Cell>
                  </Column>

                  <Column width={200}>
                    <HeaderCell>Site Name</HeaderCell>
                    <Cell dataKey="site_name" />
                  </Column>

                  <Column width={200}>
                    <HeaderCell>Invoice Value</HeaderCell>
                    <Cell dataKey="invoice_value" />
                  </Column>

                  <Column width={200}>
                    <HeaderCell>Received Amount</HeaderCell>
                    <Cell dataKey="received_payment" />
                  </Column>

                  <Column width={200}>
                    <HeaderCell>Balance Payment</HeaderCell>
                    <Cell dataKey="balance_payment" />
                  </Column>
                </Table>
              </>
            ) : (
              <>
                {tabletype == "Balance Credits" ? (
                  <>
                    <Table
                      height={420}
                      data={getData()}
                      sortColumn={sortColumn}
                      sortType={sortType}
                      onSortColumn={handleSortColumn}
                      loading={loading}
                      style={{ width: "100%" }}
                    >
                      <Column width={50} align="center" fixed>
                        <HeaderCell>S.No</HeaderCell>
                        <Cell>
                          {(rowData, rowIndex) =>
                            rowIndex !== undefined ? rowIndex + 1 : ""
                          }
                        </Cell>
                      </Column>

                      <Column width={200}>
                        <HeaderCell>Site Name</HeaderCell>
                        <Cell dataKey="site_name" />
                      </Column>

                      <Column width={200}>
                        <HeaderCell>PE Total Value</HeaderCell>
                        <Cell dataKey="pe_total_expense" />
                      </Column>
                      <Column width={200}>
                        <HeaderCell>Vendor Credit</HeaderCell>
                        <Cell dataKey="credit_amt_vendor" />
                      </Column>
                      <Column width={200}>
                        <HeaderCell>PE Vendor</HeaderCell>
                        <Cell dataKey="pe_total_vendor" />
                      </Column>
                      <Column width={200}>
                        <HeaderCell>PE Labour</HeaderCell>
                        <Cell dataKey="pe_total_labour" />
                      </Column>

                      <Column width={200}>
                        <HeaderCell>Balance Credits</HeaderCell>
                        <Cell dataKey="balance_credits" />
                      </Column>
                    </Table>
                  </>
                ) : (
                  <>
                    {tabletype == "Transport Expense" ? (
                      <>
                        <Table
                          height={420}
                          data={getData()}
                          sortColumn={sortColumn}
                          sortType={sortType}
                          onSortColumn={handleSortColumn}
                          loading={loading}
                          style={{ width: "100%" }}
                        >
                          <Column width={50} align="center" fixed>
                            <HeaderCell>S.No</HeaderCell>
                            <Cell>
                              {(rowData, rowIndex) =>
                                rowIndex !== undefined ? rowIndex + 1 : ""
                              }
                            </Cell>
                          </Column>

                          <Column width={200}>
                            <HeaderCell>Site Name</HeaderCell>
                            <Cell dataKey="site_name" />
                          </Column>

                          <Column width={200}>
                            <HeaderCell>Total Transport</HeaderCell>
                            <Cell dataKey="transport" />
                          </Column>
                        </Table>
                      </>
                    ) : (
                      <>
                        {tabletype == "Labour Expense" ? (
                          <>
                            <Table
                              height={420}
                              data={getData()}
                              sortColumn={sortColumn}
                              sortType={sortType}
                              onSortColumn={handleSortColumn}
                              loading={loading}
                              style={{ width: "100%" }}
                            >
                              <Column width={50} align="center" fixed>
                                <HeaderCell>S.No</HeaderCell>
                                <Cell>
                                  {(rowData, rowIndex) =>
                                    rowIndex !== undefined ? rowIndex + 1 : ""
                                  }
                                </Cell>
                              </Column>

                              <Column width={200}>
                                <HeaderCell>Site Name</HeaderCell>
                                <Cell dataKey="site_name" />
                              </Column>

                              <Column width={200}>
                                <HeaderCell>Total PE Value</HeaderCell>
                                <Cell dataKey="pe_total" />
                              </Column>

                              <Column width={200}>
                                <HeaderCell>Balance Credits</HeaderCell>
                                <Cell dataKey="balance_credits" />
                              </Column>
                            </Table>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <div>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                className="pagination"
                size="xs"
                layout={["total", "pager", "skip"]}
                total={tableData.length}
                limitOptions={[5, 10, 15]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
