import React, { useEffect, useState } from "react";
import "./Quotationpdf.css";
import { useParams, useNavigate } from "react-router-dom";
import { callApi } from "../../Utils/Api";
import rs1 from "../../assets/rs1.svg";
import rs from "../../assets/rs.svg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// Define interfaces for the data structure
interface Item {
  item_id: string;
  po_id: string;
  product_id: string;
  product: string;
  po_qty: string;
  po_unit_price: string;
  po_total: string;
  pe_qty: string;
  pe_unit_price: string;
  pe_total: string;
}

interface PurchaseOrder {
  po_id: string;
  pe_id: string;
  pe_number: string;
  po_number: string | null;
  pe_value: string;
  pe_date: string;
  vendor_id: string;
  vendor_name: string;
  site_id: string;
  comments: string;
  items: Item[];
  shipping_address: string;
}

const PurchaseEntryPage: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<PurchaseOrder | null>(null);
  const logo1 = require("../../assets/sidebar/image1.png");
  const { pe_id } = useParams<{ pe_id: string }>();

  useEffect(() => {
    getQuotationDetails();
  }, []);

  const getQuotationDetails = () => {
    const req = {
      pe_id: pe_id,
    };
    callApi("post", "pe_id_select.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["response_Code"] === 200) {
          setData(response.responseJson[0]);
        }
      });
  };

  if (!data) {
    return <div>Loading...</div>;
  }
  const handlegoBack = () => {
    const site_Id = localStorage.getItem("siteid");
    navigate(`/layout/sitedetails/${site_Id}`);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById("pdf-content");
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save(`purchase_entry_${pe_id}.pdf`);
      });
    }
  };

  const shipadd = localStorage.getItem("shipadd") || "";

  return (
    <div className="container">
      <div style={{ padding: "20px" }}>
        <button
          style={{ float: "left" }}
          className="btn excelbtn"
          onClick={handlegoBack}
        >
          Go Back
        </button>
        <button
          style={{ float: "right" }}
          className="btn excelbtn convert-btn"
          onClick={handleDownloadPDF}
        >
          Download PDF
        </button>
      </div>
      <div className="card quotationcard" id="pdf-content">
        <div className="header1">
          <div>
            <img src={logo1} alt="D&D Architects Logo" width="100" />
          </div>
          <div className="title-qt">
            <h3>D&D ARCHITECTS</h3>
          </div>

          <div className="quotation-text">
            <h4>PURCHASE ENTRY</h4>
          </div>
        </div>
        <div className="section-title contact-info">
          <p>
            <strong>Contact: +91 99433 44464</strong>
          </p>
          <p>
            <strong>Website: http://www.ddarchitects.co</strong>
          </p>
        </div>
        <div className="section">
          <div className="quote-info">
            <div className="projectdiv">
              <h6>VENDOR</h6>
            </div>
            <div className="projectdiv">
              <h6>SHIP TO</h6>
            </div>
            <div></div>
          </div>
        </div>

        <div className="section">
          <div className="quote-info">
            <div
              className=""
              style={{
                backgroundColor: "#00a7f6",
                color: "white",
              }}
            >
              <p>
                <strong>{data.vendor_name}</strong>
              </p>
              <p>
                <strong dangerouslySetInnerHTML={{ __html: shipadd }} />
              </p>
            </div>
            <div
              className=""
              style={{
                backgroundColor: "#00a7f6",
                color: "white",
              }}
            >
              <p>
                <strong>D&D Architects</strong>
              </p>
              <p>
                <strong>F7,Shanmugam Complex,1st Floor</strong>
              </p>
              <p>
                <strong>Mtp Road,RS Purum</strong>
              </p>
              <p>
                <strong>Coimbatore-641002</strong>
              </p>
            </div>
            <div
              style={{
                backgroundColor: "rgb(89 89 89)",
                color: "white",
                padding: "5px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>
                  DATE: <span>{data.pe_date}</span>
                </strong>
              </p>
              <p>
                <strong>PE: {data.pe_number}</strong>
              </p>
            </div>
          </div>
        </div>

        <div className="section roomsection">
          <table className="table1">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Description</th>
                <th>Qty</th>
                <th>Unit price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {data.items.map((item, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{item.product}</td>
                  <td>{item.pe_qty}</td>
                  <td>{item.po_unit_price}</td>
                  <td>
                    <img
                      src={rs1}
                      style={{ backgroundColor: "#ffff" }}
                      alt="rs1"
                    />{" "}
                    {item.pe_total}
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}></td>
                <td
                  className="total"
                  style={{
                    backgroundColor: "rgb(89 89 89)",
                    color: "white",
                    textAlign: "start",
                  }}
                >
                  Sub Total
                </td>
                <td
                  style={{
                    backgroundColor: "rgb(89 89 89)",
                    color: "white",
                  }}
                >
                  <img src={rs} alt="rs" /> {data.pe_value}
                </td>
              </tr>
            </tbody>
          </table>
          {data.comments ? (
            <div className="materials-section">
              <h5 className="underline">Comments:</h5>
              <p dangerouslySetInnerHTML={{ __html: data.comments }} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseEntryPage;
