import React, { useState, useEffect } from "react";
import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useNavigate } from "react-router-dom";
import eye from "../../assets/loginimage/eye (1).png";
import eyehide from "../../assets/loginimage/hidden.png";
import $ from "jquery";
import logo from "../../assets/loginimage/D&Doglogo.png";
import { callApi } from "./../../../src/Utils/Api";
import { showError, showSuccess } from "../../Utils/sweetAlert";
import bkimge from "../../assets/loginimage/D&D.png"

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState("");
  const [show, setShow] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlechange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "username") {
      if (/^\d*$/.test(value)) {
        setUsername(value);
        const validUsername = value.length === 10;
        setNameError(!validUsername);
      }
    }

    if (name === "password") {
      setPassword(value);
      const validPassword =
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(value);
      setPasswordError(value.length > 0 && !validPassword);
    }
  };

  const handlelogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!username || !password || nameError || passwordError) {
      setNameError(!username || nameError);
      setPasswordError(!password || passwordError);
      return;
    }

    const req = {
      mobile_no: username,
      password: password,
    };

    callApi("POST", "login.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["response_Code"] === 200) {
          setTimeout(() => {
            setLoading(false);
          }, 500);
          setTimeout(() => {
            showSuccess("Success!", "Login Successfully!");
          }, 1000);
          const userDetails = response.response.data;
          sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
          localStorage.setItem("username",userDetails.name);
          setTimeout(() => {
            navigate("/layout/dashboard");
          }, 2000);
        } else {
          showError("Failed", "The mobile  number and password are not matched!");
        }
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    $(".field-wrapper .field-placeholder").on("click", function () {
      $(this).closest(".field-wrapper").find("input").focus();
    });

    $(".field-wrapper input").on("keyup", function () {
      var value = $.trim(String($(this).val()));
      if (value) {
        $(this).closest(".field-wrapper").addClass("hasValue");
      } else {
        $(this).closest(".field-wrapper").removeClass("hasValue");
      }
    });

    return () => {
      $(".field-wrapper .field-placeholder").off("click");
      $(".field-wrapper input").off("keyup");
    };
  }, []);

  return (
    <div className="dndloginpage">
      {/* <div className="loginpageheadtext">
        <span className="textloinghead">LO</span>
        <span className="textloinghead1">GIN</span>
      </div> */}
      <div className="loginpage">
        <div className="row dndrowcontainer" style={{padding:"0px"}}>
          <div className="col-md-6 col-sm-12 logcol1">
            <div className="logocontain">
              <img
                className="logologpage"
                src={logo}
                alt="logo"
                height={180}
                width={180}
              ></img>

              <form className="logpageform" onSubmit={handlelogin}>
                <div className="userdetails">
                  <div className="field-wrapper">
                    <div className="inputfield-icon">
                      <input
                        className="input-field"
                        name="username"
                        type="text"
                        value={username}
                        onChange={(e) => handlechange(e)}
                        pattern="^\d{10}$"
                        maxLength={10}
                      />
                      <div className="field-placeholder">
                        <span>Mobile Number</span>
                      </div>
                    </div>
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {nameError && "Enter a valid 10-digit Mobile Number"}
                    </span>
                  </div>
                  <div className="field-wrapper mt-3">
                    <div className="inputfield-icon">
                      <input
                        className="input-field"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => handlechange(e)}
                        minLength={8}
                      />
                      <div className="field-placeholder">
                        <span>Password</span>
                      </div>
                      <span
                        className="eye-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <img src={eye} width={20} height={20} />
                        ) : (
                          <img src={eyehide} width={20} height={20} />
                        )}
                      </span>
                    </div>
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {passwordError && "Enter a valid Password"}
                    </span>
                  </div>
                </div>
                <div className="form-button logbtn">
                  <button type="submit" className="btn submitbtn">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 logcol2" style={{padding:"0px"}}>
            <img src={bkimge} alt="backgroundimage"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
