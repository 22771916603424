import React, { useEffect, useState, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./quatation.css";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess } from "../../Utils/sweetAlert";
import { callApi } from "../../Utils/Api";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faDownload,
  faEye,
  faPaperclip,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

interface Row {
  id: any;
  sno: number;
  description: string;
  length: string;
  h_w: string;
  area: string;
  totalPrice: string;
}
interface Less {
  id: any;
  sno: number;
  description: string;
  amount: any;
}

interface Table {
  name: string;
  rows: Row[];
}
interface LessTable {
  name: string;
  rows: Less[];
}

function Returnslip() {
  const navigate = useNavigate();
  const [Rtname, setRtname] = useState("");
  const [Rtdate, setRtdate] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [Ponumber, setPonumber] = useState("");
  const [returnId, setReturnId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [Remark, setRemark] = useState("");
  const [CustomerVendor, setCustomerVendor] = useState("");
  const [deletedIds, setDeletedIds] = useState<number[]>([]);
  const [receivedAmount, setReceivedAmount] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [rows, setRows] = useState<{ [category: string]: Row[] }>({});
  const [submitting, setSubmitting] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [comments, setComments] = useState("");
  const { rid } = useParams<{ rid: string }>();
  const [showEditors, setShowEditors] = useState<{
    [category: string]: boolean;
  }>({});
  const [fileType, setFileType] = useState("");
  const [tables, setTables] = useState<Table[]>([]);
  const [lessWork, setLessWork] = useState<LessTable[]>([]);
  const [ckeditorData, setCkeditorData] = useState<{
    [category: string]: string;
  }>({});
  const [state, setState] = useState({
    QuoteListSet: { category: [] as any[] },
    vendor: [
      {
        id: "",
        name: "",
      },
    ],
    item: [
      {
        id: "",
        product: "",
        product_price: "",
      },
    ],
    poData: [],
  });
  const [deletedCategoryItemIds, setDeletedCategoryItemIds] = useState<
    number[]
  >([]);
  const [deletedCategoryIds, setDeletedCategoryIds] = useState<number[]>([]);
  const [deletedLessWorkIds, setDeletedLessWorkIds] = useState<number[]>([]);
  const [deletedTableIds, setDeletedTableIds] = useState<number[]>([]);

  useEffect(() => {
    console.log(
      deletedCategoryItemIds,
      deletedCategoryIds,
      deletedLessWorkIds,
      deletedTableIds
    );
  }, [
    deletedCategoryItemIds,
    deletedCategoryIds,
    deletedLessWorkIds,
    deletedTableIds,
  ]);

  useEffect(() => {
    getQuotatilDetails();
    GetVendorDetails();
    const venid = localStorage.getItem("vendor_id") || "";
    setCustomerVendor(venid);
  }, []);

  const getQuotatilDetails = () => {
    const req = {
      quo_id: "",
    };
    callApi("post", "quo_detail.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["responseCode"] === 200) {
          const data = response.responseJson;
          setState((prevState) => ({
            ...prevState,
            QuoteListSet: response.responseJson,
          }));

          const categories = data.category.map((cat: any) => cat.categoryName);

          const rows = data.category.reduce((acc: any, cat: any) => {
            acc[cat.categoryName] = cat.categoryItems.map(
              (item: any, index: any) => ({
                id: item.item_id,
                sno: index,
                description: item.description,
                length: item.length,
                h_w: item.height,
                area: item.area,
                totalPrice: item.totalPrice,
              })
            );
            return acc;
          }, {});

          const material = data.category.reduce((acc: any, cat: any) => {
            acc[cat.categoryName] = cat.material;
            return acc;
          }, {});

          const extraWork = data.extraWork.map((extra: any) => ({
            name: extra.categoryName,
            rows: extra.categoryItems.map((item: any, index: any) => ({
              id: item.item_id,
              sno: index,
              description: item.description,
              length: item.length,
              h_w: item.height,
              area: item.area,
              totalPrice: item.totalPrice,
            })),
          }));

          const lessWork = Array.isArray(data.lesswork)
            ? data.lesswork.map((less: any) => ({
                name: less.categoryName,
                rows: less.categoryItems.map((item: any, index: any) => ({
                  id: item.item_id,
                  sno: index,
                  description: item.description,
                  amount: item.amount,
                })),
              }))
            : [];
          setCategories(categories);
          setRows(rows);
          setTables(extraWork);
          setLessWork(lessWork);
          setCkeditorData(material);
        } else {
          setState((prevState: any) => ({
            ...prevState,
            QuoteListSet: [],
          }));
        }
      });
  };

  const GetVendorDetails = () => {
    callApi("POST", "vendor_list.php", "")
      .then((res) => res.data)
      .then((response) => {
        const filteredVendors = response.responseJson.filter(
          (vendor: any) => vendor.vendor_status === "1"
        );
        setState((prevState: any) => ({
          ...prevState,
          vendor: filteredVendors,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    callApi("POST", "product_list.php", "")
      .then((res) => res.data)
      .then((response) => {
        console.log("response--->222", response.responseJson);

        setState((prevState: any) => ({
          ...prevState,
          item: response.responseJson,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    let req = {
      rt_id: "",
    };

    callApi("POST", "return_select.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["response_Code"] == 200) {
          setState((prevState: any) => ({
            ...prevState,
            poData: response?.responseJson[0],
          }));
          setReturnId(response?.responseJson[0]?.id);
          setPonumber(response?.responseJson[0]?.po_no);
          setSiteId(response?.responseJson[0]?.site_id);
          setCustomerVendor(response?.responseJson[0]?.vendor_id);
          setComments(response?.responseJson[0]?.comments);
          setRtname(response?.responseJson[0]?.rt_no);
          setRemark(response?.responseJson[0]?.remarks);
          setItemData(response?.responseJson[0]?.items);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("poData_____________", state.poData, ItemData);
  };

  const [ItemData, setItemData] = useState([
    {
      orginal_product: "",
      orginal_qty: "",
      mis_matched_qty: "",
      mis_matched_product: "",
      id: "",
    },
  ]);

  const addItemRow = (): void => {
    const newItem = {
      orginal_product: "",
      orginal_qty: "",
      mis_matched_qty: "",
      mis_matched_product: "",
      id: "",
    };
    setItemData((prevItemData) => [...prevItemData, newItem]);
    console.log(ItemData); // This may not reflect the updated state immediately
  };

  const deleteItemRow = (index: number): void => {
    const updatedExpenceData = [...ItemData];
    const deletedItemId: any = updatedExpenceData[index]?.id; // Assuming `id` is the identifier
    updatedExpenceData.splice(index, 1);
    setItemData(updatedExpenceData);
    setDeletedIds([...deletedIds, deletedItemId]);
  };
  useEffect(() => {
    console.log("expenceData has changed:", ItemData);
  }, [ItemData]);

  useEffect(() => {
    setRtdate(getCurrentDate());
  }, []);
  const onchange = (e: any, index: number) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    console.log("event___", fieldName, fieldValue);
    if (
      fieldName === "orginal_product" &&
      !/^[a-zA-Z0-9\s]*$/.test(fieldValue)
    ) {
      return;
    }
    if (
      fieldName === "mis_matched_product" &&
      !/^[a-zA-Z0-9\s]*$/.test(fieldValue)
    ) {
      return;
    }
    if (
      (fieldName === "mis_matched_qty" && !/^[0-9\s]*$/.test(fieldValue)) ||
      fieldValue === "0"
    ) {
      return;
    }
    if (
      (fieldName === "original_qty" && !/^[0-9\s]*$/.test(fieldValue)) ||
      fieldValue === "0"
    ) {
      return;
    }

    let newItem;
    const updatedExpenceData: any = [...ItemData];
    if (fieldName == "po_qty") {
      const po_unit_price: any = updatedExpenceData[index].po_unit_price;
      const po_total = fieldValue * po_unit_price;
      newItem = {
        ...updatedExpenceData[index],
        [fieldName]: fieldValue,
        po_total,
      };
    } else {
      newItem = { ...updatedExpenceData[index], [fieldName]: fieldValue };
    }
    // newItem.valid = fieldValue !== "";
    updatedExpenceData[index] = newItem;
    setItemData(updatedExpenceData);
  };
  const [isValid, setIsValid] = useState(false);
  const checkValid = () => {
    let valid = true;
    const updatedItemData = ItemData.map((element) => {
      if (
        element.orginal_product !== "" &&
        element.orginal_qty !== "" &&
        element.mis_matched_product !== "" &&
        element.mis_matched_qty !== ""
      ) {
        return { ...element, valid: true };
      } else {
        valid = false;
        return { ...element, valid: false };
      }
    });
    setItemData(updatedItemData);
    return valid;
  };
  const submitHandelClick = async () => {
    const isValid = await checkValid();
    console.log("checkis valid------", isValid);
    const siteId = localStorage.getItem("siteid");
    const req = {
      id: returnId ?? "",
      rt_no: Rtname,
      site_id: siteId,
      remarks: Remark,
      po_date: Ponumber,
      vendor_id: CustomerVendor,
      po_id: rid,
      comments: comments,
      items: ItemData,
      deletedIds: deletedIds,
      selectedFile: selectedFile,
      bill_upload: base64String,
      fileType: fileType,
      rt_date: Rtdate,
    };
    if (isValid) {
      setIsValid(false);
      callApi("post", "return_create.php", req)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response["responseCode"] === 200) {
            showSuccess("Success!", response.message);
            setTimeout(() => {
              navigate(`/layout/returnslippdf/${response.rt_id}`);
            }, 2000);

            GetVendorDetails();
          } else {
            showError("Failed", response.message);
          }
        });
    } else {
      setIsValid(true);
    }

    console.log("submitHandelClick", req);
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [base64String, setBase64String] = useState<string | null>(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      console.log("file___", file, event.target);

      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
      convertToBase64(file);
    }
  };

  const convertToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64 = reader.result as string;
      setBase64String(base64);
      setFileType(file.type);
      console.log(base64, file.type);
    };
    reader.onerror = (error) => {
      console.error("Error converting file to Base64: ", error);
    };
  };

  const handlePreviewClick = () => {
    if (previewUrl) {
      window.open(previewUrl, "_blank");
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handlegoBack = () => {
    navigate(-1);
  };
  const vendornames = localStorage.getItem("vendor_name") || "";

  return (
    <>
      {" "}
      <div className="">
        <div style={{ padding: "20px" }}>
          <div className="text-center col-flex11">
            <button
              style={{ float: "left" }}
              className="btn excelbtn mr-2 pdf-btn"
              onClick={handlegoBack}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
      <div className="container-qt">
        <header className="head pe-entry">
          <h5>Add Return Slip </h5>
        </header>

        <div className="">
          <Row>
            <Col lg="3" md="2" sm="2" xs="2">
              <label>Date</label>
              <input
                type="date"
                value={Rtdate}
                className="form-control"
                onChange={(e) => setRtdate(e.target.value)}
              />
            </Col>
            <Col lg="3" md="4" sm="4" xs="4">
              <label>Vendor</label>
              <input
                type="text"
                value={vendornames}
                className="form-control"
                disabled
              />
            </Col>
          </Row>
          {/* <Row>
                <Col lg="2" md="2" sm="2" xs="2"></Col>
                <Col lg="4" md="4" sm="4" xs="4">
                    <label>Vendor</label>
                    <Form.Select
                        aria-label="Default select example"
                        style={{ height: "58%" }}
                        value={CustomerVendor || ""}
                        onChange={(e) => setCustomerVendor(e.target.value)}

                    >
                        {state.vendor.map((vendor) => (
                            <option
                                key={vendor.id}
                                value={vendor.id}
                            >
                                {vendor.name}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col lg="2" md="2" sm="2" xs="2"></Col>
            </Row> */}
        </div>
        <div className="category-section">
          <h4>Item Details</h4>
          <div className="table-container">
            <table className="custom-table-ban">
              <thead>
                <tr>
                  <th>SNo</th>
                  <th>Ordered Product</th>
                  <th>QTY</th>
                  <th>Miss matched Product</th>
                  <th>QTY</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {ItemData?.length > 0
                  ? ItemData.map((itm: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <input
                              className="table-input-style textcenter"
                              type="text"
                              name="orginal_product"
                              value={itm.orginal_product}
                              onChange={(e) => onchange(e, index)}
                              minLength={1}
                              maxLength={40}
                            />
                            {isValid && itm.orginal_product == "" && (
                              <p
                                style={{
                                  margin: "0px 5px",
                                  textAlign: "left",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                Required Field.
                              </p>
                            )}
                          </td>
                          <td>
                            <input
                              className="table-input-style textcenter"
                              type="text"
                              name="orginal_qty"
                              value={itm.orginal_qty}
                              onChange={(e) => onchange(e, index)}
                              minLength={1}
                              maxLength={10}
                            />
                            {isValid && itm.orginal_qty == "" && (
                              <p
                                style={{
                                  margin: "0px 5px",
                                  textAlign: "left",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                Required Field.
                              </p>
                            )}
                          </td>
                          <td>
                            <input
                              className="table-input-style textcenter"
                              type="text"
                              name="mis_matched_product"
                              value={itm.mis_matched_product}
                              onChange={(e) => onchange(e, index)}
                              minLength={1}
                              maxLength={40}
                            />
                            {isValid && itm.mis_matched_product == "" && (
                              <p
                                style={{
                                  margin: "0px 5px",
                                  textAlign: "left",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                Required Field.
                              </p>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              className="table-input-style textcenter"
                              name="mis_matched_qty"
                              value={itm.mis_matched_qty}
                              onChange={(e) => onchange(e, index)}
                              minLength={1}
                              maxLength={10}
                            />
                            {isValid && itm.mis_matched_qty == "" && (
                              <p
                                style={{
                                  margin: "0px 5px",
                                  textAlign: "left",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              >
                                Required Field.
                              </p>
                            )}
                          </td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              className="remove-row-btn"
                              style={{ width: "30px" }}
                              onClick={() => deleteItemRow(index)}
                            >
                              <span style={{ fontWeight: "900" }}>-</span>
                            </button>
                            <button
                              className="add-row-btn"
                              onClick={addItemRow}
                            >
                              <span style={{ fontWeight: "900" }}>+</span>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
                {/* {ItemData?.length > 0 && (
                      <tr>
                          <td colSpan={4} style={{ textAlign: "right", fontWeight: "bold" }}></td>
                          <td className="grandtotal">
                              Grand Total =  {ItemData.reduce((acc: any, curr: any) => acc + parseFloat(curr.po_total || 0), 0).toFixed(2)}
                          </td>
                          <td></td>
                      </tr>
                  )} */}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "1rem",
          }}
        >
          <label
            htmlFor="file-upload"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              fontSize: "16px",
              color: "#333",
              border: "1px solid #ccc",
              padding: "8px 12px",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <FontAwesomeIcon
              icon={faPaperclip}
              style={{ marginRight: "8px" }}
            />
            <span>Attach Bill</span>
          </label>
          <input
            type="file"
            id="file-upload"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".img,.pdf,.png,.jpg,.jpeg"
            onChange={handleFileChange}
          />
          <button
            onClick={handlePreviewClick}
            style={{
              background: "transparent",
              border: "none",
              cursor: "pointer",
              color: "#007bff",
              fontSize: "18px",
              padding: "5px",
              borderRadius: "5px",
              transition: "color 0.3s",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.color = "#0056b3")}
            onMouseLeave={(e) => (e.currentTarget.style.color = "#007bff")}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </div>

        <div>
          <Row>
            <Col lg="4" md="4" sm="4" xs="4">
              <label>Remarks</label>
              <CKEditor
                editor={ClassicEditor}
                data={Remark}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setRemark(data);
                }}
                config={{
                  placeholder: "Enter Remark...",
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="category-section">
          <Row>
            <Col lg="3" md="3" sm="3" xs="3" className="flex">
              <Button
                variant="outline-primary subbutton"
                type="submit"
                className="subbtn"
                onClick={submitHandelClick}
              >
                Save{" "}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Returnslip;
