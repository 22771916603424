import React, { useEffect, useState } from "react";
import "./Header.css";
import AddNew from "../../assets/header/addNew.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import menu from "../../assets/menu.svg";
import { useNavigate } from "react-router-dom";
import userprofile from "../../assets/userprofile.png";
import { showAlert } from "../../Utils/sweetAlert";
import Logo from "../../assets/sidebar/D&Dlogoofinal.png";

interface HeaderProps {
  toggleSidebar: () => void;
  headerTitle: any;
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar, headerTitle }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const userprofilename = localStorage.getItem("username");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsSidebarOpen(false); // Ensure the sidebar is open on desktop view
      }
    };
    const userprofilename = localStorage.getItem("username");
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openModal = () => {
    navigate("/layout/createform");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log("searchQuery", searchQuery);
  }, [searchQuery]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const handleUserClick = () => {
    setShowUserDetails(!showUserDetails);
  };

  const handleLogout = async () => {
    const isConfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to log out?",
      "Yes",
      "No"
    );
    if (isConfirm) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
  };

  console.log("cgbdhwkj==========>", userprofilename);

  return (
    <div className={`header ${isMobile ? "" : "d-grid"}`}>
      {isMobile && (
        <>
          <button onClick={toggleSidebar} className="mobile-toggle-button">
            <img src={menu} width={35} height={35} alt="menu" />
          </button>
          <img
            src={Logo}
            alt="Logo"
            className="headerlogo"
            width={200}
            height={50}
          />
        </>
      )}
      <div className="right-side">
        <div className="userprofile" onClick={handleUserClick}>
          <span className="prof">{userprofilename}</span>
          <img
            src={userprofile}
            alt="user"
            height={50}
            width={50}
            style={{ marginLeft: "5px" }}
          />
        </div>
        {showUserDetails && (
          <div className="user-details-card">
            <div
              onClick={handleLogout}
              style={{ display: "grid", justifyContent: "center" }}
            >
              <span className="prof1">{userprofilename}</span>
              <span>LOG OUT</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
