import React, { useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

const IdleTimerComponent = () => {
  const navigate = useNavigate();
  const idleTimerRef = useRef(null);

  const onIdle = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  useIdleTimer({
    ref: idleTimerRef,
    timeout: 1000 * 600, // 2 minutes in milliseconds
    onIdle: onIdle,
    debounce: 500,
  });

  return null;
};

export default IdleTimerComponent;
