import React, { useState, ChangeEvent, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Papa from "papaparse";
import { Table, Pagination, Modal, Button } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  faAngleLeft,
  faAngleRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import "./Sitelist.css";
import Column from "rsuite/esm/Table/TableColumn";
import { callApi } from "../../../Utils/Api";
import { showAlert, showError, showSuccess } from "../../../Utils/sweetAlert";
import { isEmpty } from "validator";
import ExcelJS from 'exceljs';

const Sitelist: React.FC = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<any[]>([]);
  const [dataPeriod, setDataPeriod] = useState<"yearly" | "monthly">("monthly");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [siteDetails, setSiteDetails] = useState({
    name: "",
    customer_name: "",
    contact: "",
    billing_address: "",
    shipping_address: "",
  });
  const [nameerror, setNameerror] = useState(false)
  const [customernameerror, setCustomernameerror] = useState(false)
  const [contacterror, setContacterror] = useState(false)
  const [shippingaddresserror, setShippingaddresserror] = useState(false)
  const [billingaddresserror, setBillingaddresserror] = useState(false)
  const [error, setError] = useState(false);
  const [editingSiteDetails, setEditingSiteDetails] = useState<any>(null); // State for editing
  const [searchQuery, setSearchQuery] = useState("");
  const { Column, HeaderCell, Cell } = Table;
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [contactlengtherror, setContactlengtherror] = useState(false)

  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  const paginatedData = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const data = tableData.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const getData = () => {
    let filteredData = tableData;

    // Apply search filter if searchQuery exists
    if (searchQuery) {
      filteredData = tableData.filter((row) =>
        Object.values(row).some((value) =>
          typeof value === "string"
            ? value.toLowerCase().includes(searchQuery.toLowerCase())
            : false
        )
      );
    }

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  useEffect(() => {
    getSitelist();
  }, []);

  const navigateSiteDetails = () => {
    navigate("/layout/sitedetails");
  };

  const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
    setPage(1);
    setLimit(dataKey);
  };

  const getSitelist = () => {
    callApi("post", "site_list.php", "")
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          const data: any = [];
          res.data.responseJson.forEach((element: any) => {
            if (element.completed_site_status === "0") {
              data.push(element);
            }
          });
          console.log("site_list_________________1", data);

          setTableData(data);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };

  const downloadCSV = () => {
    const data = [
      ["site List"],
      ["S.No", "Site Name", "Invoice Value", "Balance Payment"],
      ...tableData.map((row) => [row.s_no, row.name, row.invoice_value, row.invoice_balance]),
    ];

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add rows to the worksheet
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    // Merge the title row across all columns and center the title
    worksheet.mergeCells('A1:D1');
    const titleCell = worksheet.getCell('A1');
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
    titleCell.font = { bold: true, size: 14 };

    // Style for the header row (second row)
    const headerRow = worksheet.getRow(2);
    headerRow.eachCell((cell) => {
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { bold: true };
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };
    });

    // Write the workbook to an Excel file
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Site_List ${formattedDate}.xlsx`;
      link.click();
    });
  };

  const handlePageClickPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageListChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value =
      event.target.value === "All"
        ? tableData.length
        : Number(event.target.value);
    setRowsPerPage(value);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const handleShowModalAdd = () => {
    setShowModalAdd(true);
    setSiteDetails({
      name: "",
      customer_name: "",
      contact: "",
      billing_address: "",
      shipping_address: "",
    });
  };

  const handleShowModalEdit = (rowData: any) => {
    setShowModalEdit(true);
    setEditingSiteDetails(rowData);
    setSiteDetails({
      name: rowData.name,
      customer_name: rowData.customer_name,
      contact: rowData.contact,
      billing_address: rowData.billing_address,
      shipping_address: rowData.shipping_address,
    });
  };

  const handleCloseModal = () => {
    setShowModalAdd(false);
    setShowModalEdit(false);
    setEditingSiteDetails(null);
    setError(false)
    setNameerror(false)
    setContacterror(false)
    setCustomernameerror(false)
    setContactlengtherror(false)

  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSiteDetails({ ...siteDetails, [name]: value });

    if (name === "contact") {
      const isValidInput = /^[0-9/]*$/.test(value);
      if (!isValidInput) {
        setContacterror(true);
      } else {
        setContacterror(false);
      }
    }
    //  if (name === "name") {
    //   const isValidInput = /^[a-zA-Z ]+$/.test(value);
    //   if (!isValidInput) {
    //     setNameerror(true);
    //     console.error("Invalid input. Please enter only characters.");
    //   } else {
    //     setNameerror(false);
    //   }
    // } 
    if (name === "customer_name") {
      const isValidINput = /^[A-Za-z\s]+$/.test(value);
      if (!isValidINput) {
        setCustomernameerror(true);
        console.error("Invalid input. Please enter only characters.");
      } else {
        setCustomernameerror(false);
      }
    }


  };

  useEffect(() => {
    console.error("customernameerror.", customernameerror);
  }, [customernameerror]);
  const handleCkeditorChange = (name: any, value: any) => {
    setSiteDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    handleSave(event);
  };

  const handleSave = (e: any) => {
    if (
      isEmpty(siteDetails.name) ||
      isEmpty(siteDetails.contact) ||
      isEmpty(siteDetails.customer_name) ||
      isEmpty(siteDetails.shipping_address) ||
      isEmpty(siteDetails.billing_address)

    ) {
      setError(true)
      setContactlengtherror(false)
    }
    else if (siteDetails.contact.length < 10) {
      setContactlengtherror(true)
      setError(false)

    }
    else if (nameerror || contacterror || customernameerror) {
      setError(true)
    } else {
      const apiEndpoint = editingSiteDetails
        ? "site_update.php"
        : "site_create.php";
      const requestData = editingSiteDetails
        ? { ...siteDetails, id: editingSiteDetails.id }
        : siteDetails;

      callApi("post", apiEndpoint, requestData)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response["responseCode"] === 200) {
            editingSiteDetails
              ? showSuccess("Site updated successfully", "")
              : showSuccess("Site created successfully", "");

            getSitelist();
          } else if (response.success === false) {
            showError("Error", "Fill out all fields");
            setShowModalAdd(true);
          } else {
            console.error("Failed to save site");
          }
        })
        .catch((error) => {
          console.error("Error saving site:", error);
        });
      handleCloseModal();
    };
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleCheckbox = async (e: any, id: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to change the status?",
      "Yes",
      "No"
    );
    const { checked } = e.target;
    console.log("handleCheckbox_____", checked);
    if (isComfirm) {
      const req = {
        id: id,
        status: checked == false ? "1" : "0",
      };
      callApi("POSt", "site_active_status.php", req)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response.responseCode === 200) {
            showSuccess("Status Changed Successfully", "");
            getSitelist();
          } else {
            showError("FAILED!", "CHANGE failed");
          }
        });
    }
  };

  const userEdit = (row: any) => {
    handleShowModalEdit(row);
  };

  const filteredData = paginatedData.filter(
    (row) =>
      (row.site_name &&
        row.site_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (row.invoice_value &&
        row.invoice_value.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (row.balance_payment &&
        row.balance_payment
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (row.available_for_po &&
        row.available_for_po.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const handleSiteClick = (rowData: any) => {
    navigate(`/layout/sitedetails/${rowData.id}`);
  };
  const handlecompletedsite = () => {
    navigate("/layout/completedsite/");
  };

  return (
    <div className="container-qt">
      <div className="">
        <div className="tabletitle" style={{ fontSize: "24px" }}>Site List</div>
        <div className="table-container">
          <div className="table-header">
            <div className='vendor-add-btn'>
              <button
                type="button"
                className="btn excelbtn exbtn"
                style={{ marginRight: "10px" }}
                onClick={handleShowModalAdd}
              >
                Add Site
              </button>
            </div>
            <div className='vendor-add-btn'>
              <button
                type="button"
                className="btn excelbtn exbtn "
                style={{ marginRight: "10px" }}
                onClick={() => handlecompletedsite()}
              >
                Completed Site
              </button>
            </div>
            <div className="table-search searchfieldscontain vendor-excel">
              <button
                type="button"
                className="btn excelbtn btnmobiledash "
                style={{ marginRight: "10px" }}
                onClick={downloadCSV}
              >
                Excel
              </button>

              <div className="search-field1">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-input vendorsearch"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", overflow: "hidden" }}>
            <Table
              height={420}
              data={getData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
              style={{ width: "100%", overflow: "hidden" }}
            >
              <Column width={100} align="center" fixed>
                <HeaderCell>S.No</HeaderCell>
                <Cell>
                  {(rowData: any, rowIndex?: number) => (
                    <span onClick={() => handleSiteClick(rowData)}>
                      {rowIndex !== undefined
                        ? (page - 1) * limit + rowIndex + 1
                        : ""}
                    </span>
                  )}
                </Cell>
              </Column>

              <Column width={200} align="cen1er">
                <HeaderCell>Site Name</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      onClick={() => handleSiteClick(rowData)}
                      className="underline-qt"
                    >
                      {rowData.name}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={200} align="cen1er">
                <HeaderCell>Invoice Value</HeaderCell>
                <Cell >
                  {(rowData) => (
                    <div
                      onClick={() => handleSiteClick(rowData)}
                      className="underline-qt"
                    >
                      {rowData.invoice_value}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={200} align="cen1er">
                <HeaderCell>Balance Payment</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      onClick={() => handleSiteClick(rowData)}
                      className="underline-qt"
                    >
                      {rowData.invoice_balance}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={200} align="center">
                <HeaderCell>Edit</HeaderCell>
                <Cell width={100}>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <button
                        className="iconbg"
                        onClick={() => userEdit(rowData)}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                    </div>
                  )}
                </Cell>
              </Column>

              {/* <Column width={100} align="center">
                <HeaderCell>Status</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={rowData.status === "1"}
                        onChange={(e) => handleCheckbox(e, rowData.id)}
                      />
                      <span className="slider round"></span>
                    </label>
                  )}
                </Cell>
              </Column> */}
            </Table>
          </div>
          <div>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              className="pagination"
              size="xs"
              layout={["total", "pager", "skip"]}
              total={tableData.length}
              limitOptions={[5, 10, 15]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>

      <Modal open={showModalAdd || showModalEdit} onClose={handleCloseModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="modaltittle">
            {showModalAdd ? "Add Site Details" : "Edit Site Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form style={{ padding: "2%", overflow: "hidden" }}>
            <div className='row'>
              <div className="col-md-6 form-group">
                <label className='labeltittle' htmlFor="name">
                  Site Name<span className='mandatoryfield'>*</span></label>
                <input
                  type="text"
                  placeholder="Enter site name"
                  name="name"
                  className={`form-control ${error && isEmpty(siteDetails.name) ? 'is-invalid' : ''} mt-1 mb-2`}
                  minLength={3}
                  maxLength={30}
                  value={siteDetails.name}
                  onChange={handleChange}
                  required
                />
                {nameerror || error && isEmpty(siteDetails.name) && <div className="error-message" style={{ color: "red" }}>Enter Site Name</div>}
              </div>
              <div className="col-md-6 form-group">
                <label className='labeltittle' htmlFor="name">
                  Customer Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter customer name"
                  name="customer_name"
                  className={`form-control ${error && isEmpty(siteDetails.customer_name) || customernameerror ? 'is-invalid' : ''} mt-1 mb-2`}
                  minLength={3}
                  maxLength={20}
                  value={siteDetails.customer_name}
                  onChange={handleChange}
                  required
                />
                {error &&  isEmpty(siteDetails.customer_name) && <div className="error-message" style={{ color: "red", display: "block" }}>Enter Customer Name</div>}
                { customernameerror &&   !isEmpty(siteDetails.customer_name)&& <div className="error-message" style={{ color: "red", display: "block" }}>Enter Customer Name</div>}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label htmlFor="price" className="labeltittle">
                  Contact Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter contact Number"
                  name="contact"
                  className={`form-control ${error && isEmpty(siteDetails.contact) ? 'is-invalid' : ''} mt-1 mb-2`}
                  value={siteDetails.contact}
                  onChange={handleChange}
                  minLength={10}
                  maxLength={10}
                  required
                />
                {contactlengtherror || (error && isEmpty(siteDetails.contact)) && <div className="error-message" style={{ color: "red" }}>Enter 10-digits Number</div>}
                {contacterror && <div className="error-message" style={{ color: "red" }}>Enter Contact Number</div>}
              </div>
            </div>
            <Form.Group
              controlId="formBillingAddress"
              className="mb-10 form-pad"
            >
              <label htmlFor="price" className="labeltittle">
                Billing Address<span style={{ color: "red" }}>*</span>
              </label>
              <div className="custom-ckeditor">
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: [],
                  }}
                  data={siteDetails.billing_address}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleCkeditorChange("billing_address", data);
                  }}
                />
                {error && isEmpty(siteDetails.billing_address) && <div className="error-message" style={{ color: "red" }}>Enter Billing Address</div>}

              </div>
            </Form.Group>
            <Form.Group
              controlId="formShippingAddress"
              className="mb-10 form-pad"
            >
              <label htmlFor="price" className="labeltittle">
                Shipping Address<span style={{ color: "red" }}>*</span>
              </label>
              <div className="custom-ckeditor">
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: [],
                  }}
                  data={siteDetails.shipping_address}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleCkeditorChange("shipping_address", data);
                  }}
                />
                {error && isEmpty(siteDetails.shipping_address) && <div className="error-message" style={{ color: "red" }}>Enter Shipping Address</div>}

              </div>
            </Form.Group>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="newuser mt-3" onClick={(e) => handleSave(e)}>
            {showModalAdd ? "Add Site Details" : "Edit Site Details"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sitelist;
