import React, { useState, ChangeEvent, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Papa from "papaparse";
import { Table, Pagination, Modal, Button, Col, Row } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import {
  faAngleLeft,
  faAngleRight,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "../page/site/Sitelist.css";
import Column from "rsuite/esm/Table/TableColumn";
import { callApi } from "../../Utils/Api";
import { showAlert, showError, showSuccess } from "../../Utils/sweetAlert";
import { Card } from "react-bootstrap";

const Invoicepayment: React.FC = () => {
  const navigate = useNavigate();
  const { inv_id } = useParams<{ inv_id: string }>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [cardData, setcardData] = useState<any[]>([]);
  const [dataPeriod, setDataPeriod] = useState<"yearly" | "monthly">("monthly");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [siteNames, setSiteNames] = useState([]);
  const [invno, setinvno] = useState([]);
  const [invalue, setinvalue] = useState([]);

  const [siteDetails, setSiteDetails] = useState({
    received_date: "",
    payment_received: "",
  });
  const [editingSiteDetails, setEditingSiteDetails] = useState<any>(null); // State for editing

  const [searchQuery, setSearchQuery] = useState("");

  const { Column, HeaderCell, Cell } = Table;
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  const paginatedData = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const data = tableData.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setSiteDetails((prevDetails) => ({
      ...prevDetails,
      received_date: formattedDate,
    }));
  };

  useEffect(() => {
    console.log("before", siteDetails.received_date);

    if (!siteDetails.received_date) {
      getCurrentDate();
      console.log("after", siteDetails.received_date);
    }
  }, [siteDetails.received_date]);
  const getData = () => {
    let filteredData = tableData;

    // Apply search filter if searchQuery exists
    if (searchQuery) {
      filteredData = tableData.filter((row) =>
        Object.values(row).some((value) =>
          typeof value === "string"
            ? value.toLowerCase().includes(searchQuery.toLowerCase())
            : false
        )
      );
    }

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  useEffect(() => {
    getSitelist();
    getsitecarddata();
    getSitecardlist();
  }, []);
  const getsitecarddata = () => {
    const site_id = localStorage.getItem("siteid");

    callApi("post", "site_list.php", "")
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          const filteredData = res.data.responseJson.filter(
            (item: any) => item.id === site_id
          );
          setcardData(filteredData);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };
  const navigateSiteDetails = () => {
    navigate("/layout/sitedetails");
  };

  const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
    setPage(1);
    setLimit(dataKey);
  };

  const getSitelist = () => {
    const req = {
      invoice_id: inv_id,
    };
    callApi("post", "invoice_amt_received_list.php", req)
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          const data: any = res.data.responseJson;
          console.log("site_list_________________1", data);

          setTableData(data);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error: any) => {
        console.error("Error fetching site list:", error);
      });
  };

  const getSitecardlist = () => {
    const req = {
      invoice_id: inv_id,
    };
    callApi("post", "invoice_amt_site_details.php", req)
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          const data: any = res.data.responseJson;
          if (data.length > 0) {
            setSiteNames(data[0].site_name);
            setinvalue(data[0]?.invoice_value);
            setinvno(data[0]?.invoice_id);
          }
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error: any) => {
        console.error("Error fetching site list:", error);
      });
  };

  const downloadCSV = () => {
    console.log("data data", tableData);
    const data = [
      ["S.No", "Payment Date", "Payment Received", "Balance"],
      ...tableData.map((row) => [
        row.id,
        row.received_date,
        row.payment_received,
        row.balance,
      ]),
    ];

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Site-List.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePageClickPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageListChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value =
      event.target.value === "All"
        ? tableData.length
        : Number(event.target.value);
    setRowsPerPage(value);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const handleShowModalAdd = () => {
    setShowModalAdd(true);
    setSiteDetails({
      received_date: "",
      payment_received: "",
    });
  };

  const handleShowModalEdit = (rowData: any) => {
    setShowModalEdit(true);
    setEditingSiteDetails(rowData);
    setSiteDetails({
      received_date: rowData.received_date,
      payment_received: rowData.payment_received,
    });
  };

  const handleCloseModal = () => {
    setShowModalAdd(false);
    setShowModalEdit(false);
    setEditingSiteDetails(null);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSiteDetails({ ...siteDetails, [name]: value });
  };

  const handleSave = () => {
    const apiEndpoint = editingSiteDetails
      ? "invoice_amount_update.php"
      : "invoice_amount_add.php";
    const site_id = localStorage.getItem("siteid");
    const requestData = editingSiteDetails
      ? {
          ...siteDetails,
          id: editingSiteDetails.id,
          site_id: site_id,
          inv_amt: invalue,
        }
      : {
          received_amt: siteDetails.payment_received,
          received_date: siteDetails.received_date,
          site_id: site_id,
          invoice_id: inv_id,
          inv_amt: invalue,
        };

    callApi("post", apiEndpoint, requestData)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["responseCode"] === 200) {
          console.log("response_Code", response);

          editingSiteDetails
            ? showSuccess("Payment updatedd successfully", "")
            : showSuccess("Payment created successfully", "");

          getSitelist();
        } else if (response["success"] === false) {
          showError("Error", response.message);
        } else {
          console.error("Failed to save site");
        }
      })
      .catch((error: any) => {
        console.error("Error saving site:", error);
      });
    handleCloseModal();
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const userEdit = (row: any) => {
    console.log("edit", row);
    handleShowModalEdit(row);
  };

  const handleSiteClick = (rowData: any) => {
    navigate(`/layout/sitedetails/${rowData.id}`);
  };
  const handlecompletedsite = () => {
    navigate("/layout/completedsite/");
  };

  const formatDate = (dateStr: any) => {
    const [day, month, year] = dateStr.split("-");
    return `${year}-${month}-${day}`;
  };

  const handledeleteClick = async (rowData: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Delete?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      const req = {
        id: rowData.id,
      };
      callApi("post", "invoice_amount_delete.php", req)
        .then((res: any) => {
          if (res.data && res.data["responseCode"] === 200) {
            showSuccess("success !", res.data.message);
            getSitelist();
            getsitecarddata();
          } else {
            console.error("Failed to fetch site list");
          }
        })
        .catch((error) => {
          console.error("Error fetching site list:", error);
        });
    }
  };
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        {" "}
        <button onClick={handleBack} className="btn excelbtn mr-10 pdf-btn">
          Go Back
        </button>
      </div>

      <Card className="vendorlist-card">
        <Row className="vendorlist-row">
          <Col md={3} className="vendorlist-row-col">
            <div className="vendorfieldscard1 ">
              <p>Site Name</p>
              <span>: {siteNames}</span>
            </div>
          </Col>
          <Col md={6} className="vendorlist-row-col">
            <div className="vendorfieldscard1">
              <p>Invoice No</p>
              <span>: {invno}</span>
            </div>
          </Col>
          <Col md={3} className="vendorlist-row-col">
            <div className="vendorfieldscard1">
              <p>Invoice Value</p>
              <span>: {invalue}</span>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="container-qt">
        <div className="">
          <div className="table-container">
            <div className="table-header">
              <button
                type="button"
                className="btn excelbtn"
                style={{ marginRight: "10px" }}
                onClick={handleShowModalAdd}
              >
                Add
              </button>
              <button
                type="button"
                className="btn excelbtn"
                style={{ marginRight: "10px" }}
                onClick={downloadCSV}
              >
                Excel
              </button>

              <div className="table-search">
                <div className="search-field1">
                  <input
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }}>
              <Table
                height={300}
                data={getData()}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loading}
                style={{ width: "100%", overflow: "hidden" }}
              >
                <Column width={100} align="cen1er">
                  <HeaderCell>SNo</HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) =>
                      rowIndex !== undefined ? rowIndex + 1 : ""
                    }
                  </Cell>
                </Column>

                <Column width={200} align="cen1er">
                  <HeaderCell>Date</HeaderCell>
                  <Cell>
                    {(rowData) => (
                      <div
                        onClick={() => handleSiteClick(rowData)}
                        className="underline-qt"
                      >
                        {rowData.received_date}
                      </div>
                    )}
                  </Cell>
                </Column>

                <Column width={200} align="cen1er">
                  <HeaderCell>Payment received</HeaderCell>
                  <Cell dataKey="payment_received" />
                </Column>

                <Column width={200} align="cen1er">
                  <HeaderCell>Balance </HeaderCell>
                  <Cell dataKey="balance" />
                </Column>

                <Column width={150} align="center">
                  <HeaderCell>Edit</HeaderCell>
                  <Cell width={100}>
                    {(rowData) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "15px",
                        }}
                      >
                        <button
                          onClick={() => userEdit(rowData)}
                          className="iconbg"
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ height: "20px", width: "20px" }}
                          />
                        </button>
                        <button
                          className="iconbg"
                          onClick={() => handledeleteClick(rowData)}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ height: "20px", width: "20px" }}
                          />
                        </button>
                      </div>
                    )}
                  </Cell>
                </Column>
              </Table>
            </div>
            <div>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                className="pagination"
                size="xs"
                layout={["total", "pager", "skip"]}
                total={tableData.length}
                limitOptions={[5, 10, 15]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        </div>

        <Modal open={showModalAdd || showModalEdit} onClose={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {showModalAdd ? "Add Payment" : "Edit Payment"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="form-align">
                <Form.Group controlId="formSiteName" className="mb-10">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Payment Date"
                    name="received_date"
                    value={siteDetails.received_date}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formCustomerName" className="mb-10">
                  <Form.Label>Payment</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Payment"
                    name="payment_received"
                    value={siteDetails.payment_received}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: "#595959", color: "white" }}
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              style={{ backgroundColor: "#007bff", color: "white" }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Invoicepayment;
