import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Button, Col, Grid, Modal, Pagination, Row, SelectPicker, Table } from 'rsuite'
import { Form } from "react-bootstrap";
import "./vendorsitelist.css"
import { showAlert, showError, showSuccess } from '../../../Utils/sweetAlert'
import { callApi } from '../../../Utils/Api'
import Papa from 'papaparse'
import { isEmpty } from 'validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
// import { Id } from '@reduxjs/toolkit/dist/tsHelpers'
import { useLocation, useNavigate } from 'react-router-dom'
import { Height } from '@mui/icons-material';
import ExcelJS from 'exceljs';

const Vendorsitelist = () => {

    const [formData, setFormData] = useState({
        id: '',
        vendorname: '',
        site: "",
        credit: "",
        exiting_site_id: ""
    });

    const [error, setError] = useState(false);
    const [contactnumbererror, setcontactnumbererror] = useState(false);
    const [contactnameerror, setcontactnameerror] = useState(false);
    const [crediterror, setCrediterror] = useState(false)
    const [vendornameerror, setvendornameerror] = useState(false);
    const [gstnumbererror, setgstnumbererror] = useState(false);
    const [confirm_passworderror, setconfirm_passworderror] = useState(false)
    const [modalTitle, setModalTitle] = useState("");
    const [givenCredit, setGivenCredit] = useState("");
    const [amountError, setAmountError] = useState(false);

    const [state, setState] = useState({
        UserListSet: {},
        vendor: [],
        site_list: [],
        moveSiteOption: [],
        dropsite_list: []
    });
    const [nameerror, setNameerror] = useState(false);
    const [passworderror, setpassworderror] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [isToggled, setIsToggled] = useState(false);
    const [billingaddresserror, setbillingaddresserror] = useState(false)
    // const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    // State for editing
    // const [dropsite_list,setDropsite_list]=usestate()

    const [searchQuery, setSearchQuery] = useState("");

    const { Column, HeaderCell, Cell } = Table;
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [CustomerVendor, setCustomerVendor] = useState("");
    const [siteList, setSiteList] = useState("");



    const getData = () => {
        let filteredData = vendorlist;

        // Apply search filter if searchQuery exists
        if (searchQuery) {
            filteredData = vendorlist.filter((row: any) =>
                Object.values(row).some((value) =>
                    typeof value === "string"
                        ? value.toLowerCase().includes(searchQuery.toLowerCase())
                        : false
                )
            );
        }

        // Apply sorting if sortColumn and sortType are provided
        if (sortColumn && sortType) {
            filteredData = filteredData.slice().sort((a: any, b: any) => {
                let x = a[sortColumn];
                let y = b[sortColumn];

                if (typeof x === "string" && typeof y === "string") {
                    x = x.toLowerCase();
                    y = y.toLowerCase();
                }

                if (sortType === "asc") {
                    return x > y ? 1 : -1;
                } else {
                    return x < y ? 1 : -1;
                }
            });
        }

        console.log("Filtered Data: ", filteredData);

        // Apply pagination
        const start = (page - 1) * limit;
        const end = start + limit;

        return filteredData.slice(start, end);
    };

    const handleSortColumn = (sortColumn: any, sortType: any) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    // const handleCloseModal = () => {
    //     setShowModalAdd(false);
    //     setShowModalEdit(false);
    //     setEditingSiteDetails(null);
    // };

    const handleCloseModal = () => {
        setShowModal(false)

        setFormData({
            id: "",
            vendorname: rowData.id,
            site: "",
            credit: "",
            exiting_site_id: ""
        });
        setAmountError(false)
        setCrediterror(false)
        setError(false)


    };
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        console.log("----", name, value);

        // Update state for form fields
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        // Perform validation based on field name
        if (name === "contactnumber") {
            const isValidInput = /^[0-9/]*$/.test(value);
            if (!isValidInput || value.length < 10) {
                setcontactnumbererror(true);
                console.error("Invalid input. Please enter only characters.");
            } else {
                setcontactnumbererror(false);
            }
        } else if (name === "vendorname") {
            const isValidInput = /^[a-zA-Z ]+$/.test(value);
            if (!isValidInput) {
                setvendornameerror(true);
                console.error("Invalid input. Please enter only characters.");
            } else {
                setvendornameerror(false);
            }
        }
        else if (name === "credit") {
            const isValidInput = /^[0-9/]*$/.test(value);
            if (!isValidInput) {
                setCrediterror(true);
                console.error("Invalid input. Please enter only Numbers.");
            } else {
                setCrediterror(false);
                if (modalTitle == "Move Credit") {
                    if (Number(value) > Number(givenCredit)) {
                        setAmountError(true);
                    } else {
                        setAmountError(false);
                    }
                    console.log("______", value, givenCredit, Number(value) > Number(givenCredit));
                }
            }
        }
    };

    const location = useLocation();
    const navigate = useNavigate()

    const {
        rowData
    } = location.state || {}

    console.log("rowData---> 1", rowData);


    useEffect(() => {
        getUserDetails();
        GetVendorDetails();
        getSitelist();
        if (!isEmpty(rowData.id)) {
            setCustomerVendor(rowData.id);
            setFormData((prevState) => ({
                ...prevState,
                vendorname: rowData.id
            }));
        }
    }, []);
    useEffect(() => {
        console.log("modalTitle", modalTitle, formData);
    }, [modalTitle, formData]);

    const GetVendorDetails = () => {
        callApi("POST", "vendor_list.php", "")
            .then((res) => res.data)
            .then((response) => {
                setState((prevState: any) => ({
                    ...prevState,
                    vendor: response.responseJson,
                }));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getSitelist = () => {
        callApi("POST", "site_list.php", "")
            .then((res) => res.data)
            .then((response) => {
                if (response.response_Code == 200) {
                    let siteListData: any = [];
                    response.responseJson.forEach((element: any) => {
                        if (element.completed_site_status == "0") {
                            siteListData.push(element);
                        }
                    });
                    console.log("ponmithunuthum8765---->", siteListData);
                    setState((prevState: any) => ({
                        ...prevState,
                        site_list: response.responseJson,
                        dropsite_list: siteListData
                    }));


                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handlesaveuser = (e: FormEvent) => {
        e.preventDefault();
        console.log(
            "formFields_________",
            formData
        );

        if ((
            isEmpty(formData.vendorname) ||
            isEmpty(formData.site)) || isEmpty(formData.credit) ||
            crediterror === true
            // isEmpty(formData.mobile.length < 10)
        ) {
            console.log("this", formData)
            setError(true);
            // showError("Failed", "Fill all the fields!");
        } else if (formData.credit.length < 2) {
            showError("Failed", "Credit Minimum length 2.");
        } else {
            if (modalTitle === "Add Credit") {
                let request = {
                    site_id: formData.site,
                    vendor_id: formData.vendorname,
                    credit_amt: formData.credit
                };
                callApi("POST", "vendor_credit_add.php", request)
                    .then((res: any) => res.data)
                    .then((response: any) => {
                        if (response.responseCode === 200) {
                            showSuccess("Success!", response.message);
                            setFormData({
                                id: "",
                                vendorname: rowData.id,
                                site: "",
                                credit: "",
                                exiting_site_id: ""
                            });
                            setShowModal(false);
                            getUserDetails();
                            handleCloseModal();
                        }
                        else {
                            showError("Failed", "Vendor Creation Failed!");
                        }
                    });
                setAmountError(false);
            } else if (modalTitle == "Edit Credit") {
                editingdetails();
                setAmountError(false);
            } else {
                if (
                    (isEmpty(formData.vendorname) ||
                        isEmpty(formData.site) ||
                        isEmpty(formData.credit) || formData.site == "0")
                ) {
                    console.log("formData_____________)", formData);
                    showError("Failed", "Fill all the fields!");
                    setAmountError(false);
                    setError(true);
                } else if (Number(formData.credit) > Number(givenCredit)) {
                    setAmountError(true);
                } else {
                    let request = {
                        new_site_id: formData.site,
                        exiting_site_id: formData.exiting_site_id,
                        vendor_id: formData.vendorname,
                        amt: formData.credit
                    };
                    console.log("request", request);
                    callApi("post", "vendor_amt_move.php", request)

                        .then((res: any) => res.data)
                        .then((response: any) => {
                            if (response.responseCode === 200) {
                                showSuccess("EDITED!", response.message);
                                getUserDetails();
                                setFormData({
                                    id: "",
                                    vendorname: "",
                                    site: "",
                                    credit: "",
                                    exiting_site_id: ""
                                });
                                setShowModal(false);
                                setAmountError(false);
                            } else {
                                showError("FAILED!", "Editing Failed");
                            }


                        });
                }
            }
        }
    };


    useEffect(() => {
        console.log("----44444444444444-------....>>", formData.site);
    }, [formData.site]);

    const getUserDetails = () => {
        const req = {
            "vendor_id": rowData.id
        }
        callApi("post", "vendor_site_select.php", req)
            .then((res: any) => res.data)
            .then((response: any) => {
                if (response["response_Code"] === 200) {
                    console.log("coming");
                    console.log("response.responseJson", response.responseJson);
                    setVendorlist(response.responseJson);
                }
                console.log("UserListSetvendor--->", state.UserListSet);

            })
    }

    const addUser = () => {
        setModalTitle("Add Credit");
        setShowModal(true)
    };

    const userEdit = (data: any) => {
        console.log("this is the data", data)
        setFormData({
            id: data.id,
            vendorname: data.vendor_id,
            site: data.site_id,
            credit: "",
            exiting_site_id: ""
        });
        console.log("muthu---->111", formData);


        setModalTitle("Edit Credit");
        setShowModal(true);
    };

    const editingdetails = () => {
        let request = {
            site_id: formData.site,
            vendor_id: formData.vendorname,
            credit_amt: formData.credit
        };
        if (isEmpty(formData.credit)) {
            setError(true)
        }
        else {
            console.log("request", request);
            callApi("post", "vendor_credit_add.php", request)

                .then((res: any) => res.data)
                .then((response: any) => {
                    if (response.responseCode === 200) {
                        showSuccess("EDITED!", response.message);
                        getUserDetails();
                        setFormData({
                            id: "",
                            vendorname: rowData.id,
                            site: "",
                            credit: "",
                            exiting_site_id: ""
                        });
                        setShowModal(false);
                    } else {
                        showError("FAILED!", "Editing Failed");
                    }
                    console.log("============================>", formData);
                });
        }
    };

    // const [currentPage, setCurrentPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showModal, setShowModal] = useState(false); // State for showing/hiding modal
    const [vendorlist, setVendorlist] = useState<any>([]);

    console.log("vendorsitelist--->0000", vendorlist);

    // const pageCount = Math.ceil(state.UserListSet.length / rowsPerPage);
    // const paginatedData = state.UserListSet.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
    // console.log("paginatedData", paginatedData);

    const downloadCSV = () => {
        const data = [
            ["Vendor site details"],
            ["S.No", "site Name", "PO value", "PE value", "Given Credits", "Balance credit"],
            ...vendorlist.map((row: any) => [row.s_no, row.site_name, row.po_value, row.pe_value, row.given_credits, row.balance_credits])
        ];

        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Add rows to the worksheet
        data.forEach((row) => {
            worksheet.addRow(row);
        });

        // Merge the title row across all columns and center the title
        worksheet.mergeCells('A1:F1');
        const titleCell = worksheet.getCell('A1');
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
        titleCell.font = { bold: true, size: 14 };

        // Style for the header row (first row)
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell: any) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
            cell.font = { bold: true };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } }; // Yellow background  fgColor: { argb: 'FFFF00' }
        });

        // Write the workbook to an Excel file
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Vendor_site_details_${formattedDate}.xlsx`;
            link.click();
        });
    };

    const handlePageClickprevious = () => {
        setCurrentPage(currentPage - 1);
    };
    const handlePageClicknext = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlepageListChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value === "All" ? vendorlist.length : Number(event.target.value);
        setRowsPerPage(value);
        setCurrentPage(0); // Reset to first page when rows per page changes
    };

    const movecredit = async (data: any) => {
        setFormData({
            id: data.id,
            vendorname: data.vendor_id,
            site: '',
            credit: '',
            exiting_site_id: data.site_id
        });
        setGivenCredit(data.given_credits);
        setModalTitle("Move Credit");
        const moveSiteOption = vendorlist.filter((site: any) => {
            return site.id != data.id;
        });
        setState((prevState: any) => ({
            ...prevState,
            moveSiteOption: moveSiteOption,
        }));
        setShowModal(true);

    }
    const closeModal = () => {
        setShowModal(false);
        setFormData({
            id: "",
            vendorname: "",
            site: "",
            credit: "",
            exiting_site_id: ''
        });
        setError(false)
    }

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // const filteredData = paginatedData.filter((row: any) =>
    //     row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     row.contact_no.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
        setPage(1);
        setLimit(dataKey);
    };

    const handlestatement = (rowData: any) => {
        console.log("rowData", rowData.id);

        console.log("navigate", rowData.id); // Print the name
        navigate('/layout/overallvendorstatement', { state: { rowData } }); // Navigate to the vendor page within layout
    };

    const vendoroptions = [
        { id: "0", name: "Select Site" },
        ...state.vendor
    ];
    const siteoptions = [
        { id: "0", name: "Select Site" },
        ...state.dropsite_list
    ];
    const move_Site_Option = [
        { id: "0", site_name: "Select Site" },
        ...state.moveSiteOption
    ];
    const handlegoBack = () => {
        navigate(-1);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission
            // You can add any additional actions here if needed
        }
    };

    //dropsite_list




    return (
        <>
            <div style={{ padding: "20px", display: "flex", justifyContent: "space-between", gap: "10px" }}>
                {" "}
                <button className="btn excelbtn mr-2" onClick={handlegoBack} style={{ height: "38px" }}>
                    Go Back
                </button>
                <button className='btn excelbtn mr-2' onClick={(e) => handlestatement(rowData)} style={{ height: "38px" }}>statement</ button>
            </div>
            <div className="new-vendorlist-card vendorlistcard">
                <Grid fluid>
                    <Row className="new-vendorlist-row">
                        <Col xs={12} md={4} className="new-vendorlist-col">
                            <div className="vendor-fields1">
                                <p className="field-label">Vendor Name :</p>
                                <span className="field-value">{rowData.name}</span>
                            </div>
                            <div className="vendor-fields1">
                                <p className="field-label">Contact Name :</p>
                                <span className="field-value">{rowData.contact_name}</span>
                            </div>
                            <div className="vendor-fields1">
                                <p className="field-label">Contact Details :</p>
                                <span className="field-value">{rowData.contact_no}</span>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="new-vendorlist-col">
                            <div className="vendor-fields">
                                <p className="field-label">GST Number:</p>
                                <span className="field-value">{rowData.gst_number}</span>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="new-vendorlist-col">
                            <div className="vendor-fields">
                                <p className="field-label">Billing Address:</p>
                                <span className="field-value">
                                    {rowData.billing_address}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>


            {/* <Card className="vendorlist-card">
                <Row className="vendorlist-row">
                    <Col md={4} className="vendorlist-row-col">
                        <div className="vendorfieldscard1 ">
                            <p style={{ fontWeight: "bold" }}>Vendor Name</p>
                            <span>: {"   "}{rowData.name}</span>
                        </div>
                    </Col>
                    <Col md={4} className="vendorlist-row-col">
                        <div className="vendorfieldscard1">
                            <p style={{ fontWeight: "bold" }}>Contact Name</p>
                            <span>: {"   "}{rowData.contact_name}</span>
                        </div>
                    </Col>
                    <Col md={4} className="vendorlist-row-col">
                        <div className="vendorfieldscard1">
                            <p style={{ fontWeight: "bold" }}>Contact Details</p>
                            <span>: {"   "}{rowData.contact_no}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="vendorlist-row">
                    <Col md={6}>

                        <div className="vendorfieldscard1">
                            <p style={{ fontWeight: "bold" }}>GST Number</p>
                            <span>: {"   "}{rowData.gst_number}</span>
                        </div>
                    </Col>
                    <Col md={6} className="vendorlist-row-col billing-address">
                        <div className="vendorfieldscard1">
                            <p style={{ fontWeight: "bold", flexWrap: "nowrap" }}>Billing Address:</p>
                            <span >
                                {"   "}{rowData.billing_address}{" "}
                            </span>
                        </div>
                    </Col>
                </Row> */}
            {/* <Col md={4} className="vendorlist-row-col billing-address">
                        <div className="vendorfieldscard1">
                            <p style={{ textWrap: "nowrap" }}>Billing Address:</p>
                            <span style={{ textWrap: "nowrap", marginLeft: "2%" }}>
                                {"   "}{rowData.shipping_address}{" "}
                            </span>
                        </div>
                    </Col> */}
            {/* <Col md={1} className='vendorlist-row-col' style={{display:"flex",justifyContent:"center"}}>
                        <div className="statementinvendor">
                            <button className='btn statementbtn' onClick={(e)=>handlestatement(rowData)}>statement</ button>
                        </div>
                    </Col> */}
            {/* </Card> */}
            <div className="container-qt">
                {/* <div className="rs-panel-body"> */}
                <div className="rs-table rs-table-hover">
                    <div className="tabletitle" style={{ fontSize: "24px" }}>Vendor Details</div>
                    <div className="table-header ">
                        <div className='vendor-add-btn'>
                            <button
                                type="button"
                                className="btn excelbtn exbtn"
                                style={{ marginRight: "10px" }}
                                onClick={addUser}
                            >
                                Add Credit
                            </button>
                        </div>
                        <div className="table-search searchfieldscontain vendor-excel">
                            <button
                                type="button"
                                className="btn excelbtn btnmobiledash "
                                style={{ marginRight: "10px" }}
                                onClick={downloadCSV}
                            >
                                Excel
                            </button>

                            <div className="search-field1">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="search-input vendorsearch"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "100%", overflowX: "hidden", overflowY: "hidden" }}>
                        <Table
                            height={420}
                            data={getData()}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            onSortColumn={handleSortColumn}
                            loading={loading}
                            style={{ width: "100%" }} // Adjust min-width as needed
                        >
                            <Column width={50} align="center" fixed>
                                <HeaderCell>S.No</HeaderCell>
                                <Cell dataKey="s_no" />
                            </Column>
                            <Column width={200} align="center">
                                <HeaderCell>Site Name</HeaderCell>
                                <Cell dataKey="site_name">

                                </Cell>
                            </Column>
                            <Column width={200} align="center">
                                <HeaderCell>PO Value</HeaderCell>
                                <Cell dataKey="po_value">

                                </Cell>
                            </Column>
                            <Column width={200} align="center">
                                <HeaderCell>PE Value</HeaderCell>
                                <Cell dataKey="pe_value">

                                </Cell>
                            </Column>
                            <Column width={150} align="center">
                                <HeaderCell>Given Credit</HeaderCell>
                                <Cell dataKey="given_credits">

                                </Cell>
                            </Column>
                            <Column width={150} align="center">
                                <HeaderCell>Balance Credit</HeaderCell>
                                <Cell dataKey="balance_credits">

                                </Cell>
                            </Column>
                            <Column width={220} align="center">
                                <HeaderCell>Action</HeaderCell>
                                <Cell>
                                    {(rowData) => (
                                        <div style={{ display: "flex" }}>
                                            <Button className='excelbtn' style={{ width: "40%", height: "30px", margin: "0% 2% 0% 2%" }} onClick={() => userEdit(rowData)}>Add Credit</Button>
                                            <Button className="excelbtntable" style={{ backgroundColor: "lightgray", width: "45%", height: "30px", margin: "0% 2% 0% 2%" }} onClick={() => movecredit(rowData)}>Move Credit</Button>
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                        </Table>
                    </div>

                    <div>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={5}
                            className="pagination"
                            size="xs"
                            layout={["total", "pager", "skip"]}
                            total={vendorlist.length}
                            limitOptions={[5, 10, 15]}
                            limit={limit}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={handleChangeLimit}
                        />
                    </div>
                </div>
            </div>


            {/* </div> */}

            {/* Modal for adding new user */}
            <Modal open={showModal} onClose={handleCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className='modaltittle'>{modalTitle == "Edit Credit" ? "Add Credit" : modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form style={{ padding: "2%", overflow: "hidden" }} >
                        {/* {modalTitle != "Move Credit" &&
                        
                        <>
                            <div className="form-group">
                                <label htmlFor="name">Vendor Name</label>
                                { <input
                                type="text"
                                className={`form-control ${error && isEmpty(formData.vendorname) ? 'is-invalid' : ''} mt-1 mb-2`}
                                id="name"
                                name="name"
                                value={formData.vendorname}
                                onChange={handleChange}
                            /> }
                                <Form.Select
                                    aria-label="Default select example"
                                    style={{ height: "58%" }}
                                    name='vendorname'
                                    value={formData.vendorname || ""}
                                    // onChange={(e: any) => setCustomerVendor(e.target.value)}
                                    onChange={handleChange}
                                >
                                    {vendoroptions.map((vendor: any) => (
                                        <option key={vendor.id} value={vendor.id}>
                                            {vendor.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                {error && isEmpty(formData.vendorname) && <div className="invalid-feedback">{"Name Error"}</div>}
                                {nameerror && <div className="error-message" style={{ color: "red" }}>Enter valid Name</div>}
                            </div>
                        </>} */}
                        <div className='row'>
                            <div className="col-md-6 form-group">
                                <div className="form-group" style={{ paddingTop: "2%" }}>
                                    <label htmlFor="mobile" className='labeltittle'>Select Site<span className='mandatoryfield'>*</span></label>
                                    {modalTitle != "Move Credit" ? <>
                                        <Form.Select
                                            aria-label="Default select example"
                                            style={{ height: "58%" }}
                                            name='site'
                                            value={formData.site || ""}
                                            className={`form-control ${error && isEmpty(formData.site) ? 'is-invalid' : ''} mt-1 mb-2`}
                                            // onChange={(e: any) => setSiteList(e.target.value)}
                                            onChange={handleChange}
                                            required
                                        >
                                            {siteoptions.map((site_list: any) => (
                                                <option key={site_list.id} value={site_list.id}>
                                                    {site_list.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </> : <>
                                        <Form.Select
                                            aria-label="Default select example"
                                            style={{ height: "58%" }}
                                            name='site'
                                            value={formData.site || ""}
                                            className={`form-control ${error && isEmpty(formData.site) ? 'is-invalid' : ''} mt-1 mb-2`}
                                            // onChange={(e: any) => setSiteList(e.target.value)}
                                            onChange={handleChange}
                                        >
                                            {move_Site_Option.map((site_list: any) => (
                                                <option key={site_list.id} value={site_list.site_id}>
                                                    {site_list.site_name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </>}
                                    {error && isEmpty(formData.site) && <div className="invalid-feedback" style={{ display: "block" }}>{"choose valid site"}</div>}
                                </div>
                            </div>
                            <div className="col-md-6 form-group">
                                <div className="form-group" style={{ paddingTop: "2%" }}>
                                    <label htmlFor="text" className='labeltittle'>Price<span className='mandatoryfield'>*</span></label>
                                    <input
                                        type="text"
                                        className={`form-control ${crediterror || (error && isEmpty(formData.credit)) ? 'is-invalid' : ''} mt-1 mb-2`}
                                        id="credit"
                                        name="credit"
                                        value={formData.credit}
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        minLength={2}
                                        maxLength={12}
                                        required
                                    />
                                    {error && isEmpty(formData.credit) && <div className="invalid-feedback" style={{ display: "block" }}>{"choose valid site"}</div>}
                                    {crediterror && !isEmpty(formData.credit) && <div className="invalid-feedback" style={{ display: "block" }}>{"choose valid site"}</div>}
                                    {/* {crediterror || (error && isEmpty(formData.credit)) && <div className="error-message" style={{ color: "red",display:"block" }}>Enter valid Credit</div>} */}
                                    {amountError && <div className="error-message" style={{ color: "red", display: "block" }}>Entered amount is greater than current amount.</div>}
                                </div>
                            </div>
                        </div>
                        <Button className='newuser mt-3' onClick={(e) => handlesaveuser(e)}>{modalTitle == "Edit Credit" ? "Add Credit" : modalTitle}</Button>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Vendorsitelist