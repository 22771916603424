import React, { FormEvent, useState, ChangeEvent, useEffect } from "react";
import Papa from "papaparse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../usermanagement/usermanagement.css";
import { showAlert, showError, showSuccess } from "../../../Utils/sweetAlert";
import { callApi } from "../../../Utils/Api";
import { isEmpty } from "validator";
import { Table, Pagination, Modal, Button, DatePicker } from "rsuite";
import {
  faAngleLeft,
  faAngleRight,
  faEdit,
  faMagnifyingGlass,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import "../../page/site/Sitelist.css";
import SearchIcon from "@rsuite/icons/Search";
import { TextField } from "@mui/material";
import axios from "axios";
import { log } from "console";
import ExcelJS from "exceljs";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const Nameofproduct = () => {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    Site: "",
    price: "",
  });

  const [state, setState] = useState({
    site: [
      {
        id: "",
        name: "",
      },
    ],
  });

  const [error, setError] = useState(false);
  const [sitenameerror, setSitenameerror] = useState(false);
  const [priceerror, setpriceerror] = useState(false);
  const [vendornameerror, setvendornameerror] = useState(false);
  const [passworderror, setpassworderror] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [confirm_passworderror, setconfirm_passworderror] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { Column, HeaderCell, Cell } = Table;
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [productlist, setProductlist] = useState<any>([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [datas, setDatas] = useState([]);

  useEffect(() => { }, [fromDate, toDate]);
  console.log("---dff", fromDate, toDate);

  const handleSearch = () => {
    if (!fromDate || !toDate) {
      showError("FAILED!", "Please Select both Dates");
      return;
    }

    const from_date = formattedFromDate(fromDate);
    const to_date = formattedToDate(toDate);

    // const formattedFromDate = fromDate.toISOString().split('T')[0];
    // const formattedToDate = toDate.toISOString().split('T')[0];
    const request = {
      from_date: from_date,
      to_date: to_date,
    };
    callApi("post", "expense_list.php", request)
      .then((res: any) => res.data)
      .then((response: any) => {
        console.log("response--->", response);
        // if (response["response_Code"] === 200) {
        console.log("product--->", response.responseJson);
        setProductlist(response.responseJson);
        // }
        console.log("product---> 11", productlist);
      });
  };
  const handleDateChange = (value: any, event: any) => {
    setFromDate(value); // value is of type Date | null
    console.log(fromDate);
  };
  const formattedFromDate = (date: any) => {
    if (!fromDate) return ""; // handle undefined or null date
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based in JavaScript
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleDateChangeto = (value: any, event: any) => {
    setToDate(value);
    console.log(toDate);
  };
  const formattedToDate = (date: any) => {
    if (!toDate) return ""; // handle undefined or null date
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based in JavaScript
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const today = new Date();

  const disableFutureDates = (date: any) => {
    return date > today;
  };

  const totalPages = Math.ceil(productlist.length / rowsPerPage);
  const paginatedData = productlist.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const getData = () => {
    let filteredData = productlist;

    // Apply search filter if searchQuery exists
    if (searchQuery) {
      filteredData = productlist.filter((row: any) =>
        Object.values(row).some((value) =>
          typeof value === "string"
            ? value.toLowerCase().includes(searchQuery.toLowerCase())
            : false
        )
      );
    }

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a: any, b: any) => {
        let x = a[sortColumn];
        let y = b[sortColumn];

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
    setPage(1);
    setLimit(dataKey);
  };

  const data = productlist.filter((v: any, i: any) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      id: "",
      name: "",
      price: "",
      Site: "",
    });
    setError(false);
    setSitenameerror(false);
    setvendornameerror(false);
    setpriceerror(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleCheckbox = async (e: any, id: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to change the status?",
      "Yes",
      "No"
    );
    const { checked } = e.target;
    console.log("handleCheckbox_____", checked);
    if (isComfirm) {
      const req = {
        id: id,
        status: checked == false ? "1" : "0",
      };
      callApi("POSt", "Product.delete.php", req)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response.responseCode === 200) {
            showSuccess("CHANGE!", response.message);
            productnameDetails();
          } else {
            showError("FAILED!", response.message);
          }
        });
    }
  };
  const handleChange = (e: any) => {
    console.log("e.target", e.target);

    const { name, value } = e.target;
    console.log("name--->", name, value);

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "price") {
      const isValidInput = /^[0-9/]*$/.test(value);
      if (!isValidInput) {
        setpriceerror(true);
      } else {
        setpriceerror(false);
      }
    } else if (name === "name") {
      const isValidInput = /^.*$/.test(value);
      if (!isValidInput) {
        setvendornameerror(true);
        console.error("Invalid input. Please enter only characters.");
      } else {
        setvendornameerror(false);
      }
    }
  };
  useEffect(() => {
    productnameDetails();
  }, []);

  const handleproductcreate = (e: FormEvent) => {
    e.preventDefault();

    console.log("formFields_________", formData);

    if (
      isEmpty(formData.name) ||
      isEmpty(formData.price) ||
      isEmpty(formData.Site) || formData.Site == "0"
    ) {
      setError(true);
      console.log();
    } else if (vendornameerror || priceerror) {
      // setError(true)
    } else {
      console.log("jgjhjh", modalTitle);

      if (modalTitle === "Add Transport Expense") {
        let request = {
          vendor_id: formData.name,
          site_id: formData.Site,
          price: formData.price,
        };

        callApi("POST", "expense_add.php", request)
          .then((res: any) => res.data)
          .then((response: any) => {
            if (response.responseCode === 200) {
              showSuccess("Success!", response.message);
              setFormData({
                name: "",
                price: "",
                Site: "",
                id: "",
              });
              console.log("{_______________________>}");

              setShowModal(false);
              productnameDetails();
              handleCloseModal();
            } else {
              showError("Failed", "Form Submission Failed!");
            }
          });
      } else {
        editingdetails();
      }
    }
  };
  const productnameDetails = () => {
    // const req = {

    //     "site_id": "1"

    // }
    // console.log("req---->", req);

    callApi("post", "expense_list.php", "")
      .then((res: any) => res.data)
      .then((response: any) => {
        console.log("response--->", response);
        // if (response["response_Code"] === 200) {
        console.log("product--->", response.responseJson);
        setProductlist(response.responseJson);
        // }
        console.log("product---> 11", productlist);
      });

    callApi("POST", "site_list.php", "")
      .then((res) => res.data)
      .then((response) => {
        console.log("response--->111", response.responseJson);

        if (response.response_Code == 200) {
          let siteListData: any = [];
          response.responseJson.forEach((element: any) => {
            if (element.completed_site_status == "0") {
              siteListData.push(element);
            }
          });

          setState((prevState: any) => ({
            ...prevState,
            site: siteListData,
          }));
          console.log("siteListData", siteListData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addProduct = () => {
    setModalTitle("Add Transport Expense");
    setShowModal(true);
  };

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const productEdit = (data: any) => {
    console.log("this is the data", data);
    const matchingSite = state.site.find(
      (site: any) => site.name === data.Site_name
    );
    console.log("this is the data", matchingSite);
    setFormData({
      id: data.id,
      name: data.Vendor,
      Site: data.Site_name,
      price: data.amount,
    });
    if (matchingSite) {
      console.log("This is the data", matchingSite);
      setFormData((prevFormData) => ({
        ...prevFormData,
        Site: matchingSite.id,
      }));
    }
    console.log("-------------------------->", data.Site, matchingSite);

    setModalTitle("Edit Transport Expense");
    setShowModal(true);

    console.log("setFormData===>", formData);
  };

  const editingdetails = () => {
    let request = {
      id: formData.id,
      Vendor: formData.name,
      Site_id: formData.Site,
      price: formData.price,
    };
    console.log("req------>", formData.Site);
    callApi("post", "expense_update.php", request)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response.responseCode === 200) {
          showSuccess("EDITED!", response.message);
          productnameDetails();
          setShowModal(false);
          setFormData({
            id: "",
            name: "",
            Site: "",
            price: "",
          });
        } else {
          showError("FAILED!", response.message);
        }
      });
  };

  const userDelete = async (row: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to delete the user?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      let req = {
        user_id: row.id,
      };
      callApi("POSt", "user_delete.php", req)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response.responseCode === 200) {
            showSuccess("DELETED!", response.message);
            productnameDetails();
          } else {
            showError("FAILED!", "Delete failed");
          }
        });
    }
  };

  const downloadCSV = () => {
    const data = [
      [
        "S.No",
        "Date",
        "PE Number",
        "Po Number",
        "Vendor",
        "Site Name",
        "Amount",
      ],
      ...productlist.map((row: any) => [
        row.s_no,
        row.updated_date,
        row.pe_number,
        row.po_number,
        row.Vendor,
        row.Site_name,
        row.amount,
      ]),
    ];

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add rows to the worksheet
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    // Merge the title row across all columns and center the title
    worksheet.mergeCells("A1:G1");
    const titleCell = worksheet.getCell("A1");
    titleCell.value = "Expense List"; // Set the title value
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell.font = { bold: true, size: 14 };

    // Style for the header row (second row)
    const headerRow = worksheet.getRow(2);
    headerRow.eachCell((cell: any) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF" },
      };
    });

    // Write the workbook to an Excel file
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${currentDate.getFullYear()}`;
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `expense_list_${formattedDate}.xlsx`;
      link.click();
    });
  };

  const siteselect = [{ id: "0", name: "Select Site" }, ...state.site];

  return (
    <>
      <div className="container-qt">
        {/* <div className="rs-panel-body"> */}
        <div className="rs-table rs-table-hover">
          <div className="tabletitle" style={{ fontSize: "24px" }}>
            Expense List
          </div>
          <div
            className="table-header expensetabelheader"
            style={{ gap: "10px" }}
          >
            <div className="vendor-add-btn expenseaddbtn">
              <button
                type="button"
                className="btn excelbtn expencetransport exbtn"
                style={{ marginRight: "10px" }}
                onClick={addProduct}
              >
                Add Transport
              </button>
            </div>
            <div className="grpdatepicker">
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
              <DatePicker
                size="lg"
                placeholder="From Date"
                disabledDate={disableFutureDates}
                className="datepicker"
                // value={fromDate}
                onChange={handleDateChange}
              />
              <DatePicker
                size="lg"
                placeholder="To Date"
                disabledDate={disableFutureDates}
                className="datepicker"
                // value={toDate}
                onChange={handleDateChangeto}
              // renderInput={(params) => <TextField {...params} size="large" className='datepicker' />}
              />
              {/* </LocalizationProvider> */}
              <button
                type="button"
                className="btn excelbtn"
                style={{ width: "100px" }}
                onClick={handleSearch}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ fontSize: "18px" }}
                />
              </button>
            </div>
            <div className="table-search searchfieldscontain vendor-excel expensesearch">
              {/* <div style={{width:"100%"}}> */}
              <button
                type="button"
                className="btn excelbtn btnmobiledash expencebtn-excel"
                style={{ marginRight: "10px" }}
                onClick={downloadCSV}
              >
                Excel
              </button>
              {/* </div> */}
              <div className="search-field1">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-input vendorsearch expencebtn-search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", overflowX: "hidden" }}>
            <Table
              height={420}
              data={getData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
              style={{ width: "100%" }} // Adjust min-width as needed
            >
              <Column width={50} align="center" fixed>
                <HeaderCell>S.No</HeaderCell>
                <Cell dataKey="s_no" />
              </Column>

              <Column width={100}>
                <HeaderCell>Date</HeaderCell>
                <Cell dataKey="updated_date" />
              </Column>
              <Column width={100}>
                <HeaderCell>Site Name</HeaderCell>
                <Cell dataKey="Site_name" />
              </Column>
              <Column width={100}>
                <HeaderCell>PE Number</HeaderCell>
                <Cell dataKey="pe_number"></Cell>
              </Column>
              <Column width={100}>
                <HeaderCell>PO Number</HeaderCell>
                <Cell dataKey="po_number"></Cell>
              </Column>
              <Column width={100}>
                <HeaderCell>vendor</HeaderCell>
                <Cell dataKey="Vendor" />
              </Column>
              <Column width={100}>
                <HeaderCell>Amount</HeaderCell>
                <Cell dataKey="amount" />
              </Column>

              <Column width={100}>
                <HeaderCell>Action</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div>
                      {rowData.status == 3 ? (
                        <button
                          className="iconbg"
                          onClick={() => productEdit(rowData)}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ height: "20px", width: "20px" }}
                          />
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
          </div>
          <div>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              className="pagination"
              size="xs"
              layout={["total", "pager", "skip"]}
              total={productlist.length}
              limitOptions={[5, 10, 15]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>

      {/* Modal for adding new user */}
      <Modal open={showModal} onClose={handleCloseModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="modaltittle">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form style={{ padding: "2%", overflow: "hidden" }}>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="labeltittle" htmlFor="name">
                  Vendor Name<span className="mandatoryfield">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${error && isEmpty(formData.name) ? "is-invalid" : ""
                    } mt-1 mb-2`}
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  maxLength={50}
                />
                {error && isEmpty(formData.name) && (
                  <div className="invalid-feedback">{"Vendor Name Error"}</div>
                )}
                {vendornameerror && (
                  <div className="error-message" style={{ color: "red" }}>
                    Enter valid Vendor Name
                  </div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label className="labeltittle" htmlFor="site">
                  Site Name<span className="mandatoryfield">*</span>
                </label>
                <Form.Select
                  className={`form-control ${error && (isEmpty(formData.Site) || formData.Site == "0") ? 'is-invalid' : ''} mt-1 mb-2`}
                  aria-label="Default select example"
                  style={{ height: "58%" }}
                  name="Site"
                  value={formData.Site}
                  onChange={handleChange}
                  required
                >
                  {siteselect.map((site) => (
                    <option key={site.id} value={site.id}>
                      {site.name}
                    </option>
                  ))}
                </Form.Select>
                {error && (isEmpty(formData.Site) || formData.Site == "0") &&
                  <div className="invalid-feedback" style={{ display: "block" }} >{"Site Name Error"}</div>
                }
                {sitenameerror &&
                  (formData.Site == "0" || formData.Site == "") && (
                    <div className="error-message" style={{ color: "red" }}>
                      Enter valid Site
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label className="labeltittle" htmlFor="price">
                  Amount<span className="mandatoryfield">*</span>
                </label>
                <input
                  type="price"
                  className={`form-control ${error && isEmpty(formData.price) ? "is-invalid" : ""
                    } mt-1 mb-2`}
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  maxLength={20}
                  minLength={2}
                  required
                />
                {error && isEmpty(formData.price) && (
                  <div className="invalid-feedback" style={{ display: "block" }}>{"Price Amount Error"}</div>
                )}
                {priceerror && (
                  <div className="error-message" style={{ color: "red" }}>
                    Enter valid Price Amount
                  </div>
                )}
              </div>
            </div>
            <Button
              className="newuser mt-3"
              onClick={(e) => handleproductcreate(e)}
            >
              {modalTitle}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Nameofproduct;
